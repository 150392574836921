import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../Pages/Forecast/Forecast.css";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonCellRenderer from "./TableButton.jsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Grid, Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme.js";
import dayjs from "dayjs";
import instance from "../middleware.js";
import { toast } from "react-toastify";

const PredictTable = ({ selectedDate, dayType, onAverageValuesChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDarkMode = theme.palette.mode === "dark";

  let selecDate;
  if (selectedDate) {
    selecDate = dayjs(selectedDate);
  }
  const downloadExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet1");
    sheet.addRow([
      "Time Stamp",
      "Temperature",
      "Humidity",
      "Load",
      "Difference",
      "Updated Load",
    ]);
    rowData.forEach((row) => {
      sheet.addRow([
        row.timeStamp,
        parseFloat(row.predictedTemp),
        parseFloat(row.predictedHum),
        parseFloat(row.predictedLoad),
        parseFloat(row.difference),
        parseFloat(row.updatePredictedLoad),
      ]);
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, `Prediction ${selecDate.format("YYYY-MM-DD")}.xlsx`);
    });
  };

  const suppressRowHoverHighlight = true;

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Time Stamp",
      headerClass: "customHeader",
      field: "timeStamp",
      cellClass: "timestampCol",
      width: 200,
      lockPosition: "left",
    },
    {
      headerName: "Predictions for",
      headerClass: "predForHeader",
      lockPosition: "left",
      children: [
        {
          headerName: "Temp",
          headerClass: "predForTempCol",
          field: "predictedTemp",
          cellClass: "tempClass",
          width: 150,
          lockPosition: "left",
        },
        {
          headerName: "Humidity",
          headerClass: "predForHumCol",
          field: "predictedHum",
          cellClass: "humidityClass",
          width: 150,
          lockPosition: "left",
        },
        {
          headerName: "Load",
          headerClass: "predForLoadCol",
          field: "predictedLoad",
          cellClass: "loadClass",
          width: 250,
          lockPosition: "left",
        },
      ],
    },
    {
      headerName: "Difference",
      headerClass: "updatedLoadHeader",
      field: "difference",
      cellClass: "uploadCol",
      width: 150,
      lockPosition: "left",
    },
    {
      headerName: "Updated Load",
      headerClass: "updatedLoadHeader",
      field: "updatePredictedLoad",
      cellClass: "uploadCol",
      width: 200,
      lockPosition: "left",
    },
  ]);
  const [rowData, setRowData] = useState([]);

  const [averageValues, setAverageValues] = useState({
    averageTemperature: 0,
    averageHumidity: 0,
    averageLoad: 0,
    maxLoad: 0,
    minLoad: 0,
  });

  const fetchDataAndProcess = () => {
    const workbook1 = new ExcelJS.Workbook();
    const sheet = workbook1.addWorksheet("Sheet1");
    sheet.addRow(["difference"]);
    rowData.forEach((row) => {
      sheet.addRow([row.difference]);
    });
    workbook1.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      postExcelData(blob);
    });
  };
  const postExcelData = (blob) => {
    const formData = new FormData();
    formData.append(
      "FILE",
      blob,
      `differece ${selecDate.format("YYYY-MM-DD")}.xlsx`
    );
    formData.append("DATE", selecDate.format("YYYY-MM-DD"));
    instance
      .post("/UploadDifferences", formData)
      .then((response) => {
        toast.success("Difference Updated Successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selecDate) {
          setColumnDefs([
            {
              headerName: "Time Stamp",
              headerClass: "customHeader",
              field: "timeStamp",
              cellClass: "timestampCol",
              width: 200,
              lockPosition: "left",
            },
            {
              headerName: `Predictions for ${selecDate.format("YYYY/MM/DD")}`,
              headerClass: "predForHeader",
              lockPosition: "left",
              children: [
                {
                  headerName: "Temp",
                  headerClass: "predForTempCol",
                  field: "predictedTemp",
                  cellClass: "tempClass",
                  width: 150,
                  lockPosition: "left",
                },
                {
                  headerName: "Humidity",
                  headerClass: "predForHumCol",
                  field: "predictedHum",
                  cellClass: "humidityClass",
                  width: 150,
                  lockPosition: "left",
                },
                {
                  headerName: "Load",
                  headerClass: "predForLoadCol",
                  field: "predictedLoad",
                  cellClass: "loadClass",
                  lockPosition: "left",
                  cellRenderer: (params) => {
                    return (
                      <ButtonCellRenderer
                        value={params.value}
                        onButtonClick={(inputValue) => {
                          let foundMatchingRow = false;
                          if (inputValue) {
                            let Value = parseFloat(
                              parseFloat(inputValue).toFixed(2)
                            );
                            setRowData((prevRows) => {
                              return prevRows.map((row) => {
                                if (foundMatchingRow) {
                                  return {
                                    ...row,
                                    updatePredictedLoad:
                                      row.updatePredictedLoad + Value,
                                    difference: row.difference + Value,
                                  };
                                } else if (params.value === row.predictedLoad) {
                                  foundMatchingRow = true;
                                  return {
                                    ...row,
                                    updatePredictedLoad:
                                      row.updatePredictedLoad + Value,
                                    difference: row.difference + Value,
                                  };
                                } else {
                                  return row;
                                }
                              });
                            });
                          }
                        }}
                      />
                    );
                  },
                  width: 250,
                },
              ],
            },
            {
              headerName: "Difference",
              headerClass: "updatedLoadHeader",
              field: "difference",
              cellClass: "uploadCol",
              width: 150,
              lockPosition: "left",
            },
            {
              headerName: "Updated Load",
              headerClass: "updatedLoadHeader",
              field: "updatePredictedLoad",
              cellClass: "uploadCol",
              width: 200,
              lockPosition: "left",
            },
          ]);
          const params = new URLSearchParams();
          params.append("DATE", selecDate.format("YYYY-MM-DD"));
          params.append("SPECIALDAY", dayType);
          const startAnnDate = "2024-08-08";
          const apiEndpoint = selecDate.isAfter(startAnnDate)
            ? "/predictLoad_ANN"
            : "/predictLoad";
          const response = await instance.post(apiEndpoint, params, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });

          if (response.data && response.data.blocks) {
            const tableData = response.data.blocks.map((block, index) => ({
              timeStamp: timestamps[index]?.label,
              predictedLoad: parseFloat(parseFloat(block.wdLoad).toFixed(2)),
              predictedTemp: block.temp,
              predictedHum: block.rh,
              updatePredictedLoad: parseFloat(parseFloat(block.wdLoad).toFixed(2)),
              difference: 0,
            }));
            setRowData(tableData);
            const validBlocks = response.data.blocks.filter(
              (row) =>
                !isNaN(parseFloat(row.temp)) &&
                !isNaN(parseFloat(row.rh)) &&
                !isNaN(parseFloat(row.wdLoad))
            );

            const totalTemperature = validBlocks.reduce(
              (sum, row) => sum + parseFloat(row.temp),
              0
            );
            const totalHumidity = validBlocks.reduce(
              (sum, row) => sum + parseFloat(row.rh),
              0
            );
            const totalLoad = validBlocks.reduce(
              (sum, row) => sum + parseFloat(row.wdLoad),
              0
            );
            const maxLoad = Math.max(
              ...validBlocks.map((row) => parseFloat(row.wdLoad))
            );
            const minLoad = Math.min(
              ...validBlocks.map((row) => parseFloat(row.wdLoad))
            );
            const averageTemperature = totalTemperature / validBlocks.length;
            const averageHumidity = totalHumidity / validBlocks.length;
            const averageLoad = totalLoad / validBlocks.length;

            setAverageValues({
              averageTemperature: isNaN(averageTemperature)
                ? 0
                : parseFloat(averageTemperature.toFixed(2)),
              averageHumidity: isNaN(averageHumidity)
                ? 0
                : parseFloat(averageHumidity.toFixed(2)),
              averageLoad: isNaN(averageLoad)
                ? 0
                : parseFloat(averageLoad.toFixed(2)),
              maxLoad: isNaN(maxLoad) ? 0 : parseFloat(maxLoad.toFixed(2)),
              minLoad: isNaN(minLoad) ? 0 : parseFloat(minLoad.toFixed(2)),
            });
            onAverageValuesChange({
              averageTemperature,
              averageHumidity,
              averageLoad,
              maxLoad,
              minLoad,
            });
            const DATE = selecDate.format("YYYY-MM-DD");
            const diffresponse = await instance.get(`/getDiffData/${DATE}`, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            });

            if (diffresponse.data && diffresponse.data.data) {
              const diffData = diffresponse.data.data.map((block, index) => ({
                timeStamp: timestamps[index]?.label,
                difference: block.difference,
              }));

              setRowData((prevRows) => {
                return prevRows.map((existingRow) => {
                  const newRow = diffData.find(
                    (newRow) => newRow.timeStamp === existingRow.timeStamp
                  );
                  if (newRow) {
                    return {
                      ...existingRow,
                      difference: parseFloat(newRow.difference),
                      updatePredictedLoad:
                        existingRow.updatePredictedLoad +
                        parseFloat(newRow.difference),
                    };
                  } else {
                    return existingRow;
                  }
                });
              });
            }
          }
        }
      } catch (error) {
        toast.error("No Data Available");
      }
    };
    fetchData();
  }, [selectedDate, dayType]);

  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={isDarkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
        style={{
          height: "46.875rem",
          width: "98%",
          marginTop: "1.875rem",
          backgroundColor: colors.primary.main,
          padding: "1.25rem",
          borderRadius: "24px",
          marginBottom: "1.25rem",
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        }}
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontFamily: "Poppins,sans-serif",
                fontSize: "1.5rem",
                fontWeight: "400",
                display: "flex",
                ml: "0.8rem",
                color: colors.primary.black,
              }}
            >
              Prediction Table
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Grid container>
              <Grid item>
                <Button
                  onClick={fetchDataAndProcess}
                  sx={{
                    display: "flex",
                    color: colors.primary["main"],
                    fontFamily: "Poppins,sans-serif",
                    textTransform: "none",
                    width: "8rem",
                    height: "2.8rem",
                    borderRadius: "16px",
                    background: colors.primary.dark,
                    marginLeft: "0.5rem",
                    marginRight: "1.25rem",
                    marginBottom: "0.625rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.125rem",
                    textAlign: "center",
                    color: colors.primary["main"],
                    "&:hover": {
                      color: colors.primary["main"],
                      background: colors.primary["black"],
                    },
                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                      webkitTapHighlightColor: colors.primary["black"],
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    color: colors.primary.dark,
                    fontFamily: "Poppins,sans-serif",
                    textTransform: "none",
                    width: "2.5rem",
                    height: "2.5rem",
                    minWidth: "0.625rem",
                    borderRadius: "12px",
                    background: colors.grey[200],
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.125rem",
                    textAlign: "center",
                    "&:hover": {
                      color: colors.primary.dark,
                      background: colors.grey[200],
                    },
                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                      webkitTapHighlightColor: colors.primary["black"],
                    },
                  }}
                  onClick={downloadExcelFile}
                >
                  <DownloadIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            height: "40.688rem",
            width: "100%",
            // width: "68.75rem",
            marginLeft: "1.563rem",
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={{ sortable: true, resizable: true }}
            pagination={true}
            paginationPageSize={12}
            suppressRowHoverHighlight={suppressRowHoverHighlight}
          />
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            backgroundColor: "white",
          }}
        ></Box>
      </Grid>
    </div>
  );
};

export default PredictTable;
const timestamps = [
  { label: "00:00", value: 0 },
  { label: "00:15", value: 1 },
  { label: "00:30", value: 2 },
  { label: "00:45", value: 3 },
  { label: "01:00", value: 4 },
  { label: "01:15", value: 5 },
  { label: "01:30", value: 6 },
  { label: "01:45", value: 7 },
  { label: "02:00", value: 8 },
  { label: "02:15", value: 9 },
  { label: "02:30", value: 10 },
  { label: "02:45", value: 11 },
  { label: "03:00", value: 12 },
  { label: "03:15", value: 13 },
  { label: "03:30", value: 14 },
  { label: "03:45", value: 15 },
  { label: "04:00", value: 16 },
  { label: "04:15", value: 17 },
  { label: "04:30", value: 18 },
  { label: "04:45", value: 19 },
  { label: "05:00", value: 20 },
  { label: "05:15", value: 21 },
  { label: "05:30", value: 22 },
  { label: "05:45", value: 23 },
  { label: "06:00", value: 24 },
  { label: "06:15", value: 25 },
  { label: "06:30", value: 26 },
  { label: "06:45", value: 27 },
  { label: "07:00", value: 28 },
  { label: "07:15", value: 29 },
  { label: "07:30", value: 30 },
  { label: "07:45", value: 31 },
  { label: "08:00", value: 32 },
  { label: "08:15", value: 33 },
  { label: "08:30", value: 34 },
  { label: "08:45", value: 35 },
  { label: "09:00", value: 36 },
  { label: "09:15", value: 37 },
  { label: "09:30", value: 38 },
  { label: "09:45", value: 39 },
  { label: "10:00", value: 40 },
  { label: "10:15", value: 41 },
  { label: "10:30", value: 42 },
  { label: "10:45", value: 43 },
  { label: "11:00", value: 44 },
  { label: "11:15", value: 45 },
  { label: "11:30", value: 46 },
  { label: "11:45", value: 47 },
  { label: "12:00", value: 48 },
  { label: "12:15", value: 49 },
  { label: "12:30", value: 50 },
  { label: "12:45", value: 51 },
  { label: "13:00", value: 52 },
  { label: "13:15", value: 53 },
  { label: "13:30", value: 54 },
  { label: "13:45", value: 55 },
  { label: "14:00", value: 56 },
  { label: "14:15", value: 57 },
  { label: "14:30", value: 58 },
  { label: "14:45", value: 59 },
  { label: "15:00", value: 60 },
  { label: "15:15", value: 61 },
  { label: "15:30", value: 62 },
  { label: "15:45", value: 63 },
  { label: "16:00", value: 64 },
  { label: "16:15", value: 65 },
  { label: "16:30", value: 66 },
  { label: "16:45", value: 67 },
  { label: "17:00", value: 68 },
  { label: "17:15", value: 69 },
  { label: "17:30", value: 70 },
  { label: "17:45", value: 71 },
  { label: "18:00", value: 72 },
  { label: "18:15", value: 73 },
  { label: "18:30", value: 74 },
  { label: "18:45", value: 75 },
  { label: "19:00", value: 76 },
  { label: "19:15", value: 77 },
  { label: "19:30", value: 78 },
  { label: "19:45", value: 79 },
  { label: "20:00", value: 80 },
  { label: "20:15", value: 81 },
  { label: "20:30", value: 82 },
  { label: "20:45", value: 83 },
  { label: "21:00", value: 84 },
  { label: "21:15", value: 85 },
  { label: "21:30", value: 86 },
  { label: "21:45", value: 87 },
  { label: "22:00", value: 88 },
  { label: "22:15", value: 89 },
  { label: "22:30", value: 90 },
  { label: "22:45", value: 91 },
  { label: "23:00", value: 92 },
  { label: "23:15", value: 93 },
  { label: "23:30", value: 94 },
  { label: "23:45", value: 95 },
];
