import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { tokens } from '../theme';

function Dropdown({ dates, onChange }) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const [selectedDate, setSelectedDate] = useState('');

    const handleChange = (event) => {
        setSelectedDate(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };

    const dateArray = Array.from(dates);

    return (
        <FormControl>
            <InputLabel id="droplabel">Select a Date :</InputLabel>
            <Select
                labelId="droplabel"
                id="dropdelect"
                value={selectedDate}
                onChange={handleChange}
                sx={{
                    width: "15rem",
                    height: "3.125rem",
                    backgroundColor: colors.grey[200],
                    color: colors.primary.black,
                    borderRadius: "16px",
                    border: "none",
                    // "& .css-j56apn-MuiFormLabel-root-MuiInputLabel-root": {
                    //     color: "black  !important",
                    // },
                    "& .css-j56apn-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                        color: "#000000 !important",
                    },
                   
                }}
            >
                <MenuItem>Selected a date</MenuItem>
                {dateArray.map((date) => (
                    <MenuItem key={date} value={date.format("YYYY-MM-DD")}>
                        {date.format("YYYY-MM-DD")}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default Dropdown;