import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme,Grid } from "@mui/material";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import Logo1 from "../../assets/Logo1.png";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import CalculateIcon from '@mui/icons-material/Calculate';
import { useMsal } from "@azure/msal-react";
// import './global.css'

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        marginBottom: "15px",
        backgroundColor: selected === title ? "#444444" : "transparent",
        width: selected === title ? "240px" : "240px",
        borderRadius: selected === title ? "10px" : "0",
      }}
      onClick={() => {
        setSelected(title);
        if (onClick) onClick();
      }}
      icon={icon}
    >
      <Typography sx={{ color: colors.sidebar[200] }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar1 = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const { instance, accounts } = useMsal();

  const findAccountByHomeAccountId = (homeAccountId) => {
    return accounts.find((account) => account.homeAccountId === homeAccountId);
  };

  useEffect(() => {
    const storedSelected = localStorage.getItem("selectedItem");
    if (storedSelected) {
      setSelected(storedSelected);
    }
  }, []);

  const handleItemClick = (title) => {
    if (title === "Dashboard") {
      // If the user is on the "/" route, do not store "Dashboard" in localStorage
      if (
        window.location.pathname === "/" ||
        window.location.pathname === "/signin"
      ) {
        setSelected(title);
        localStorage.removeItem("selectedItem");
      } else {
        setSelected(title);
        localStorage.setItem("selectedItem", title);
        window.dispatchEvent(new Event("storage"));
      }
    } else {
      setSelected(title);
      localStorage.setItem("selectedItem", title);
      window.dispatchEvent(new Event("storage"));
    }
  };
  const logOut = async () => {
    const homeAccountId = accounts[0].homeAccountId;
    const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
    if (accountToSignOut) {
      try {
        await instance.logout({
          account: accountToSignOut,
        });

        // Clear localStorage item
        localStorage.clear(); // Redirect to "/" after successful logout
        window.dispatchEvent(new Event("storage"));
        setTimeout(() => {
          navigate("/");
        }, 500);
        localStorage.removeItem("selectedItem");
        window.dispatchEvent(new Event("storage"));
      } catch (error) {
        
      }
    } else {
      
    }
  };

  return (
    <Grid lg={10.6} md={10.6}
    sx={{
      // display: "flex",
      height: "97vh",
      // zIndex: 5,
      marginLeft: "10px",
      marginTop: "10px",
      // position: "absolute",
      // background: "green",
      position: 'sticky',
      top: "10px",
      "& .pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper":
      {
        color: colors.sidebar[200],
      },
      "& .pro-sidebar > .pro-sidebar-inner": {
        borderRadius: "24px",
        backgroundColor: colors.sidebar[100],
      },
      "&. pro-sidebar-inner": {
        backgroundColor: `${colors.purple[300]} !important`,
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !transparent",
      },
      "& .pro-inner-item": {
        padding: "5px 35px 5px 20px !important",
      },
      "& .pro-inner-item:hover": {
        color: "#ffffff !important",
        backgroundColor: "#444444 !important",
        borderRadius: "10px",
      },
      "& .pro-inner-item:active": {
        color: "#ffffff !important",
      },
      "& .pro-icon-wrapper": {
        background: "none !important",
      },
      "& .pro-sidebar": {
        minWidth: 0
      }
    }}
  >
    <ProSidebar
      collapsed={isCollapsed}
      style={{
        height: "97vh",
        margin: "0px",
        padding: "0px",
        // position: "fixed",
        width: "100%",
      }}
    >
      <Menu iconShape="square">
        {/* LOGO AND MENU ICON */}
        {/* <MenuItem
      onClick={() => setIsCollapsed(!isCollapsed)}
      icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
      style={{
        margin: "0px",
        color: colors.grey[100],
      }}
    > */}
        {/* {!isCollapsed && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          ml="15px"
        >
          <Typography variant='h3' color={colors.grey[100]} sx={{ marginBottom: '30px' }}>Tata Power</Typography>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <MenuOutlinedIcon style={{ color: "white", marginBottom: '30px' }} />
          </IconButton>
        </Box>
      )} */}
        {/* </MenuItem> */}
        {!isCollapsed && (
          <Box mb="25px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ pt: 2 }}
            >
              <img
                width="80%"
                // height="80px"
                src={Logo1}
              />
            </Box>
          </Box>
        )}
        <Box
          paddingLeft={isCollapsed ? undefined : "5%"}
        // sx={{ height: "79vh" }}
        >
          <Item
            title="Dashboard"
            to="/Dashboard"
            icon={<GridViewSharpIcon sx={{ backgroundColor: "" }} />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="Upload Data"
            to="/Upload"
            icon={<UploadFileOutlinedIcon />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="Forecast"
            to="/Forecast"
            icon={<TimelineOutlinedIcon />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="Statistical Forecast"
            to="/StatisticalForecast"
            icon={<CalculateIcon />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="14 Days Forecast"
            to="/14daysForecast"
            icon={<QueryStatsIcon />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="Verify Prediction"
            to="/Verify"
            icon={<PollOutlinedIcon />}
            selected={selected}
            setSelected={handleItemClick}
          />
          <Item
            title="Insight Board"
            icon={<StackedBarChartIcon />}
            selected={selected}
            setSelected={handleItemClick}
            onClick={() =>
              window.open(
                // "https://app.powerbi.com/view?r=eyJrIjoiMTZjNDVlMGYtM2I1MC00MjlkLThlNjQtOWRhOGYwNzAxYmJhIiwidCI6IjNiNWUwMmI5LWQ2MjMtNGJhNy1hNzRhLTEyZDc0YmM1MjE3YyJ9",
                "https://app.powerbi.com/reportEmbed?reportId=d09dcc6a-4359-4360-92d5-ed146cebe201&autoAuth=true&ctid=3b5e02b9-d623-4ba7-a74a-12d74bc5217c",
                "_blank"
              )
            }
          />

          {/* <Box> */}
          {/* <div style={{ marginTop: "11rem" }}> */}
          <Item
            title="Logout"
            to="/"
            icon={<LogoutTwoToneIcon />}
            selected={selected}
            setSelected={handleItemClick}
            onClick={logOut}
          />
          {/* </div>
          </Box> */}
        </Box>
      </Menu>
    </ProSidebar>
  </Grid>
  );
};

export default Sidebar1;
