import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Grid,
  Box,
  Modal,
  IconButton,
  useTheme,
  Button,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import { tokens } from "../../theme";
import ResultTable from "../../Components/ResultTable";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import MultipleDates from "../../Components/MultipleDates";
import instance from "../../middleware";
import { toast } from "react-toastify";

const Comp = () => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1500,
    height: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTempModalOpen, setIsTempModalOpen] = useState(false);
  const [isHumidityModalOpen, setIsHumidityModalOpen] = useState(false);
  const [newDatesArray, setNewDatesArray] = useState([]);
  const [arrayMultiple, setArrayMultiple] = useState([]);
  const [blockDates, setBlockDates] = useState([]);
  const navigate = useNavigate();
  const [dayType, setDayType] = React.useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const GoBack = () => {
    navigate("/Forecast");
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const toggleLoadModal = () => {
    setIsLoadModalOpen(!isLoadModalOpen);
  };
  const toggleTempModal = () => {
    setIsTempModalOpen(!isTempModalOpen);
  };
  const toggleHumidityModal = () => {
    setIsHumidityModalOpen(!isHumidityModalOpen);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("ComparisonDates");
    const comdayType = localStorage.getItem("ComparisonDaytype");
    if (storedValue) {
      try {
        const daysArray = [];
        for (let i = 0; i < 1000; i++) {
          daysArray.push(dayjs().add(i, "day")["$d"]);
        }
        setBlockDates(daysArray);
        const parsedValue = JSON.parse(storedValue);
        const parseddaytype = JSON.parse(comdayType);
        if (Array.isArray(parsedValue)) {
          const convertD = parsedValue.map(
            (dateString) => new Date(dateString)
          );
          setDayType(parseddaytype);
          setArrayMultiple(convertD.slice(0, -1));
          setNewDatesArray(convertD);
        } else {
        }
      } catch (error) {}
    } else {
      toast.error("No data found in localStorage.");
    }
  }, []);

  const handleUpdate = (selectedDates) => {
    setArrayMultiple(selectedDates);
    const predictDate = newDatesArray[newDatesArray.length - 1];
    const mergedArray = [...selectedDates, predictDate];
    setNewDatesArray(mergedArray);
    localStorage.setItem("ComparisonDates", JSON.stringify(mergedArray));
    setOpen(false);
  };

  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      if (
        !newDatesArray ||
        newDatesArray.length === 0 ||
        !dayType ||
        dayType === null
      )
        return;
      try {
        const promises = arrayMultiple.map(async (date) => {
          const response = await instance.post(
            "/getData",
            `DATE=${dayjs(date).format("YYYY-MM-DD")}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          const jsonData = response.data.blocks;
          return { jsonData };
        });
        const lastDate = newDatesArray[newDatesArray.length - 1];
        const startAnnDate = "2024-08-08";
        const apiEndpoint = dayjs(lastDate).isAfter(startAnnDate)
          ? "/predictLoad_ANN"
          : "/predictLoad";
        const predictionResponse = await instance.post(
        apiEndpoint,
          `DATE=${dayjs(lastDate).format("YYYY-MM-DD")}&SPECIALDAY=${dayType}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const predictionData = predictionResponse.data.blocks;
        const chartData = await Promise.all(promises);
        setChartData([...chartData, { jsonData: predictionData }]);
      } catch (error) {
        toast.error("Error");
      }
    };

    fetchData();
  }, [newDatesArray]);

  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "20px",
          width: "98%",
          // width: "75rem",
          alignItems: "center",
          marginTop: "1.875rem",
          position: "relative",
          top: 0,
          zIndex: 0,
          height: "5rem",
          backgroundColor: colors.primary.main,
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        }}
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Grid container>
              <Grid item>
                <Button
                  onClick={GoBack}
                  sx={{
                    color: colors.primary.dark,
                    fontFamily: "Poppins,sans-serif",
                    textTransform: "none",
                    width: "6.3rem",
                    height: "3rem",
                    borderRadius: "16px",
                    background: colors.grey[200],
                    marginLeft: "1.25rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.125rem",
                    textAlign: "center",
                    color: colors.primary.main,
                    "&:hover": {
                      color: colors.primary.dark,
                      background: colors.grey[200],
                    },
                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                      webkitTapHighlightColor: colors.primary.black,
                    },
                  }}
                >
                  <NavigateBeforeIcon
                    sx={{
                      color: colors.primary.dark,
                      padding: "0rem",
                      margin: "0rem",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Poppins,sans-serif",
                      color: colors.primary.dark,
                      fontSize: "0.813rem",
                      fontWeight: "500",
                    }}
                  >
                    Go Back
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <div
                  style={{ display: "flex", alignItems: "center", ml: "0rem" }}
                >
                  <Typography
                    sx={{
                      marginTop: "0.313rem",
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      marginLeft: "1.3rem",
                      fontFamily: "Poppins,sans-serif",
                      color: colors.primary.dark,
                      display: "flex",
                      marginTop: "0.625rem",
                    }}
                  >
                    Compare with Past Actuals
                  </Typography>
                </div>
              </Grid>
              <Grid item sx={{ alignItems: "center", display: "flex" }}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[200],
                    borderRadius: "0.625rem",
                    marginLeft: "1.25rem",
                    padding: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins,sans-serif",
                      color: colors.primary.dark,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Selected Daytype :
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "Poppins,sans-serif",
                          color: colors.primary.dark,
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "0.625rem",
                          fontWeight: "500",
                        }}
                      >
                        {dayType === "Normal Day"
                          ? "Normal(Full Load Day/Weekend)"
                          : dayType === "Medium Load Day"
                          ? "Medium Load Day"
                          : "Holiday/Festival"}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Button
              sx={{
                color: colors.primary.main,
                fontFamily: "Poppins,sans-serif",
                textTransform: "none",
                width: "10rem",
                height: "3rem",
                padding: "1rem 2rem",
                borderRadius: "16px",
                background: colors.primary.black,
                // mt: "0.625rem",
                marginLeft: "0.5rem",
                marginRight: "1.25rem",
                fontSize: "0.875rem",
                fontWeight: "400",
                lineHeight: "1.125rem",
                textAlign: "center",
                color: colors.primary.main,
                "&:hover": {
                  color: colors.primary.main,
                  background: colors.primary.black,
                },
                "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                  webkitTapHighlightColor: colors.primary.black,
                },
              }}
              onClick={handleOpen}
            >
              Select Dates
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <MultipleDatesPicker
                  open={open}
                  selectedDates={arrayMultiple}
                  disabledDates={blockDates}
                  onCancel={() => setOpen(false)}
                  onSubmit={handleUpdate}
                  slotProps={{
                    popper: {
                      sx: {
                        ".MuiPaper-root": {
                          //  border: "1px solid blue",
                          borderRadius: "4px !important",
                        },
                        " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                          boxShadow: "none !important",
                        },
                        "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                          {
                            borderRadius: "12px !important",
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                          },
                        // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                        //   borderRadius: '12px !important',
                        //   backgroundColor: colors.primary.black,
                        //   color: colors.primary.main

                        // },
                        " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
                          {
                            border: "none !important",
                          },
                        " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            borderRadius: "12px !important",
                            // background: colors.primary.light
                          },
                        ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                            borderRadius: "10px !important",
                          },
                        ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
                          {
                            borderRadius: "12px",
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                          },
                        ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            borderRadius: "12px !important",
                            backgroundColor: `${colors.grey[200]} !important`,
                          },
                        ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                          {
                            borderRadius: "12px !important",
                            backgroundColor: colors.grey[200],
                          },
                        ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root": {
                          borderRadius: "12px !important",
                        },
                        ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                            borderRadius: "10px !important",
                          },
                      },
                    },
                  }}
                />
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: colors.primary.main,
          marginTop: "1.875rem",
          display: "flex",
          width: "98%",
          // width: "74.875rem",
          borderRadius: "24px",
          height: "31.25rem",
        }}
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "500",
                fontFamily: "Poppins,sans-serif",
                marginTop: "1.25rem",
                marginLeft: "1.25rem",
              }}
            >
              Load
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={toggleLoadModal}
              sx={{
                // position: "absolute",
                // top: "14.375rem",
                // right: "2rem",
                // display: 'flex',
                // float: 'right'
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* <Typography
        sx={{
          fontSize: "1.5rem",
          fontWeight: "500",
          fontFamily: "Poppins,sans-serif",
          marginTop: "1.25rem",
          marginLeft: "1.25rem",
        }}
      >
        Load
      </Typography> */}
        {/* <IconButton
        onClick={toggleLoadModal}
        sx={{
          // position: "absolute",
          // top: "14.375rem",
          // right: "2rem",
          display: 'flex',
          float: 'right'
        }}
      >
        <FullscreenIcon />
      </IconButton> */}
        {/* <Box> */}

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // width: "73.125rem",
            height: "28.125rem",
            marginX: "15px",
            backgroundColor: "transparent",
          }}
        >
          <MultipleDates
            selectedDate={newDatesArray}
            chartData={chartData}
            dayType={dayType}
            metric="wdLoad"
            margins={{ top: 5, right: 30, bottom: 70, left: 50 }}
            yDomain={[0, 100]}
            legend="Load"
            color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
            pointColor=" rgba(0,0,0,0)"
            expandedView
            pointFaceColor="rgba(255,255,255)"
            pointSize={2}
            lineWidth={1}
            pointWidth={1}
            pointBorderColor={{ from: "serieColor" }}
          />
        </Grid>
        <Box>
          <Modal
            open={isLoadModalOpen}
            onClose={toggleLoadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                backdropFilter: "blur(2px)",
              },
            }}
          >
            <Box sx={modalStyle}>
              <MultipleDates
                selectedDate={newDatesArray}
                chartData={chartData}
                dayType={dayType}
                metric="wdLoad"
                margins={{ top: 5, right: 0, bottom: 70, left: 70 }}
                yDomain={[0, 100]}
                legend="Load"
                color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
              <IconButton
                onClick={toggleLoadModal}
                sx={{
                  position: "absolute",
                  top: "0.3135rem",
                  right: "0.3135rem",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Modal>
        </Box>
        {/* </Box> */}
        {/* </Typography> */}
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} spacing={3} sx={{}}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          // sx={{ marginRight: "1.875rem" }}
        >
          <Box
            sx={{
              backgroundColor: colors.primary.main,
              marginTop: "1.875rem",
              display: "flex",
              // width: "36.5rem",
              borderRadius: "24px",
              height: "21.75rem",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    fontFamily: "Poppins,sans-serif",
                    marginTop: "1.25rem",
                    marginLeft: "1.25rem",
                  }}
                >
                  Temperature
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={toggleTempModal}
                  sx={{
                    // position: "absolute",
                    // top: "47.5rem",
                    // right: "40.5rem",
                    // display: 'flex',
                    // float: 'right'
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "500",
              fontFamily: "Poppins,sans-serif",
              marginTop: "1.25rem",
              marginLeft: "1.25rem",
            }}
          >
            Temperature
          </Typography> */}
            {/* <IconButton
            onClick={toggleTempModal}
            sx={{
              // position: "absolute",
              // top: "47.5rem",
              // right: "40.5rem",
              display: 'flex',
              float: 'right'
            }}
          >
            <FullscreenIcon />
          </IconButton> */}
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                height: "16.25rem",
                // width: "34.375rem",
                marginX: "15px",
              }}
            >
              <MultipleDates
                selectedDate={newDatesArray}
                chartData={chartData}
                dayType={dayType}
                metric="temp"
                margins={{ top: 10, right: 20, bottom: 65, left: 50 }}
                yDomain={[0, 100]}
                legend="Temperature"
                color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
            </Grid>
            <Box>
              <Modal
                open={isTempModalOpen}
                onClose={toggleTempModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <MultipleDates
                    selectedDate={newDatesArray}
                    chartData={chartData}
                    dayType={dayType}
                    metric="temp"
                    margins={{ top: 10, right: 20, bottom: 70, left: 50 }}
                    yDomain={[0, 100]}
                    legend="Temperature"
                    color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
                    pointColor=" rgba(0,0,0,0)"
                    expandedView
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  />
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      position: "absolute",
                      top: "0.3135rem",
                      right: "0.3135rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
            {/* </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              backgroundColor: colors.primary.main,
              marginTop: "1.875rem",
              display: "flex",
              // width: "36.5rem",
              borderRadius: "24px",
              height: "21.75rem",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    fontFamily: "Poppins,sans-serif",
                    marginTop: "1.25rem",
                    marginLeft: "1.25rem",
                  }}
                >
                  Humidity
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={toggleHumidityModal}
                  sx={{
                    // position: "absolute",
                    // top: "47.5rem",
                    // right: "2rem",
                    // display: 'flex',
                    // float: 'right',
                    // width: "30px"
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </Grid>
            </Grid>
            {/* <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "500",
              fontFamily: "Poppins,sans-serif",
              marginTop: "1.25rem",
              marginLeft: "1.25rem",
            }}
          >
            Humidity
          </Typography> */}
            {/* <IconButton
            onClick={toggleHumidityModal}
            sx={{
              // position: "absolute",
              // top: "47.5rem",
              // right: "2rem",
              display: 'flex',
              float: 'right',
              width: "30px"
            }}
          >
            <FullscreenIcon />
          </IconButton> */}
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                height: "16.25rem",
                marginX: "15px",
                // width: "34.375rem",
                // width: "90%"
              }}
            >
              <MultipleDates
                selectedDate={newDatesArray}
                dayType={dayType}
                chartData={chartData}
                metric="rh"
                margins={{ top: 10, right: 20, bottom: 65, left: 50 }}
                yDomain={[0, 100]}
                legend="Humidity"
                color1={[
                  "#F3C699",
                  "#919191",
                  "#E07001",
                  "#008080",
                  "#FF00FF",
                  "#FFD700",
                  "#6A5ACD",
                  "#DC143C",
                ]}
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
            </Grid>
            <Box>
              <Modal
                open={isHumidityModalOpen}
                onClose={toggleHumidityModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <MultipleDates
                    chartData={chartData}
                    selectedDate={newDatesArray}
                    dayType={dayType}
                    metric="rh"
                    margins={{ top: 10, right: 20, bottom: 70, left: 50 }}
                    yDomain={[0, 100]}
                    legend="Humidity"
                    color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
                    pointColor=" rgba(0,0,0,0)"
                    expandedView
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  />
                  <IconButton
                    onClick={toggleHumidityModal}
                    sx={{
                      position: "absolute",
                      top: "0.3135rem",
                      right: "0.3135rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
            {/* </Typography> */}
          </Box>
        </Grid>
      </Grid>
      <ResultTable
        selectedDate={newDatesArray[newDatesArray.length - 1]}
        dayType={dayType}
        newDates={arrayMultiple}
      />
    </div>
  );
};

export default Comp;
