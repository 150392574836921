import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import day from "./Assests/Bg.png";
import "./SignIn.css";
import somaiya from "./Assests/Logo1.png";
import { InputLabel, FormHelperText } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityOffRounded from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRounded from "@mui/icons-material/VisibilityRounded";
import { redirect, useNavigate } from "react-router-dom";
import night from "./Assests/night.jpg";

import { loginRequest, msalConfig } from "../../AuthProvider";
import axios from "axios";
import azure from '../../assets/fang-stocks.jpg'

import { useMsal } from "@azure/msal-react";




function SignIn() {
  // Accessing the login function from the authentication context
  const navigate = useNavigate();
  const { instance } = useMsal();
  // const handleLogout = async () => {
  //   await logout(); // Call the logout function
  //   window.location.href = "/";
  // };
  localStorage.removeItem('selectedItem');
  localStorage.removeItem('authority2');
  const handleRedirect = async () => {
    try {
      navigate('/dash2')
      // Call loginRedirect to initiate the Azure AD login process
      instance.loginRedirect({
        ...loginRequest,
        prompt: 'create',
        // redirectUri:"http://localhost:3000/dash2"
      });
      // redirect("/dash2")
      // handleTokenAcquisition();
    } catch (error) {
      
    }
  };
  // const handleTokenAcquisition = async () => {
  //   try {
  //     // Get the access token silently
  //     const accessToken = await instance.acquireTokenSilent(loginRequest);

  //     // Make the API call with the access token
  // const response = await axios.get('/hellow', {
  //   headers: {
  //     Authorization: `Bearer ${accessToken.accessToken}`,
  //   },
  // });
  //     console.log("response", response);
  //     console.log(response.data); // Should log "Hello World"

  //     navigate('/dash2');
  //   } catch (error) {
  //     console.error('Error occurred during token acquisition:', error);
  //   }
  // };
  // const handleAuthorize = async () => {
  //   try {
  //     // Call the login function when the "Authorize" button is clicked


  //     // Get the access token

  //   } catch (error) {
  //     console.error("Error occurred during authorization:", error);
  //   }
  // };



  const [imageSrc, setImageSrc] = useState(day);

  useEffect(() => {

    const interval = setInterval(() => {
      setImageSrc((prevSrc) => (prevSrc === day ? night : day));
    }, 5000);

    return () => clearInterval(interval);
  }, []);


  return (
    <Box 
    sx={{ 
      width: "100vw", 
      }}>
      <Grid container>
        <Grid item 
        sx={{ 
          width: "56%", 
          }}>
          <Box id="imgBox" component="img" alt="Daytime Image" src={imageSrc} />
          <img src={somaiya} alt="somaiya" id="somaiya" />
          <figcaption id="top">AI-Based</figcaption>
          <p id="bottom">Short-Term Load Forecasting</p>
          <Box id="footer">
            <p id="foot">Developed By</p>
            <p id="foot2"><b>K. J. Somaiya Institute of Technology  </b></p>
          </Box>
        </Grid>
        <Grid item id="signInGridItem"
          sx={{
            width: "44%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box id="signInBox" sx={{
            width: "59%",
            display: 'flex',
            flexDirection: 'column',
            // mx: 'auto',
            // marginY: '10.625rem',
          }}>
            <Typography
              component="h1"
              variant="h4"
              id="signInTitle"
              sx={{
                fontWeight: "400",
                fontFamily: 'Poppins, sans-serif',
                fontStyle: 'normal',
                fontSize: '2rem',
                lineHeight: 'normal',
                color: '#2C2C2C',
                mb: "2.5rem",
              }}
            >
              Sign In
            </Typography>

            <Button
              onClick={handleRedirect}
              fullWidth
              variant="contained"
              id="signInButton"
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                background: "#2C2C2C",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins, sans-serif",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1rem",
                paddingY: "1rem",
                paddingX: "0.5rem",
                textTransform: 'none'
              }}
            >
              Authorize with Azure
              {/* <MicrosoftIcon/> */}


            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignIn;