import React, { useEffect, useState } from "react";
import { ColorModeContext, useMode } from "./theme.js";
import { CssBaseline, ThemeProvider,Grid } from "@mui/material";
import Sidebar from "./Pages/Global/Sidebar.jsx";
import Topbar from "./Pages/Global/Topbar";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import UploadData from "./Pages/UploadData/uploadData.jsx";
import SignIn from "./Pages/SignIn/SignIn.jsx";
import Pastresult from "./Pages/PastResult/pastresults.jsx";
import Forecast from "./Pages/Forecast/Forecast.jsx";
import Dashboard2 from "./Pages/Dashboard/dashboard2.jsx";

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ToastContainer } from "react-toastify";

import { EventType, PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./AuthProvider.js";


import Comp from "./Pages/CompWPA/comp.jsx";
import Ndayforecast from "./Pages/NdayForecast/ndayforecast.jsx";
import { Helmet } from "react-helmet-async"; // Import Helmet and HelmetProvider from 'react-helmet-async'
import StatForecast from "./Pages/Statistics/StatForecast.jsx";

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const WrappedView = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [activeAccount, setActiveAccount] = useState(null);


  useEffect(() => {
    const handleLoginSuccess = (event, error, data) => {
      if (
        data &&
        data.eventType === EventType.LOGIN_SUCCESS &&
        data.payload.account
      ) {
        navigate("/dash2", { replace: true });
      }
    };

    const checkActiveAccount = () => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        setActiveAccount(accounts[0]);
        const currentPath = window.location.pathname;
        if (currentPath === "/" || currentPath === "/signin") {
          navigate("/dash2");
        } else {
          navigate(currentPath);
        }
      } else {
        
        navigate("/");
      }
    };
    setTimeout(checkActiveAccount, 500);

    return () => {
      instance.removeEventCallback(handleLoginSuccess);
    };
  }, [instance, navigate, msalInstance]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
        />
        <Helmet>
          <title>{window.location.pathname.replace("/", "")}</title>
        </Helmet>
        <div className="App">
          <AuthenticatedTemplate>
          <Routes>
              <Route
                path="/*"
                element={
                  <div>
                    <Grid container lg={12}>
                      <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4} 
                      // sx={{ background: "red" }}
                      >
                        <Sidebar isSidebar={isSidebar} />
                      </Grid>
                      <Grid item lg={9.6} md={9.6} sm={9.6} xs={9.6}>
                        <Topbar setIsSidebar={setIsSidebar} />
                        <main
                          className="content"
                          style={{
                            flexGrow: 1,
                            // marginLeft: isSidebar ? "300px" : 0,
                            transition: "margin-left 0.3s",
                          }}
                        >
                          <Routes>
                            <Route
                              exact
                              path="/Dashboard"
                              element={<Dashboard2 />}
                            />
                            <Route
                              exact
                              path="/Dashboard/*"
                              element={<Navigate to="/Dashboard" />}
                            />
                            <Route
                              exact
                              path="/*"
                              element={<Navigate to="/Dashboard" />}
                            />
                            <Route
                              exact
                              path="/StatisticalForecast"
                              element={<StatForecast />}
                            />
                            <Route
                              exact
                              path="/Upload"
                              element={
                                <div>
                                  <Helmet>
                                    <title>Upload Data</title>
                                    <meta
                                      name="description"
                                      content="Upload data page description"
                                    />
                                  </Helmet>
                                  <UploadData />
                                </div>
                              }
                            />
                            <Route
                              exact
                              path="/Verify"
                              element={<Pastresult />}
                            />
                          <Route
                            exact
                            path="/Forecast"
                            element={<Forecast />}
                          />
                  
                          <Route exact path="/CompareWithPast" element={<Comp />} />
                          <Route
                            exact
                            path="/14daysForecast"
                            element={<Ndayforecast />}
                          />
                        </Routes>
                      </main>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Helmet>
              <title>Sign In</title>
            </Helmet>
            <SignIn />
          </UnauthenticatedTemplate>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
}

export default App;
