import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Thermostat from "@mui/icons-material/Thermostat";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { useTheme, IconButton, Modal } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FirstComponent from "../../Components/DatePicker";
import PredictTable from "../../Components/PredictTable";
import Linetest from "../../Components/Linetest";
import instance from "../../middleware";
import '../../Components/multi.css'
function Forecast() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 700,
    // bgcolor: "background.paper",
    backgroundColor: colors.primary.main,
    boxShadow: 24,
    p: 4,
  };
  const [selectedDate, setSelectedDate] = useState();
  const [dayType, setDayType] = useState();
  const [data, setData] = useState({ predictLoad: null });
  const [chartData, setChartData] = useState(null);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTempModalOpen, setIsTempModalOpen] = useState(false);
  const [isHumidityModalOpen, setIsHumidityModalOpen] = useState(false);

  const [averageValues, setAverageValues] = useState({
    averageTemperature: 0,
    averageHumidity: 0,
    averageLoad: 0,
    maxLoad:0,
    minLoad:0
  });

  const toggleLoadModal = () => {
    setIsLoadModalOpen(!isLoadModalOpen);
  };

  const toggleTempModal = () => {
    setIsTempModalOpen(!isTempModalOpen);
  };

  const toggleHumidityModal = () => {
    setIsHumidityModalOpen(!isHumidityModalOpen);
  };

  const handleAverageValuesChange = (values) => {
    setAverageValues({
      averageTemperature: parseFloat(values.averageTemperature.toFixed(2)),
      averageHumidity: parseFloat(values.averageHumidity.toFixed(2)),
      averageLoad: parseFloat(values.averageLoad.toFixed(2)),
      maxLoad: parseFloat(values.maxLoad.toFixed(2)),
      minLoad: parseFloat(values.minLoad.toFixed(2))
    });
  };

  const handleDateChange = (changes) => {
    let newDate = changes[0];
    let newDayType = changes[1];
    const startDate = dayjs("2020-01-01");
    const endDate = dayjs().add(1, "day");
    const selected = dayjs(newDate);
    if (selected.isBefore(startDate) || selected.isAfter(endDate)) {
      // console.log("Selected date is out of range");
      return;
    }
    setSelectedDate(newDate);
    setDayType(newDayType)
  };

  const handleDayTypeChange = (newDayType) => {
    setDayType(newDayType);
  };

  //   try {
  //     const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
  //     console.log(selectedDate);
  //     const token = localStorage.getItem('authority2');
  //     const response = await axios.post(
  //       "http://127.0.0.1:8000/getMAERange",
  //       `FROM=${formattedDate}&TO=${formattedDate}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "Authorization": `Bearer ${token}`
  //         },
  //       }
  //     );
  //     if (response.data && response.data.maes) {
  //       setMaeData(response.data.maes[0]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching MAE range:", error);
  //   }
  // };

  const fetchChartData = async (selectedDate, dayType) => {
    if (selectedDate && dayType) {
      try {
        const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
        const startAnnDate = '2024-08-08';
        const apiEndpoint = selectedDate.isAfter(startAnnDate) ? "/predictLoad_ANN" : "/predictLoad";
        const predictLoadResponse = await instance.post(
          apiEndpoint,
          `DATE=${formattedDate}&SPECIALDAY=${dayType}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",

            },
          }
        );
        const jsonData = predictLoadResponse.data.blocks;
        setData({ predictLoad: jsonData });
        setChartData(jsonData);
      } catch (error) {
        
      }
    }
  };

  useEffect(() => {
    if (selectedDate && dayType) {
      fetchChartData(selectedDate, dayType);
    }
  }, [selectedDate, dayType]);
  return (
    <div style={{
      // marginLeft:'0.313rem'
    }}>
      <Grid lg={12} sm={12} md={12} xs={12} 
      sx={{ mt: "1.875rem" }}
      >
        <Grid sm={12} md={12} lg={12} xs={12} sx={{width:'98%'}}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "20px",
              // width: "99%",
              alignItems: "center",
              // marginTop: "7.2rem",
              position: "relative",
              top: 0,
              // marginLeft: "0.313rem",
              zIndex: 0,
              height: "5rem",
              backgroundColor: colors.primary.main,
              boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
            }}
          >

            <Grid container xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", alignItems: "center", marginLeft: "0rem", width: '100%' }}>
              <Grid item sx={{}} xs={6} sm={6} md={1.7} lg={1.7} >
                <Typography
                  sx={{
                    marginTop: "0.313rem",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginLeft: "1.3rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.primary.dark,
                    width: '100%'
                  }}
                >
                  Forecast for
                </Typography></Grid>


              <Grid xs={10} sm={10} md={10} lg={10.2}>
                <FirstComponent
                  onDateChange={handleDateChange}
                  onDayTypeChange={handleDayTypeChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container columnSpacing={1.5} lg={12} sm={12} md={12} xs={12} sx={{
           marginTop: '2rem',
           width:'100%'
        }}>
          <Grid  item xs={12} sm={12} md={10.2} lg={10.2}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",
                // width: "64rem",
                justifyContent: "flex-start",
                // alignItems: "center,
                position: "relative",
                top: 0,
                height: "30rem",
                // marginRight: '1.5rem',
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
            <Grid container sx={{
              display:'flex',
              justifyContent:'space-between'
            }}>
            <Grid item>
            <Typography
            display="flex"
            variant="h2"
            style={{
              fontSize: "1.563rem",
              fontWeight: "600",
              fontFamily: "Poppins,sans-serif",
              color: colors.grey[900],
              display: "flex",
              marginTop: "2rem",
              marginLeft: '2rem',

            }}
          >
            Load
          </Typography>
            </Grid>
            <Grid item>
            <IconButton
            onClick={toggleLoadModal}
            sx={{
              // position: "absolute",
              // top: "1rem",
              // right: "1rem",
              marginRight:'16px',
              marginTop:'16px'
            }}
          >
            <FullscreenIcon />
          </IconButton>
            </Grid>
            </Grid>


              <Linetest
                selectedDate={selectedDate}
                dayType={dayType}
                metric="wdLoad"
                margins={{ top: 10, right: 30, bottom: 120, left: 70 }}
                yDomain={[0, 100]}
                legend="Load"
                pointColor=" rgba(0,0,0,0)"
                actualData={data.getData}
                predictedData={data.predictLoad}
                chartData={chartData}
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
                color1={[colors.linechart[600]]}
                containercolor={colors.linechart[400]}
              />
            </Grid>
            <Box>
              <Modal
                open={isLoadModalOpen}
                onClose={toggleLoadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <Linetest
                    selectedDate={selectedDate}
                    dayType={dayType}
                    metric="wdLoad"
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    yDomain={[0, 70]}
                    legend="Load"
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    chartData={chartData}
                    color1={colors.linechart[600]}
                    containercolor={colors.linechart[400]}
                    expandedView
                  />
                  <IconButton
                    onClick={toggleLoadModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
          <Grid item sx={{  }} xs={12} sm={12} md={1.5} lg={1.64}>
            <Grid lg={12} xs={12} sm={12} md={12}
              sx={{
                backgroundColor: colors.grey[480],
                paddingY: "0.625rem",
                marginBottom: "1rem",
                borderRadius: "15px",
                display: 'flex',
                // width: "9.38rem",
              }}
            >
              <Box>
                <Grid container
                  sx={{
                    display: 'flex',
                    // justifyContent: 'space-around',
                    width: "9.38rem"
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[800],
                        width: "1.875rem",
                        height: "1.875rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          color: "white",
                          padding: "0.313rem",
                          borderRadius: "8px",
                          color: colors.primary["main"],
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: "0.2rem",
                        fontSize: "1.125rem",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: '500',
                        color: colors.grey[900],
                      }}
                    >
                      {averageValues.averageLoad}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.grey[680],
                    marginLeft: "1.25rem",
                  }}
                >
                  {" "}
                  Avg. Load (MW)
                </Typography>
              </Box>
            </Grid>
            <Grid lg={12} xs={12} sm={12} md={12}
              sx={{
                backgroundColor: colors.orange[100],
                paddingY: "0.625rem",
                marginBottom: "1rem",
                borderRadius: "15px",
                display: 'flex',
                // width: "9.38rem",
              }}
            >
              <Box>
                <Grid container
                  sx={{
                    display: 'flex',
                    width: "9.38rem"
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[800],
                        width: "1.875rem",
                        height: "1.875rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <Thermostat
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          color: "white",
                          padding: "0.313rem",
                          borderRadius: "8px",
                          color: colors.primary["main"],
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: "0.2rem",
                        fontSize: "1.125rem",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: '500',
                        color: colors.grey[900],
                      }}
                    >
                      {averageValues.averageTemperature}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.grey[680],
                    marginLeft: "1.25rem",
                  }}
                >
                  {" "}
                  Avg. Temp (°C)
                </Typography>
              </Box>
            </Grid>
            <Grid lg={12} xs={12} sm={12} md={12}
              sx={{
                backgroundColor: colors.purple[200],
                paddingY: "0.625rem",
                marginBottom: "1rem",
                borderRadius: "15px",
                display: 'flex',
                // width: "9.38rem",
              }}
            >
              <Box>
                <Grid container
                  sx={{
                    display: 'flex',
                    width: "9.38rem"
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[800],
                        width: "1.875rem",
                        height: "1.875rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <WaterDropIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          color: "white",
                          padding: "0.313rem",
                          borderRadius: "8px",
                          color: colors.primary["main"],
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: "0.2rem",
                        fontSize: "1.125rem",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: '500',
                        color: colors.grey[900],
                      }}
                    >
                      {averageValues.averageHumidity}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.grey[680],
                    marginLeft: "1.25rem",
                  }}
                >
                  {" "}
                  Avg. Hum (%)
                </Typography>
              </Box>
            </Grid>
            <Grid lg={12} xs={12} sm={12} md={12}
              sx={{
                backgroundColor: colors.red[100],
                paddingY: "0.625rem",
                marginBottom: "1rem",
                borderRadius: "15px",
                display: 'flex',
                // width: "9.38rem",
              }}
            >
              <Box>
                <Grid container
                  sx={{
                    display: 'flex',
                    // justifyContent: 'space-around',
                    width: "9.38rem"
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[800],
                        width: "1.875rem",
                        height: "1.875rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          color: "white",
                          padding: "0.313rem",
                          borderRadius: "8px",
                          color: colors.primary["main"],
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: "0.2rem",
                        fontSize: "1.125rem",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: '500',
                        color: colors.grey[900],
                      }}
                    >
                      {averageValues.maxLoad}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.grey[680],
                    marginLeft: "1.25rem",
                  }}
                >
                  {" "}
                  Max. Load (MW)
                </Typography>
              </Box>
            </Grid>
            <Grid lg={12} xs={12} sm={12} md={12}
              sx={{
                backgroundColor: colors.red[100],
                paddingY: "0.625rem",
                borderRadius: "15px",
                display: 'flex',
                // width: "9.38rem",
              }}
            >
              <Box>
                <Grid container
                  sx={{
                    display: 'flex',
                    width: "9.38rem"
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        backgroundColor: colors.grey[800],
                        width: "1.875rem",
                        height: "1.875rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          color: "white",
                          padding: "0.313rem",
                          borderRadius: "8px",
                          color: colors.primary["main"],
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: "0.2rem",
                        fontSize: "1.125rem",
                        fontFamily: "Poppins,sans-serif",
                        fontWeight: '500',
                        color: colors.grey[900],
                      }}
                    >
                      {averageValues.minLoad}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "0.938rem",
                    fontFamily: "Poppins,sans-serif",
                    color: colors.grey[680],
                    marginLeft: "1.25rem",
                  }}
                >
                  {" "}
                  Min. Load (MW)
                </Typography>
              </Box>
            </Grid>

          </Grid>
        </Grid>
        <Grid container columnSpacing={2} lg={12} md={12} sm={12} xs={12}  sx={{ marginTop: "1.875rem",width:'99%' }}>

          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ }}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",
                
                justifyContent: "flex-start",
                // alignItems: "center",
                position: "relative",
                top: 0,
                height: "21.875rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
              <Grid container sx={{
                display:'flex',
                justifyContent:'space-between'
              }} >
                <Grid item>
                  <Typography
                    display="flex"
                    variant="h2"
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "600",
                      marginTop: "1.75rem",
                      fontFamily: "Poppins,sans-serif",
                      color: colors.grey[900],
                      display: "flex",
                      // marginBottom: "1.5rem",
                      marginTop: "2rem",
                      marginLeft: '1.875rem',
                    }}
                  >
                    Temperature
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      // position: "absolute",
                      // top: "1rem",
                      // right: "1rem",
                      marginTop:'15px',
                      marginRight:'15px'

                    }}

                  >
                    <FullscreenIcon />
                  </IconButton>
                </Grid>
              </Grid>


              <Linetest
                selectedDate={selectedDate}
                dayType={dayType}
                metric="temp"
                actualData={data.getData}
                predictedData={data.predictLoad}
                margins={{ top: 5, right: 30, bottom: 120, left: 70 }}
                legend="Temperature"
                pointColor=" rgba(0,0,0,0)"
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
                color1={[colors.linechart[200]]}
                containercolor={colors.linechart[200]}
              // chartData={chartData}
              />
            </Grid>
            <Box>
              <Modal
                open={isTempModalOpen}
                onClose={toggleTempModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <Linetest
                    selectedDate={selectedDate}
                    dayType={dayType}
                    metric="temp"
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    legend="Temperature"
                    color1={[colors.linechart[200]]}
                    containercolor={colors.linechart[200]}
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  // chartData={chartData}
                  />
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{  }}>
            <Grid
              sx={{
                backgroundColor: colors.primary.main,
                display: "flex",
                flexDirection: "column",
                borderRadius: "1.25rem",
                
                justifyContent: "flex-start",
                // alignItems: "center",
                position: "relative",
                top: 0,
                height: "21.875rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
            <Grid container sx={{
              display:'flex',
              justifyContent:'space-between'
            }}>
            <Grid item>
            <Typography
                display="flex"
                variant="h2"
                style={{
                  fontSize: "1.563rem",
                  fontWeight: "600",
                  marginTop: "1.75rem",
                  fontFamily: "Poppins,sans-serif",
                  color: colors.grey[900],
                  display: "flex",
                  marginTop: "2rem",
                  marginLeft: '1.875rem',
                }}
              >
                Humidity
              </Typography>
            </Grid>
            <Grid item>
            
            <IconButton
            onClick={toggleHumidityModal}
            sx={{
              // position: "absolute",
              // top: "1rem",
              // right: "1rem",
              marginRight:'15px',
              marginTop:'15px',
            }}
          >
            <FullscreenIcon />
          </IconButton>
            </Grid>
            </Grid>
              
              <Linetest
                selectedDate={selectedDate}
                dayType={dayType}
                metric="rh"
                yDomain={[0, 70]}
                margins={{ top: 4, right: 30, bottom: 120, left: 70 }}
                actualData={data.getData}
                predictedData={data.predictLoad}
                legend="Humidity"
                color1={[colors.linechart[300]]}
                containercolor={colors.linechart[300]}
                pointColor=" rgba(0,0,0,0)"
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              // chartData={chartData}
              />
            </Grid>
            <Box>
              <Modal
                open={isHumidityModalOpen}
                onClose={toggleHumidityModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <Linetest
                    selectedDate={selectedDate}
                    dayType={dayType}
                    metric="rh"
                    margins={{ top: 80, right: 0, bottom: 70, left: 50 }}
                    actualData={data.getData}
                    predictedData={data.predictLoad}
                    legend="Humidity"
                    color1={[colors.linechart[300]]}
                    pointColor=" rgba(0,0,0,0)"
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  // chartData={chartData}
                  />
                  <IconButton
                    onClick={toggleHumidityModal}
                    sx={{
                      position: "absolute",
                      top: "0.625rem",
                      right: "0.625rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ }}>
        <PredictTable
          selectedDate={selectedDate}
          dayType={dayType}
          onAverageValuesChange={handleAverageValuesChange}
        />
      </Box>
    </div>
  );
}
export default Forecast;