// // export default 

// import axios from 'axios';

// const axios = require('axios');

// const createApiInstance = (redisClient, refreshAccessToken) => {
//   const axiosApiInstance = axios.create();

//   axiosApiInstance.interceptors.request.use(
//     async (config) => {
//       const value = await redisClient.get(rediskey);
//       const keys = JSON.parse(value);
//       config.headers = {
//         'Authorization': `Bearer ${keys.access_token}`,
//         'Accept': 'application/json',
//         'Content-Type': 'application/x-www-form-urlencoded'
//       };
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
//   );

//   axiosApiInstance.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     async (error) => {
//       const originalRequest = error.config;
//       if (error.response.status === 403 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const access_token = await refreshAccessToken();
//         axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//         return axiosApiInstance(originalRequest);
//       }
//       return Promise.reject(error);
//     }
//   );

//   return axiosApiInstance;
// };

// // Example usage
// // const redisClient = require('your-redis-client');
// // const refreshAccessToken = require('your-refresh-token-function');
// // const apiInstance = createApiInstance(redisClient, refreshAccessToken);

// // Then use apiInstance for your API calls







// import axios from 'axios';


// const axios = require('axios');
// const { PublicClientApplication } = require('@azure/msal-browser');

// const azureConfig = {
//   clientId: '70866e09-cfe8-4de3-a5d0-789dddb8ee68',
//   authority: 'https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a',
//   redirectUri: 'http://localhost:3000/dash2/', 
// };

// const pca = new PublicClientApplication(azureConfig);

// const getToken = async () => {
//   const accounts = pca.getAllAccounts();
//   if (accounts.length === 0) {
//     throw new Error('No accounts found');
//   }
//   const silentRequest = {
//     scopes: ['openid', 'profile', 'user.read'],
//     account: accounts[0]
//   };
//   try {
//     const response = await pca.acquireTokenSilent(silentRequest);
//     return response.accessToken;
//   } catch (error) {
//     throw new Error('Silent token acquisition failed. Acquiring token using popup');
//   }
// };

// const axiosApiInstance = axios.create();

// axiosApiInstance.interceptors.request.use(
//   async (config) => {
//     const token = await getToken();
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );


// axiosApiInstance.get('http://127.0.0.1:8000/getData')
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(error => {
//     console.error(error);
//   });
// // Use axiosApiInstance for your API calls


// middleware
// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { useMsal } from '@azure/msal-react';


// const UseAxiosInterceptor = ({ children,msalInstance  }) => {
//   const [isSet, setIsSet] = useState(false);
//   const navigate = useNavigate();
//   const { instance } = useMsal();
//   const [activeAccount, setActiveAccount] = useState(null);
//   let token1;
//   useEffect(() => {
//     const accounts = instance.getAllAccounts();
//     if (accounts.length > 0) {
//       setActiveAccount(accounts[0]);
//       const token = accounts[0].username;
//       token1 = accounts[0].idToken;
//       console.log("object", token1)
//       console.log("username", accounts[0].idToken);
//     }
//   }, [instance]);
//   useEffect(() => {
//     const api = axios.create({
//       baseURL: 'http://127.0.0.1:8000/',
//     });

//     const requestInterceptor = api.interceptors.request.use(async (config) => {
//       console.log("Intercepting request:", config.url);
//       let token = localStorage.getItem('authority2');

//       if (!token && activeAccount) { // Check if user is already authenticated
//         try {
//           const accessToken = await msalInstance.acquireTokenSilent({
//             scopes: [
//               "api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation",
//             ],
//           });
//           token = accessToken.accessToken;
//           console.log("token auth", token)
//         localStorage.setItem("authority2", token);
//         } catch (error) {
//           console.error("Failed to acquire token:", error);
//           throw new Error('Bearer token is missing');
//         }
//       }
//       const accounts = instance.getAllAccounts();
//     if (accounts.length > 0) {
//       setActiveAccount(accounts[0]);
      
//     }
//     const idTok = accounts[0].idToken;
//       config.headers.Authorization = `Bearer ${token1}`;
//       console.log("Authorized Request",idTok);
//       return config;
//     });

//     const responseInterceptor = api.interceptors.response.use(
//       response => response,
//       error => {
//         if (error.response && error.response.status === 401) {
//           console.log('Unauthorized: Logging out user');
//           // Perform logout actions here, e.g., redirect to login page
//           navigate('/signin'); // Redirect to login page
//         } else {
//           // Handle other errors
//           console.error('API request failed:', error.message);
//           toast.error(`API request failed: ${error.message}`); // Display error message using toast
//         }
//         return Promise.reject(error);
//       }
//     );

//     setIsSet(true);

//     return () => {
//       api.interceptors.request.eject(requestInterceptor);
//       api.interceptors.response.eject(responseInterceptor);
//     };
//   }, [navigate, activeAccount]);

//   return isSet && activeAccount ? children : null;
// };


// export default UseAxiosInterceptor;
// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import { useMsal } from '@azure/msal-react';

// const api = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
// });

// const ApiComponent = () => {
//   const [activeAccount, setActiveAccount] = useState(null);
//   const { instance } = useMsal();
//   let token1;
//   const isTokenPresent = () => {
//     const accounts = instance.getAllAccounts();
//     if (accounts.length > 0) {
//       setActiveAccount(accounts[0]);
//       return accounts[0].idToken;
//       token1 = accounts[0].idToken
//     }
//     return null;
//   };

//   useEffect(() => {
//     const token = isTokenPresent();
//     if (token) {
//       api.interceptors.request.use(async (config) => {
//         console.log("Intercepting request:", config.url);
//         if (
//           config.url.includes('/predictLoad') ||
//           config.url.includes('/getData') ||
//           config.url.includes('/getMaeRange') ||
//           config.url.includes('/uploadWeatherData') ||
//           config.url.includes('/uploadLoadData') ||
//           config.url.includes('/getWeatherHeatMap') ||
//           config.url.includes('/getLoadHeatMap')
//         ) {
//           config.headers.Authorization = `Bearer ${token1}`;
//         }
//         return config;
//       });
//     }
//   }, [instance, isTokenPresent]);

//   return null; // or whatever you need to return here
// };

// export default ApiComponent;


// import { useMsal } from '@azure/msal-react';
// import axios from 'axios';
// import { useState, useEffect } from 'react';

// const useApi = () => {
//   const { instance } = useMsal();
//   const [activeAccount, setActiveAccount] = useState(null);
//   const [token, setToken] = useState(null);
  
//   useEffect(() => {
//     const isTokenPresent = () => {
//       const accounts = instance.getAllAccounts();
//       if (accounts.length > 0) {
//         setActiveAccount(accounts[0]);
//         setToken(accounts[0].idToken);
//         return accounts[0].idToken;
//       }
//       return null;
//     };

//     isTokenPresent();
//   }, [instance]);

//   const api = axios.create({
//     baseURL: 'http://127.0.0.1:8000/',
//     headers: {
//       Authorization: `Bearer ${token}` // Include the token in the Authorization header
//     }
//   });
//   console.log("Bearer",token)

//   // Add a request interceptor
//   api.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     // document.getElementById('overlay').style.display = 'block';
//     console.log('req');
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

//   // Add a response interceptor
//   api.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     document.getElementById('overlay').style.display = 'none';
//     console.log('res');
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });

//   return { activeAccount, token, api };
// };

// export default useApi;




// import { PublicClientApplication } from "@azure/msal-browser";
// import axios from "axios";
// import { msalConfig } from "./AuthProvider.js";
// import { toast } from 'react-toastify';

// const msalInstance = new PublicClientApplication(msalConfig);
// msalInstance.initialize();

// const instance = axios.create({
//   baseURL: "https://tatapowerbackend.somaiyatech.in",
// });

// instance.interceptors.request.use(
//   async function (config) {
//     try {
//       await msalInstance.handleRedirectPromise(); 
//       const accounts = msalInstance.getAllAccounts();

//       if (accounts.length > 0) {
//         const accessTokenRequest = {
//           scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
//           account: accounts[0],
//         };

//         const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
//         const accessToken = accessTokenResponse.accessToken;
//         localStorage.setItem('authority2', accessToken);
//         config.headers.Authorization = `Bearer ${accessToken}`;
//       }

//       return config;
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error.response && error.response.status === 401) {
//       toast.error("Session expired. You have been logged out.");
//       msalInstance.loginRedirect().then(() => {
//         localStorage.clear();
//         window.location.href = "/";
//       }).catch(err => {
        
//       });
//     } else if (error.message === 'Network Error') {
      
//       toast.error("Network Error occurred. Redirecting to home page...");
//       msalInstance.logoutRedirect().then(() => {
//         localStorage.clear();
//         window.location.href = "/";
//       }).catch(err => {
        
//       });
//     }
//     return Promise.reject(error);
//   }
// );

// export default instance;


import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { msalConfig } from "./AuthProvider.js";
import { toast } from 'react-toastify';

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const instance = axios.create({
  baseURL: "https://tatapowerbackend.somaiyatech.in",
  // baseURL: "http://127.0.0.1:8000",
});

instance.interceptors.request.use(
  async function (config) {
    try {
      await msalInstance.handleRedirectPromise(); 
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
          account: accounts[0],
        };

        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        const accessToken = accessTokenResponse.accessToken;
        localStorage.setItem('authority2', accessToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      toast.error("Session expired. You have been logged out.");
      await msalInstance.logoutRedirect().catch(err => {
        // console.error('Logout Redirect Error:', err);
      });
      localStorage.clear();
      window.location.href = "/";
    } else if (error.message === 'Network Error') {
      toast.error("Network Error occurred. Redirecting to home page...");
      await msalInstance.logoutRedirect().catch(err => {
        // console.error('Logout Redirect Error:', err);
      });
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default instance;
