import React, { forwardRef, useEffect, useRef, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { tokens } from "../theme";
import Spinner from "./Spinner";
import CustomSliceTooltip from "./Tooltip";
import domtoimage from "dom-to-image-more";
import { Button, Grid, useTheme } from "@mui/material";
import Download from "@mui/icons-material/Download";
import { toast } from "react-toastify";

const LineChart2 = React.forwardRef(
  (
    {
      margins,
      minValue,
      maxValue,
      legend,
      color1,
      pointFaceColor,
      pointWidth,
      lineWidth,
      predictedData

    },
    ref
  ) => {
    const chartRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [xcord, setXCord] = useState();
    const [ycord, setYCord] = useState();
    const [hoveredId, setHoveredId] = useState(null);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
      if (!predictedData || predictedData.length !== 14) {
       
        return;
      }
      const formattedData = predictedData.map(entry => ({
        id: entry.date,
        data: entry.blocks.map((block, index) => ({
          x: timestamps[index].label,
          y: block.wdLoad
        }))
      }));
      setChartData([{
        id: formattedData[13].id,
        data: formattedData[13].data,
      },{
        id: formattedData[12].id,
        data: formattedData[12].data,
      },{
        id: formattedData[11].id,
        data: formattedData[11].data,
      },{
        id: formattedData[10].id,
        data: formattedData[10].data,
      },{
        id: formattedData[9].id,
        data: formattedData[9].data,
      },{
        id: formattedData[8].id,
        data: formattedData[8].data,
      },{
        id: formattedData[7].id,
        data: formattedData[7].data,
      },{
        id: formattedData[6].id,
        data: formattedData[6].data,
      }, {
        id: formattedData[5].id,
        data: formattedData[5].data,
      }, {
        id: formattedData[4].id,
        data: formattedData[4].data,
      }, {
        id: formattedData[3].id,
        data: formattedData[3].data,
      }, {
        id: formattedData[2].id,
        data: formattedData[2].data,
      }, {
        id: formattedData[1].id,
        data: formattedData[1].data,
      }, {
        id: formattedData[0].id,
        data: formattedData[0].data,
      },
      ]);
    }, [predictedData]);

    const handleMouseEnter = (point) => {
      setHoveredId(point.points[0].data.x);
      let ymax = 0;
      point.points.forEach(point => {
        const ynow = point.y;
        if (ynow > ymax) {
          ymax = ynow;
        }
      });
      setXCord(point.points[0].x0)
      setYCord(point.height - ymax);
    };


    const handleMouseLeave = () => {
      setHoveredId(null);
    };

    const exportChartToPng = () => {
      if (chartRef.current) {
        domtoimage
          .toPng(chartRef.current, {
            width: chartRef.current.offsetWidth * 2,
            height: chartRef.current.offsetHeight * 2,
            style: {
              transform: "scale(" + 2 + ")",
              transformOrigin: "top ",
            },
          })
          .then(function (dataUrl) {
            const link = document.createElement("a");
            link.href = dataUrl;
            const ndateString = localStorage.getItem('ndate');
            const ndate = ndateString ? new Date(ndateString) : new Date();
            const formattedDate = `${ndate.getFullYear()}/${ndate.getMonth() + 1}/${ndate.getDate()}`;
          
            link.download = `PredictedLoad-Chart-After-${formattedDate}.png`;
            document.body.appendChild(link); 
            link.click();
            document.body.removeChild(link); // Remove the link from the body after clicking
          })
          .catch(function (error) {
            toast.error(`Error Exporting Chart: ${error}` );
          });
      }
    };
    
    

    if (!chartData || chartData.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            zIndex: 100,
            alignContent: "center !important",
            justifyContent: "center",
            translateY: "50%",
            marginTop: "6.25rem",
          }}
        >
          <Spinner />
        </div>
      );
    }

    return (
      <div style={{ height: "80%", width: "100%" }}>
        <Grid container sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            sx={{
              color: colors.primary.dark,
              fontFamily: "Poppins,sans-serif",
              textTransform: "none",
              width: "2.5rem",
              height: "2.5rem",
              minWidth: "10px",
              borderRadius: "12px",
              background: colors.grey[200],
              mr: "10px",
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.125rem",
              textAlign: "center",
              "&:hover": {
                color: colors.primary.dark,
                background: colors.grey[200],
              },
              "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                webkitTapHighlightColor: colors.primary["black"],
              },
            }}
            onClick={exportChartToPng}
          >
            <Download />
          </Button>
        </Grid>
        <div
          ref={chartRef}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            backgroundColor:colors.primary.main
          }}
        >
          <ResponsiveLine
            data={chartData}
            enableSlices="x"
            sliceTooltip={({ slice }) => <CustomSliceTooltip slice={slice} />}
            useCollapseData={true}
            enableCrosshair={false}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.primary["black"],
                  },
                },
                ticks: {
                  text: {
                    fill: colors.primary["black"],
                  },
                  line: {
                    stroke: colors.grey[100],
                  },
                },
              },
              grid: {
                line: {
                  stroke: colors.grey[480],
                  strokeWidth: 1,
                },
              },
              crosshair: {
                line: {
                  stroke: 'red',
                  strokeOpacity: 1,
                  strokeDasharray: 'none',
                  strokeWidth: 2,
                },
              },
            }}
            margin={margins}
            yDomain={[minValue, maxValue]}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickPadding: 8,
              tickSize: 5,
              tickRotation: 0,
              tickTextColor: "blue",
              legendOffset: 80,
              legendPosition: "middle",
              tickValues: timestamps
                .filter(
                  (timestamp) =>
                    timestamp.label.endsWith(":00") && timestamp.value % 3 === 0
                )
                .map((timestamp) => timestamp.label),
              format: (value) => value.slice(0, 2),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: [legend],
              legendOffset: -40,
              legendPosition: "middle",
            }}
            lineWidth={lineWidth}
            enableGridX={false}
            enableGridY={true}
            pointSize={10}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            pointColor={pointFaceColor}
            pointBorderColor={{ from: "serieColor" }}
            pointBorderWidth={pointWidth}
            pointLabelYOffset={-12}
            useMesh={true}
            colors={color1}
            legends={[
              {
                anchor: "bottom",
                
                direction: "row",
                justify: false,
                translateX: -30,
                translateY: 70,
                itemsSpacing: 20,
                itemDirection: "left-to-right",
                itemWidth: 65,
                toggleSerie: true,
                itemHeight: 20,
                itemTextColor: colors.primary["black"],
                itemOpacity: 1.75,
                symbolSize: 10,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                      itemBackground: "rgba(0, 0, 0, .03)",
                    },
                  },
                ],
              },
            ]}
            pointSymbol={(point) => {
              const lineColor = point.borderColor;
              const isHovered = point.datum.x === hoveredId && hoveredId !== null;
              return (
                <g>
                  {isHovered && (
                    <line
                      x1={xcord}
                      y1="-100%"
                      x2={xcord}
                      y2={ycord}
                      style={{ stroke: "#FFA07A", strokeWidth: 1, pointerEvents: "none" }}
                    />
                  )}
                  <circle
                    cx={0}
                    cy={0}
                    r={isHovered ? 6 : 2}
                    fill={lineColor}
                    fillOpacity={0.8}
                    strokeWidth={isHovered ? 0.6 : 0.2}
                    stroke={isHovered ? { lineColor } : "#FFFFFF"}
                  />
                </g>
              );
            }}
          />
        </div>
      </div>
    );
  }
);
export default LineChart2;

const timestamps = [
  { label: "00:00", value: 0 },
  { label: "00:15", value: 1 },
  { label: "00:30", value: 2 },
  { label: "00:45", value: 3 },
  { label: "01:00", value: 4 },
  { label: "01:15", value: 5 },
  { label: "01:30", value: 6 },
  { label: "01:45", value: 7 },
  { label: "02:00", value: 8 },
  { label: "02:15", value: 9 },
  { label: "02:30", value: 10 },
  { label: "02:45", value: 11 },
  { label: "03:00", value: 12 },
  { label: "03:15", value: 13 },
  { label: "03:30", value: 14 },
  { label: "03:45", value: 15 },
  { label: "04:00", value: 16 },
  { label: "04:15", value: 17 },
  { label: "04:30", value: 18 },
  { label: "04:45", value: 19 },
  { label: "05:00", value: 20 },
  { label: "05:15", value: 21 },
  { label: "05:30", value: 22 },
  { label: "05:45", value: 23 },
  { label: "06:00", value: 24 },
  { label: "06:15", value: 25 },
  { label: "06:30", value: 26 },
  { label: "06:45", value: 27 },
  { label: "07:00", value: 28 },
  { label: "07:15", value: 29 },
  { label: "07:30", value: 30 },
  { label: "07:45", value: 31 },
  { label: "08:00", value: 32 },
  { label: "08:15", value: 33 },
  { label: "08:30", value: 34 },
  { label: "08:45", value: 35 },
  { label: "09:00", value: 36 },
  { label: "09:15", value: 37 },
  { label: "09:30", value: 38 },
  { label: "09:45", value: 39 },
  { label: "10:00", value: 40 },
  { label: "10:15", value: 41 },
  { label: "10:30", value: 42 },
  { label: "10:45", value: 43 },
  { label: "11:00", value: 44 },
  { label: "11:15", value: 45 },
  { label: "11:30", value: 46 },
  { label: "11:45", value: 47 },
  { label: "12:00", value: 48 },
  { label: "12:15", value: 49 },
  { label: "12:30", value: 50 },
  { label: "12:45", value: 51 },
  { label: "13:00", value: 52 },
  { label: "13:15", value: 53 },
  { label: "13:30", value: 54 },
  { label: "13:45", value: 55 },
  { label: "14:00", value: 56 },
  { label: "14:15", value: 57 },
  { label: "14:30", value: 58 },
  { label: "14:45", value: 59 },
  { label: "15:00", value: 60 },
  { label: "15:15", value: 61 },
  { label: "15:30", value: 62 },
  { label: "15:45", value: 63 },
  { label: "16:00", value: 64 },
  { label: "16:15", value: 65 },
  { label: "16:30", value: 66 },
  { label: "16:45", value: 67 },
  { label: "17:00", value: 68 },
  { label: "17:15", value: 69 },
  { label: "17:30", value: 70 },
  { label: "17:45", value: 71 },
  { label: "18:00", value: 72 },
  { label: "18:15", value: 73 },
  { label: "18:30", value: 74 },
  { label: "18:45", value: 75 },
  { label: "19:00", value: 76 },
  { label: "19:15", value: 77 },
  { label: "19:30", value: 78 },
  { label: "19:45", value: 79 },
  { label: "20:00", value: 80 },
  { label: "20:15", value: 81 },
  { label: "20:30", value: 82 },
  { label: "20:45", value: 83 },
  { label: "21:00", value: 84 },
  { label: "21:15", value: 85 },
  { label: "21:30", value: 86 },
  { label: "21:45", value: 87 },
  { label: "22:00", value: 88 },
  { label: "22:15", value: 89 },
  { label: "22:30", value: 90 },
  { label: "22:45", value: 91 },
  { label: "23:00", value: 92 },
  { label: "23:15", value: 93 },
  { label: "23:30", value: 94 },
  { label: "23:45", value: 95 },
];