import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Grid,
  Box,
  Modal,
  IconButton,
  useTheme,
  Button,
  InputLabel,
} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import { tokens } from "../../theme";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import StatisticalDates from "../../Components/StatisticalDates";
import instance from "../../middleware";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import StatisticalTable from "../../Components/StatisticalTable";

const StatForecast = () => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1500,
    height: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTempModalOpen, setIsTempModalOpen] = useState(false);
  const [isHumidityModalOpen, setIsHumidityModalOpen] = useState(false);
  const [newDatesArray, setNewDatesArray] = useState([]);
  const [newDiffArray, setNewDiffArray] = useState([]);
  const [blockDates, setBlockDates] = useState([]);
  const [done, setDone] = useState("No");
  const [response, setResponse] = useState(null);
  const [selectDate, setSelectedDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [refDate, setRefDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [d1Date, setD1Date] = useState();
  const [d2Date, setD2Date] = useState();
  const [dayType, setDayType] = useState("Normal Day");
  const [multipleDates, setMultipleDates] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const toggleLoadModal = () => {
    setIsLoadModalOpen(!isLoadModalOpen);
  };

  const toggleTempModal = () => {
    setIsTempModalOpen(!isTempModalOpen);
  };

  const toggleHumidityModal = () => {
    setIsHumidityModalOpen(!isHumidityModalOpen);
  };

  const [chartData, setChartData] = useState(null);

  const handleDateChange = (newDate) => {
    setDone("No");
    setSelectedDate(newDate.format("YYYY-MM-DD"));
    setRefDate(newDate.format("YYYY-MM-DD"));
  };

  const handleRDateChange = (newDate) => {
    setRefDate(newDate.format("YYYY-MM-DD"));
  };

  const handleD1DateChange = (Date) => {
    setD1Date(Date.format("YYYY-MM-DD"));
  };

  const handleD2DateChange = (Date) => {
    setD2Date(Date.format("YYYY-MM-DD"));
  };

  const handleDateButtonClick = () => {
    if (d1Date && d2Date && d1Date !== d2Date) {
      const existing = newDiffArray;
      const diffarray = [...existing, [d1Date, d2Date]];
      setNewDiffArray(diffarray);
      setD1Date(null);
      setD2Date(null);
      setResponse([newDatesArray, diffarray, refDate]);
      toast.success('Dates Added')
    } else {
      toast.error("Not Possible");
    }
  };

  const handleUpdate = (selectedDates) => {
    setMultipleDates(selectedDates);
    const formattedDates = selectedDates.map((date) =>
      dayjs(date).format("YYYY-MM-DD")
    );
    setNewDatesArray(formattedDates);
    setResponse([formattedDates, newDiffArray, refDate]);
    setOpen(false);

  };

  useEffect(() => {
    const generateNextDays = () => {
      const daysArray = [];
      for (let i = 0; i < 1000; i++) {
        daysArray.push(dayjs().add(i, "day")["$d"]);
      }
      setBlockDates(daysArray);
    };
    generateNextDays();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectDate === refDate && done == "No") {
          const params = new URLSearchParams();
          params.append("From", selectDate);

          const response = await instance.post("/statical_date", params, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          if (response.data) {
            setNewDatesArray(response.data.dates);
            setNewDiffArray(response.data.difference);
            const selectmultipleDates = response.data.dates.map(
              (date) => dayjs(date)["$d"]
            );
            setMultipleDates(selectmultipleDates);
            setResponse([
              response.data.dates,
              response.data.difference,
              refDate,
            ]);
            setDone("Yes");
          }
        } else {
          setResponse([newDatesArray, newDiffArray, refDate]);
        }
      } catch (error) {
        toast.error("No Data Available");
      }
    };
    fetchData();
  }, [refDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (newDatesArray.length > 0) {
          const promises = newDatesArray.map(async (date) => {
            const response = await instance.post(
              "/getData",
              `DATE=${dayjs(date).format("YYYY-MM-DD")}`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
            const jsonData = response.data.blocks;
            return { jsonData };
          });
          let predictionResponse;

          if (refDate == selectDate) {
            const startAnnDate = "2024-08-08";
            const apiEndpoint = dayjs(selectDate).isAfter(startAnnDate)
              ? "/predictLoad_ANN"
              : "/predictLoad";
            predictionResponse = await instance.post(
              apiEndpoint,
              `DATE=${dayjs(refDate).format(
                "YYYY-MM-DD"
              )}&SPECIALDAY=${dayType}`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          } else {
            predictionResponse = await instance.post(
              "/getData",
              `DATE=${dayjs(refDate).format("YYYY-MM-DD")}`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          }
          const predictionData = predictionResponse.data.blocks;
          const chartData = await Promise.all(promises);
          setChartData([...chartData, { jsonData: predictionData }]);
        }
      } catch (error) {
        toast.error("Error");
      }
    };
    fetchData();
  }, [newDatesArray]);

  return (
    <div>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "20px",
          width: "98%",
          alignItems: "center",
          marginTop: "1.875rem",
          position: "relative",
          top: 0,
          zIndex: 0,
          paddingTop: "20px",
          paddingBottom: "20px",
          backgroundColor: colors.primary.main,
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
          justifyContent: "space-between",
        }}
      >
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container>
              <Grid item style={{ marginLeft: "20px" }}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[200],
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container>
                    <Grid item>
                      <InputLabel>Forecast for : </InputLabel>
                    </Grid>
                    <Grid item>
                      <DatePicker
                        maxDate={dayjs().add(1, "day")}
                        value={dayjs(selectDate)}
                        onChange={handleDateChange}
                        format="YYYY/MM/DD"
                        fontSize="18px"
                        slotProps={{
                          popper: {
                            sx: {
                              ".MuiPaper-root": {
                                //  border: "1px solid blue",
                                borderRadius: "4px !important",
                              },

                              "& .css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected ":
                                {
                                  color: colors.primary.main,
                                  backgroundColor: colors.primary.black,
                                  fontWeight: "500",
                                  borderRadius: "12px",
                                },
                              " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper":
                                {
                                  boxShadow: "none !important",
                                },
                              "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                },
                              " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                },
                              " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  // background: colors.primary.light
                                },
                              ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                  borderRadius: "10px",
                                },
                              ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                {
                                  borderRadius: "12px",
                                },
                              ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px",
                                  backgroundColor: `${colors.grey[200]} !important`,
                                },
                              ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                                {
                                  borderRadius: "12px",
                                  backgroundColor: colors.grey[200],
                                },
                              ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root":
                                {
                                  borderRadius: "12px",
                                },
                              ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                  borderRadius: "10px",
                                },
                            },
                          },
                        }}
                        sx={{
                          borderRadius: "1.563rem",
                          width: "8.75rem", // Adjust as needed
                          border: "none",
                          verticalAlign: "middle",
                          marginLeft: "10px",

                          "& .MuiInputLabel-root": {
                            display: "none", // Hide the default label
                          },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                            {
                              height: "0.938rem !important",
                              padding: "0rem !important",
                            },
                          "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              paddingRight: "0.313rem",
                            },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              padding: "0px !important",
                            },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            style={{
                              height: ".1em",
                              border: "none",
                              width: "100%", // Adjust to fill the available space
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item style={{ marginLeft: "20px" }}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[200],
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container>
                    <Grid item>
                      <InputLabel>Reference Point : </InputLabel>
                    </Grid>
                    <Grid item>
                      <DatePicker
                        maxDate={dayjs().add(1, "day")}
                        value={dayjs(refDate)}
                        onChange={handleRDateChange}
                        format="YYYY/MM/DD"
                        fontSize="18px"
                        slotProps={{
                          popper: {
                            sx: {
                              ".MuiPaper-root": {
                                //  border: "1px solid blue",
                                borderRadius: "4px !important",
                              },
                              "& .css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected ":
                                {
                                  color: colors.primary.main,
                                  backgroundColor: colors.primary.black,
                                  fontWeight: "500",
                                  borderRadius: "12px",
                                },
                              " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper":
                                {
                                  boxShadow: "none !important",
                                },
                              "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                },
                              " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                },
                              " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px !important",
                                  // background: colors.primary.light
                                },
                              ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                  borderRadius: "10px",
                                },
                              ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                {
                                  borderRadius: "12px",
                                },
                              ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  borderRadius: "12px",
                                  backgroundColor: `${colors.grey[200]} !important`,
                                },
                              ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                                {
                                  borderRadius: "12px",
                                  backgroundColor: colors.grey[200],
                                },
                              ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root":
                                {
                                  borderRadius: "12px",
                                },
                              ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                {
                                  backgroundColor: colors.primary.black,
                                  color: colors.primary.main,
                                  borderRadius: "10px",
                                },
                            },
                          },
                        }}
                        sx={{
                          borderRadius: "1.563rem",
                          width: "8.75rem", // Adjust as needed
                          border: "none",
                          verticalAlign: "middle",
                          marginLeft: "10px",

                          "& .MuiInputLabel-root": {
                            display: "none", // Hide the default label
                          },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                            {
                              height: "0.938rem !important",
                              padding: "0rem !important",
                            },
                          "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root":
                            {
                              paddingRight: "0.313rem",
                            },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              padding: "0px !important",
                            },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            style={{
                              height: ".1em",
                              border: "none",
                              width: "100%", // Adjust to fill the available space
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          sx={{
            marginRight: "20px",
          }}
        >
          <Button
            lg={10}
            onClick={handleOpen}
            className="hey"
            variant="contained"
            sx={{
              backgroundColor: colors.primary["black"],
              height: "2.9rem",
              borderRadius: "0.625rem",
              textTransform: "none",
              width: "100%",
              color: colors.primary["light"],
              fontSize: "0.875rem",
              fontFamily: "Poppins, sans-serif",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "100%",
              "& .css-knqc4i-MuiDialogActions-root > :not(style) ~ :not(style) ":
                {
                  backgroundColor: colors.primary["black"],
                },

              "& .css-8w2k8s-MuiButtonBase-root": {
                backgroundColor: colors.primary["black"],
              },
            }}
          >
            Compared Dates
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,

                boxShadow: 24,
                p: 4,
              }}
            >
              <MultipleDatesPicker
                open={open}
                disabledDates={blockDates}
                selectedDates={multipleDates}
                onCancel={() => setOpen(false)}
                onSubmit={handleUpdate}
                slotProps={{
                  popper: {
                    sx: {
                      ".MuiPaper-root": {
                        //  border: "1px solid blue",
                        borderRadius: "4px !important",
                      },
                      " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                        boxShadow: "none !important",
                      },
                      "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                        {
                          borderRadius: "12px !important",
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                        },
                      " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
                        {
                          border: "none !important",
                        },
                      " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          borderRadius: "12px !important",
                        },
                      ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                          borderRadius: "10px !important",
                        },
                      ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
                        {
                          borderRadius: "12px",
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                        },
                      ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          borderRadius: "12px !important",
                          backgroundColor: `${colors.grey[200]} !important`,
                        },
                      ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                        {
                          borderRadius: "12px !important",
                          backgroundColor: colors.grey[200],
                        },
                      ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root": {
                        borderRadius: "12px !important",
                      },
                      ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                          borderRadius: "10px !important",
                        },
                    },
                  },
                }}
              />
            </Box>
          </Modal>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "20px",
          width: "98%",
          // width: "75rem",
          alignItems: "center",
          marginTop: "1.875rem",
          position: "relative",
          top: 0,
          zIndex: 0,
          // height: "5rem",
          paddingTop: "20px",
          paddingBottom: "20px",
          backgroundColor: colors.primary.main,
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
          // justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ marginLeft: "20px", marginBottom: "10px" }}>
          Comparision between dates
        </Typography>
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container>
                <Grid item style={{ marginLeft: "20px" }}>
                  <Box
                    sx={{
                      backgroundColor: colors.grey[200],
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <InputLabel> Date 1 : </InputLabel>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          maxDate={dayjs().subtract(1, "day")}
                          onChange={handleD1DateChange}
                          value={d1Date}
                          format="YYYY/MM/DD"
                          fontSize="18px"
                          slotProps={{
                            popper: {
                              sx: {
                                ".MuiPaper-root": {
                                  //  border: "1px solid blue",
                                  borderRadius: "4px !important",
                                },

                                "& .css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected ":
                                  {
                                    color: colors.primary.main,
                                    backgroundColor: colors.primary.black,
                                    fontWeight: "500",
                                    borderRadius: "12px",
                                  },
                                " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper":
                                  {
                                    boxShadow: "none !important",
                                  },
                                "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                  },
                                " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                  },
                                " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    // background: colors.primary.light
                                  },
                                ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                    borderRadius: "10px",
                                  },
                                ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                  {
                                    borderRadius: "12px",
                                  },
                                ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px",
                                    backgroundColor: `${colors.grey[200]} !important`,
                                  },
                                ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                                  {
                                    borderRadius: "12px",
                                    backgroundColor: colors.grey[200],
                                  },
                                ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root":
                                  {
                                    borderRadius: "12px",
                                  },
                                ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                    borderRadius: "10px",
                                  },
                              },
                            },
                          }}
                          sx={{
                            borderRadius: "1.563rem",
                            width: "9rem", // Adjust as needed
                            border: "none",
                            verticalAlign: "middle",
                            marginLeft: "10px",

                            "& .MuiInputLabel-root": {
                              display: "none", // Hide the default label
                            },
                            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                              {
                                height: "0.938rem !important",
                                padding: "0rem !important",
                              },
                            "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root":
                              {
                                paddingRight: "0.313rem",
                              },
                            "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "0px !important",
                              },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "transparent",
                              },
                              "&:hover fieldset": {
                                borderColor: "transparent",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "transparent",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <input
                              {...params.inputProps}
                              style={{
                                height: ".1em",
                                border: "none",
                                width: "100%", // Adjust to fill the available space
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item style={{ marginLeft: "20px" }}>
                  <Box
                    sx={{
                      backgroundColor: colors.grey[200],
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <InputLabel> Date 2 : </InputLabel>
                      </Grid>
                      <Grid item>
                        <DatePicker
                          maxDate={dayjs().subtract(1, "day")}
                          onChange={handleD2DateChange}
                          value={d2Date}
                          format="YYYY/MM/DD"
                          fontSize="18px"
                          slotProps={{
                            popper: {
                              sx: {
                                ".MuiPaper-root": {
                                  //  border: "1px solid blue",
                                  borderRadius: "4px !important",
                                },
                                "& .css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected ":
                                  {
                                    color: colors.primary.main,
                                    backgroundColor: colors.primary.black,
                                    fontWeight: "500",
                                    borderRadius: "12px",
                                  },
                                " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper":
                                  {
                                    boxShadow: "none !important",
                                  },
                                "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                  },
                                " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                  },
                                " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px !important",
                                    // background: colors.primary.light
                                  },
                                ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                    borderRadius: "10px",
                                  },
                                ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                  {
                                    borderRadius: "12px",
                                  },
                                ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    borderRadius: "12px",
                                    backgroundColor: `${colors.grey[200]} !important`,
                                  },
                                ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                                  {
                                    borderRadius: "12px",
                                    backgroundColor: colors.grey[200],
                                  },
                                ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root":
                                  {
                                    borderRadius: "12px",
                                  },
                                ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                                  {
                                    backgroundColor: colors.primary.black,
                                    color: colors.primary.main,
                                    borderRadius: "10px",
                                  },
                              },
                            },
                          }}
                          sx={{
                            borderRadius: "1.563rem",
                            width: "9rem", // Adjust as needed
                            border: "none",
                            verticalAlign: "middle",
                            marginLeft: "10px",

                            "& .MuiInputLabel-root": {
                              display: "none", // Hide the default label
                            },
                            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                              {
                                height: "0.938rem !important",
                                padding: "0rem !important",
                              },
                            "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root":
                              {
                                paddingRight: "0.313rem",
                              },
                            "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                padding: "0px !important",
                              },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "transparent",
                              },
                              "&:hover fieldset": {
                                borderColor: "transparent",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "transparent",
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <input
                              {...params.inputProps}
                              style={{
                                height: ".1em",
                                border: "none",
                                width: "100%", // Adjust to fill the available space
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Grid container>
              <Grid item>
                <Button
                  onClick={handleDateButtonClick}
                  sx={{
                    display: "flex",
                    color: colors.primary["main"],
                    fontFamily: "Poppins,sans-serif",
                    textTransform: "none",
                    width: "8rem",
                    height: "2.8rem",
                    borderRadius: "16px",
                    background: colors.primary.dark,
                    marginRight: "1.25rem",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.125rem",
                    textAlign: "center",
                    color: colors.primary["main"], // Set the text color for the default state
                    "&:hover": {
                      color: colors.primary["main"], // Set the text color for the hover state
                      background: colors.primary["black"], // You can change the background color on hover if needed
                    },
                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                      webkitTapHighlightColor: colors.primary["black"],
                    },
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StatisticalTable
        predictDate={selectDate}
        dayType={dayType}
        newDates={response}
      />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: colors.primary.main,
          marginTop: "1.875rem",
          display: "flex",
          width: "98%",
          // width: "74.875rem",
          borderRadius: "24px",
          height: "31.25rem",
        }}
      >
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "500",
                fontFamily: "Poppins,sans-serif",
                marginTop: "1.25rem",
                marginLeft: "1.25rem",
              }}
            >
              Load
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={toggleLoadModal}
              sx={{
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <FullscreenIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // width: "73.125rem",
            height: "28.125rem",
            marginX: "15px",
            backgroundColor: "transparent",
          }}
        >
          <StatisticalDates
            selectedDate={[...newDatesArray, selectDate]}
            chartData={chartData}
            dayType={dayType}
            metric="wdLoad"
            margins={{ top: 5, right: 30, bottom: 70, left: 50 }}
            yDomain={[0, 100]}
            legend="Load"
            pointColor=" rgba(0,0,0,0)"
            expandedView
            pointFaceColor="rgba(255,255,255)"
            pointSize={2}
            lineWidth={1}
            pointWidth={1}
            pointBorderColor={{ from: "serieColor" }}
          />
        </Grid>
        <Box>
          <Modal
            open={isLoadModalOpen}
            onClose={toggleLoadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                backdropFilter: "blur(2px)",
              },
            }}
          >
            <Box sx={modalStyle}>
              <StatisticalDates
                selectedDate={[...newDatesArray, selectDate]}
                chartData={chartData}
                // dayType={dayType}
                metric="wdLoad"
                margins={{ top: 5, right: 0, bottom: 70, left: 70 }}
                yDomain={[0, 100]}
                legend="Load"
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
              <IconButton
                onClick={toggleLoadModal}
                sx={{
                  position: "absolute",
                  top: "0.3135rem",
                  right: "0.3135rem",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Modal>
        </Box>
        {/* </Box> */}
        {/* </Typography> */}
      </Grid>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        spacing={3}
        sx={{ marginBottom: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          // sx={{ marginRight: "1.875rem" }}
        >
          <Box
            sx={{
              backgroundColor: colors.primary.main,
              marginTop: "1.875rem",
              display: "flex",
              // width: "36.5rem",
              borderRadius: "24px",
              height: "21.75rem",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    fontFamily: "Poppins,sans-serif",
                    marginTop: "1.25rem",
                    marginLeft: "1.25rem",
                  }}
                >
                  Temperature
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={toggleTempModal}
                  sx={{
                    // position: "absolute",
                    // top: "47.5rem",
                    // right: "40.5rem",
                    // display: 'flex',
                    // float: 'right'
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                height: "16.25rem",
                // width: "34.375rem",
                marginX: "15px",
              }}
            >
              <StatisticalDates
                selectedDate={[...newDatesArray, selectDate]}
                chartData={chartData}
                dayType={dayType}
                metric="temp"
                margins={{ top: 10, right: 20, bottom: 65, left: 50 }}
                yDomain={[0, 100]}
                legend="Temperature"
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
            </Grid>
            <Box>
              <Modal
                open={isTempModalOpen}
                onClose={toggleTempModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <StatisticalDates
                    selectedDate={[...newDatesArray, selectDate]}
                    chartData={chartData}
                    dayType={dayType}
                    metric="temp"
                    margins={{ top: 10, right: 20, bottom: 70, left: 50 }}
                    yDomain={[0, 100]}
                    legend="Temperature"
                    color1={["#F3C699", "#919191", "#2C2C2C", "#E07001"]}
                    pointColor=" rgba(0,0,0,0)"
                    expandedView
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  />
                  <IconButton
                    onClick={toggleTempModal}
                    sx={{
                      position: "absolute",
                      top: "0.3135rem",
                      right: "0.3135rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
            {/* </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              backgroundColor: colors.primary.main,
              marginTop: "1.875rem",
              display: "flex",
              // width: "36.5rem",
              borderRadius: "24px",
              height: "21.75rem",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    fontFamily: "Poppins,sans-serif",
                    marginTop: "1.25rem",
                    marginLeft: "1.25rem",
                  }}
                >
                  Humidity
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={toggleHumidityModal}
                  sx={{
                    // position: "absolute",
                    // top: "47.5rem",
                    // right: "2rem",
                    // display: 'flex',
                    // float: 'right',
                    // width: "30px"
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  <FullscreenIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                height: "16.25rem",
                marginX: "15px",
                // width: "34.375rem",
                // width: "90%"
              }}
            >
              <StatisticalDates
                selectedDate={[...newDatesArray, selectDate]}
                dayType={dayType}
                chartData={chartData}
                metric="rh"
                margins={{ top: 10, right: 20, bottom: 65, left: 50 }}
                yDomain={[0, 100]}
                legend="Humidity"
                pointColor=" rgba(0,0,0,0)"
                expandedView
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                pointBorderColor={{ from: "serieColor" }}
              />
            </Grid>
            <Box>
              <Modal
                open={isHumidityModalOpen}
                onClose={toggleHumidityModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                    backdropFilter: "blur(2px)",
                  },
                }}
              >
                <Box sx={modalStyle}>
                  <StatisticalDates
                    chartData={chartData}
                    selectedDate={[...newDatesArray, selectDate]}
                    dayType={dayType}
                    metric="rh"
                    margins={{ top: 10, right: 20, bottom: 70, left: 50 }}
                    yDomain={[0, 100]}
                    legend="Humidity"
                    pointColor=" rgba(0,0,0,0)"
                    expandedView
                    pointFaceColor="rgba(255,255,255)"
                    pointSize={2}
                    lineWidth={1}
                    pointWidth={1}
                    pointBorderColor={{ from: "serieColor" }}
                  />
                  <IconButton
                    onClick={toggleHumidityModal}
                    sx={{
                      position: "absolute",
                      top: "0.3135rem",
                      right: "0.3135rem",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Modal>
            </Box>
            {/* </Typography> */}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default StatForecast;
