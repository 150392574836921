  // import React, { useEffect, useState } from "react";
  // import ReactDOM from "react-dom/client";

  // import "./index.css";
  // import App from "./App";
  // import { HelmetProvider } from 'react-helmet-async';
  // import { PublicClientApplication } from "@azure/msal-browser";
  // import { msalConfig } from "./AuthProvider";
  // import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";

  // import { Modal, Button, Typography } from "@mui/material";

  
  // import { useTheme } from "@mui/material";
  // import { tokens } from "./theme";
  
  // import NotFoundPage from "./Components/Error";

  // const msalInstance = new PublicClientApplication(msalConfig);
  // msalInstance.initialize();

  // const RootComponent = () => {
  //   const [isSessionExpired, setIsSessionExpired] = useState(false);
  //   const [initialSignInCompleted, setInitialSignInCompleted] = useState(false);
  //   const location = useLocation();
  //   const [open, setOpen] = useState(false);
    
  //   const navigate = useNavigate();
  //   const [offlineTime, setOfflineTime] = useState(0);
  //   const [refreshClicked, setRefreshClicked] = useState(false);

  //   const handleOpen = () => {
  //     setOpen(true);
  //   };
  //   const theme = useTheme();
  //   const colors = tokens(theme.palette.mode);
  //   const [showNotFoundPage, setShowNotFoundPage] = useState(false);

  //   useEffect(() => {
  //     let token23;
   
      
      
  //     const callApi = async () => {
  //       try {
  //         const accounts = msalInstance.getAllAccounts();
  //         if (accounts.length > 0) {
  //           msalInstance.setActiveAccount(accounts[0]);
  //           const silentRequest = {
  //             scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
  //             account: accounts[0],
  //             forceRefresh: true  //to true if force refresh
  //           };
  //           const accessToken = await msalInstance.acquireTokenSilent(silentRequest);
  //           token23 = accounts[0].idToken;
  //           localStorage.setItem("authority2", token23);
            
  //           setInitialSignInCompleted(true);
  //         } else {
            
  //         }
  //       } catch (error) {
          
  //       }
  //     };
      

  //     const handleLocalStorageChange = () => {
  //       const isAuthenticated = localStorage.getItem("authority2");
  //       if (isAuthenticated && !initialSignInCompleted) {
  //         callApi();
  //       }
  //     };

  //     callApi();
  //     window.addEventListener("storage", handleLocalStorageChange);

  //     return () => {
  //       window.removeEventListener("storage", handleLocalStorageChange);
  //     };
  //   }, [initialSignInCompleted]);

  //   useEffect(() => {
  //     const handleLocalStorageChange = () => {
  //       const isAuthenticated = localStorage.getItem("authority2");
  //       if (!isAuthenticated && initialSignInCompleted) {
  //         setIsSessionExpired(true);
  //         setOfflineTime(0);
  //       } else {
  //         setIsSessionExpired(false);
  //       }
  //     };

  //     handleLocalStorageChange();

  //     window.addEventListener("storage", handleLocalStorageChange);

  //     const handleNetworkChange = () => {
  //       if (!navigator.onLine) {
  //         setIsSessionExpired(true);
  //         window.close();
  //       } else {
  //         setOfflineTime(0);
  //         // Log out the user when they come back online
  //         msalInstance.logoutRedirect().then(() => {
  //           localStorage.clear();
  //           window.location.href = "/";
  //         }).catch(err => {
            
  //         });
  //       }
  //     };

  //     window.addEventListener("online", handleNetworkChange);
  //     window.addEventListener("offline", handleNetworkChange);

  //     const interval = setInterval(() => {
  //       setOfflineTime((prevTime) => prevTime + 1);
  //     }, 60000); // 1 minute interval

  //     return () => {
  //       window.removeEventListener("storage", handleLocalStorageChange);
  //       window.removeEventListener("online", handleNetworkChange);
  //       window.removeEventListener("offline", handleNetworkChange);
  //       clearInterval(interval);
  //     };
  //   }, [initialSignInCompleted]);

  //   const handleRefresh = () => {
  //     setIsSessionExpired(false);
  //     setRefreshClicked(true);
  //     setTimeout(() => {
  //       if (!navigator.onLine) {
  //         window.location.href = "/";
  //       } else {
  //         window.location.reload();
  //       }
  //     }, 1000);
  //   };

  //   useEffect(() => {
  //     if (offlineTime >= 5 && offlineTime < 10 && refreshClicked) {
  //       setShowNotFoundPage(true); 
  //     } else if (offlineTime >= 100) {
  //       msalInstance.logoutRedirect().then(() => {
  //         localStorage.clear();
  //         window.location.href = "/";
  //       }).catch(err => {
          
  //       });
  //     }
  //   }, [offlineTime, navigate, refreshClicked]);

  //   return (
  //     <>
  //       <App instance={msalInstance} />

  //       {isSessionExpired && location.pathname !== "/" && (
  //         <Modal open={true}>
  //           <div
  //             style={{
  //               position: "absolute",
  //               top: "50%",
  //               left: "50%",
  //               transform: "translate(-50%, -50%)",
  //               backgroundColor: colors.primary["main"],
  //               padding: "1.25rem",
  //               borderRadius: "5px",
  //               textAlign: "center",
  //             }}
  //           >
  //             <Typography sx={{ fontFamily: "Poppins, sans-serif", color: colors.primary["black"] }}>
  //               Session Timeout
  //             </Typography>
  //             <Typography sx={{ fontFamily: "Poppins, sans-serif", color: colors.primary["black"] }}>
  //               Your session has timed out. Please refresh the page.
  //             </Typography>
  //             <Button onClick={handleRefresh}>Refresh</Button>
  //           </div>
  //         </Modal>
  //       )}
  //       {showNotFoundPage && <NotFoundPage />}
  //     </>
  //   );
  // };

  // const root = ReactDOM.createRoot(document.getElementById("root"));
  // root.render(
  //   <HelmetProvider>
  //     <Router>
  //       <RootComponent />
  //     </Router>
  //   </HelmetProvider>
  // );



  import React, { useEffect, useState, useRef } from "react";
  import ReactDOM from "react-dom/client";
  import "./index.css";
  import App from "./App";
  import { HelmetProvider } from 'react-helmet-async';
  import { PublicClientApplication } from "@azure/msal-browser";
  import { msalConfig } from "./AuthProvider";
  import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
  import { Modal, Button, Typography } from "@mui/material";
  import { useTheme } from "@mui/material";
  import { tokens } from "./theme";
  import dayjs from "dayjs";
  
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.initialize();
  
  const RootComponent = () => {
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [initialSignInCompleted, setInitialSignInCompleted] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [offlineTime, setOfflineTime] = useState(0);
    const [refreshClicked, setRefreshClicked] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const activityTimeoutRef = useRef(null);
    const [lastActivity, setLastActivity] = useState(dayjs());
  
    const resetActivityTimeout = () => {
      setLastActivity(dayjs());
    };
  
    const handleUserActivity = () => {
      resetActivityTimeout();
    };
  
    useEffect(() => {
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      window.addEventListener("click", handleUserActivity);
  
      return () => {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
        window.removeEventListener("click", handleUserActivity);
      };
    }, []);
  
    useEffect(() => {
      const callApi = async () => {
        try {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
            const silentRequest = {
              scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
              account: accounts[0],
              forceRefresh: true
            };
            const accessToken = await msalInstance.acquireTokenSilent(silentRequest);
            localStorage.setItem("authority2", accessToken.idToken);
            setInitialSignInCompleted(true);
          }
        } catch (error) {
          // console.error("Error acquiring token:", error);
        }
      };
  
      const handleLocalStorageChange = () => {
        const isAuthenticated = localStorage.getItem("authority2");
        if (isAuthenticated && !initialSignInCompleted) {
          callApi();
        }
      };
  
      callApi();
      window.addEventListener("storage", handleLocalStorageChange);
  
      return () => {
        window.removeEventListener("storage", handleLocalStorageChange);
      };
    }, [initialSignInCompleted]);
  
    useEffect(() => {
      const handleLocalStorageChange = () => {
        const isAuthenticated = localStorage.getItem("authority2");
        if (!isAuthenticated && initialSignInCompleted) {
          setIsSessionExpired(true);
          setOfflineTime(0);
        } else {
          setIsSessionExpired(false);
        }
      };
  
      handleLocalStorageChange();
      window.addEventListener("storage", handleLocalStorageChange);
  
      const handleNetworkChange = () => {
        if (!navigator.onLine) {
          setIsSessionExpired(true);
        } else {
          setOfflineTime(0);
          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            onRedirectNavigate: () => {
              localStorage.clear();
            }
          }).then(() => {
            window.location.href = "/";
          }).catch(err => {
            // console.error("Error during logout:", err);
          });
        }
      };
  
      window.addEventListener("online", handleNetworkChange);
      window.addEventListener("offline", handleNetworkChange);
  
      const interval = setInterval(() => {
        setOfflineTime((prevTime) => prevTime + 1);
  
        // Force logout after 10 minutes regardless of activity
        if (dayjs().diff(lastActivity, 'minute') >= 30) {
          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            onRedirectNavigate: () => {
              localStorage.clear();
            }
          }).then(() => {
            window.location.href = "/";
          }).catch(err => {
            // console.error("Error during logout:", err);
          });
        }
  
        // Logout if inactive or no navigation for 5 minutes
        if (dayjs().diff(lastActivity, 'minute') >= 45|| offlineTime >= 30) {

          msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
            onRedirectNavigate: () => {
              localStorage.clear();
            }
          }).then(() => {
            window.location.href = "/";
          }).catch(err => {
            // console.error("Error during logout:", err);
          });
        }
      }, 60000);
  
      return () => {
        window.removeEventListener("storage", handleLocalStorageChange);
        window.removeEventListener("online", handleNetworkChange);
        window.removeEventListener("offline", handleNetworkChange);
        clearInterval(interval);
      };
    }, [initialSignInCompleted, lastActivity]);
  
    const handleRefresh = () => {
      setIsSessionExpired(false);
      setRefreshClicked(true);
      setTimeout(() => {
        if (!navigator.onLine) {
          window.location.href = "/";
        } else {
          window.location.reload();
        }
      }, 1000);
    };
  
    return (
      <>
        <App instance={msalInstance} />
        {isSessionExpired && location.pathname !== "/" && (
          <Modal open={true}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: colors.primary.main,
                padding: "1.25rem",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontFamily: "Poppins, sans-serif", color: colors.primary.black }}>
                Session Timeout
              </Typography>
              <Typography sx={{ fontFamily: "Poppins, sans-serif", color: colors.primary.black }}>
                Your session has timed out. Please refresh the page.
              </Typography>
              <Button onClick={handleRefresh}>Refresh</Button>
            </div>
          </Modal>
        )}
      </>
    );
  };
  
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <HelmetProvider>
      <Router>
        <RootComponent />
      </Router>
    </HelmetProvider>
  );
  