import React, {
    useState,
    useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Grid, Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme.js";
import dayjs from "dayjs";
import '../Pages/Forecast/Forecast.css';


const NextTable = ({
    predictedData,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isDarkMode = theme.palette.mode === "dark";
    const suppressRowHoverHighlight = true;
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [dayTotal, setTotal] = useState();
    const [addedDates, setAddedDates] = useState([]);

    function divideLoadValues(data, divisor) {
        const dividedData = {};
        for (const key in data) {
            if (key.includes('Load')) {
                dividedData[key] = parseFloat((data[key] / divisor).toFixed(2))
            } else {
                dividedData[key] = "Average";
            }
        }
        return dividedData;
    }

    const downloadExcelFile = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Sheet1");
        const headers = ["Time Stamp",]
        if (addedDates.length !== 0) {
            addedDates.forEach(date => headers.push(
                `${date} Load`,));
        }
        sheet.addRow(headers);
        rowData.forEach((row) => {
            const defaultRow = [
                row.timeStamp,
            ];
            if (addedDates.length !== 0) {
                addedDates.forEach(date => {
                    const load = `${date} Load`;
                    defaultRow.push(parseFloat(row[load]))
                })
            }
            sheet.addRow(defaultRow);
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: "application/octet-stream" });
            saveAs(blob, `Prediction for ${addedDates[0]}.xlsx`);
        });
    };

    useEffect(() => {
        try {
            if (predictedData) {
                setTotal({ timeStamp: "Total" });
                setAddedDates([]);
                setColumnDefs([{
                    headerName: "Time Stamp",
                    headerClass: "nextTableHeader",
                    field: "timeStamp",
                    cellClass: "nextTableCol",
                    width: 120,
                },])
                setRowData(timerow);
                predictedData.forEach(item => {
                    const loadDate = (dayjs(item.date)).format("YYYY/MM/DD");
                    setAddedDates(prevDates => {
                        const uniqueDates = Array.from(new Set([...prevDates, loadDate]));
                        return uniqueDates;
                    });
                    setTotal(prevDayTotal => ({
                        ...prevDayTotal,
                        [`${loadDate} Load`]: 0
                    }));
                    setColumnDefs(prevColumnDefs => [
                        ...prevColumnDefs,
                        {
                            headerName: `${loadDate}`,
                            headerClass: "nextTableHeader",
                            field: `${loadDate} Load`,
                            cellClass: "nextTableCol",
                            width: 140,
                        },
                    ]);
                    const newLoadDateData = item.blocks.map((block, index) => ({
                        timeStamp: timestamps[index]?.label,
                        load: block.wdLoad,
                    }));
                    setRowData(prevData => {
                        return prevData.map(existingRow => {
                            const newRow = newLoadDateData.find(newRow => newRow.timeStamp === existingRow.timeStamp);
                            if (newRow) {
                                setTotal(prevDayTotal => ({
                                    ...prevDayTotal,
                                    [`${loadDate} Load`]: parseFloat((prevDayTotal[`${loadDate} Load`]).toFixed(2)) + parseFloat((newRow.load).toFixed(2)),
                                }));
                                return {
                                    ...existingRow,
                                    [`${loadDate} Load`]: newRow.load,
                                };
                            } else {
                                return existingRow;
                            }
                        });
                    });
                })
            }
        } catch (error) {
            
        }
    }, [predictedData]);

    useEffect(() => {
        try {
            const loadEntriesCount = Object.keys(dayTotal).filter(key => key.includes("Load")).length;
            if (loadEntriesCount === 14) {
                const dividedData = divideLoadValues(dayTotal, 96);
                setRowData(prevData => [...prevData, dividedData]);
                setRowData(prevData => [...prevData, dayTotal]);
            }
        } catch (error) {
            
        }
    }, [dayTotal]);

    return (
        <div>
        <Grid container xs={12} sm={12} md={12} lg={12}
            className={isDarkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
            style={{
                width: "98%",
                height: "43.875rem",
                // width: "74.875rem",
                marginTop: "1.875rem",
                backgroundColor: colors.primary.main,
                padding: "1.25rem",
                borderRadius: "24px",
                marginBottom: "1.25rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,

            }}
        >
            <Grid container sx={{ display: "flex", justifyContent: 'space-between' }}>
                <Grid item sx={{ display: 'flex' }}>
                    <Typography
                        sx={{
                            fontFamily: "Poppins,sans-serif",
                            fontSize: "1.5rem",
                            fontWeight: "400",
                            display: "flex",
                            ml: '1.6rem',
                            color: colors.primary.black
                        }}
                    >
                        Load Prediction Table for 14 Days
                    </Typography>
                </Grid>
                <Grid item sx={{ display: "flex" }}>
                    <Grid container>
                        <Grid item>
                            <Button
                                sx={{
                                    color: colors.primary.dark,
                                    fontFamily: "Poppins,sans-serif",
                                    textTransform: "none",
                                    width: "2.5rem",
                                    height: "2.5rem",
                                    minWidth: "0.625rem",
                                    borderRadius: "12px",
                                    background: colors.grey[200],
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    lineHeight: "1.125rem",
                                    textAlign: "center",
                                    "&:hover": {
                                        color: colors.primary.dark,
                                        background: colors.grey[200],
                                    },
                                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                                        webkitTapHighlightColor: colors.primary["black"],
                                    },
                                }}
                                onClick={downloadExcelFile}
                            >
                                <DownloadIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}
                sx={{
                    height: "37.688rem",
                    // width: "68.75rem",
                    marginLeft: "1.563rem",
                    marginTop: "1.25rem"
                }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={{ sortable: true, resizable: true }}
                    pagination={true}
                    paginationPageSize={12}
                    suppressRowHoverHighlight={suppressRowHoverHighlight}
                />
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "right",
                    backgroundColor: "white",
                }}
            >
            </Box>
        </Grid>
    </div>
    );
};

export default NextTable;

const timestamps = [
    { label: "00:00", value: 0 },
    { label: "00:15", value: 1 },
    { label: "00:30", value: 2 },
    { label: "00:45", value: 3 },
    { label: "01:00", value: 4 },
    { label: "01:15", value: 5 },
    { label: "01:30", value: 6 },
    { label: "01:45", value: 7 },
    { label: "02:00", value: 8 },
    { label: "02:15", value: 9 },
    { label: "02:30", value: 10 },
    { label: "02:45", value: 11 },
    { label: "03:00", value: 12 },
    { label: "03:15", value: 13 },
    { label: "03:30", value: 14 },
    { label: "03:45", value: 15 },
    { label: "04:00", value: 16 },
    { label: "04:15", value: 17 },
    { label: "04:30", value: 18 },
    { label: "04:45", value: 19 },
    { label: "05:00", value: 20 },
    { label: "05:15", value: 21 },
    { label: "05:30", value: 22 },
    { label: "05:45", value: 23 },
    { label: "06:00", value: 24 },
    { label: "06:15", value: 25 },
    { label: "06:30", value: 26 },
    { label: "06:45", value: 27 },
    { label: "07:00", value: 28 },
    { label: "07:15", value: 29 },
    { label: "07:30", value: 30 },
    { label: "07:45", value: 31 },
    { label: "08:00", value: 32 },
    { label: "08:15", value: 33 },
    { label: "08:30", value: 34 },
    { label: "08:45", value: 35 },
    { label: "09:00", value: 36 },
    { label: "09:15", value: 37 },
    { label: "09:30", value: 38 },
    { label: "09:45", value: 39 },
    { label: "10:00", value: 40 },
    { label: "10:15", value: 41 },
    { label: "10:30", value: 42 },
    { label: "10:45", value: 43 },
    { label: "11:00", value: 44 },
    { label: "11:15", value: 45 },
    { label: "11:30", value: 46 },
    { label: "11:45", value: 47 },
    { label: "12:00", value: 48 },
    { label: "12:15", value: 49 },
    { label: "12:30", value: 50 },
    { label: "12:45", value: 51 },
    { label: "13:00", value: 52 },
    { label: "13:15", value: 53 },
    { label: "13:30", value: 54 },
    { label: "13:45", value: 55 },
    { label: "14:00", value: 56 },
    { label: "14:15", value: 57 },
    { label: "14:30", value: 58 },
    { label: "14:45", value: 59 },
    { label: "15:00", value: 60 },
    { label: "15:15", value: 61 },
    { label: "15:30", value: 62 },
    { label: "15:45", value: 63 },
    { label: "16:00", value: 64 },
    { label: "16:15", value: 65 },
    { label: "16:30", value: 66 },
    { label: "16:45", value: 67 },
    { label: "17:00", value: 68 },
    { label: "17:15", value: 69 },
    { label: "17:30", value: 70 },
    { label: "17:45", value: 71 },
    { label: "18:00", value: 72 },
    { label: "18:15", value: 73 },
    { label: "18:30", value: 74 },
    { label: "18:45", value: 75 },
    { label: "19:00", value: 76 },
    { label: "19:15", value: 77 },
    { label: "19:30", value: 78 },
    { label: "19:45", value: 79 },
    { label: "20:00", value: 80 },
    { label: "20:15", value: 81 },
    { label: "20:30", value: 82 },
    { label: "20:45", value: 83 },
    { label: "21:00", value: 84 },
    { label: "21:15", value: 85 },
    { label: "21:30", value: 86 },
    { label: "21:45", value: 87 },
    { label: "22:00", value: 88 },
    { label: "22:15", value: 89 },
    { label: "22:30", value: 90 },
    { label: "22:45", value: 91 },
    { label: "23:00", value: 92 },
    { label: "23:15", value: 93 },
    { label: "23:30", value: 94 },
    { label: "23:45", value: 95 },
];

const timerow = [
    { timeStamp: "00:00" },
    { timeStamp: "00:15" },
    { timeStamp: "00:30" },
    { timeStamp: "00:45" },
    { timeStamp: "01:00" },
    { timeStamp: "01:15" },
    { timeStamp: "01:30" },
    { timeStamp: "01:45" },
    { timeStamp: "02:00" },
    { timeStamp: "02:15" },
    { timeStamp: "02:30" },
    { timeStamp: "02:45" },
    { timeStamp: "03:00" },
    { timeStamp: "03:15" },
    { timeStamp: "03:30" },
    { timeStamp: "03:45" },
    { timeStamp: "04:00" },
    { timeStamp: "04:15" },
    { timeStamp: "04:30" },
    { timeStamp: "04:45" },
    { timeStamp: "05:00" },
    { timeStamp: "05:15" },
    { timeStamp: "05:30" },
    { timeStamp: "05:45" },
    { timeStamp: "06:00" },
    { timeStamp: "06:15" },
    { timeStamp: "06:30" },
    { timeStamp: "06:45" },
    { timeStamp: "07:00" },
    { timeStamp: "07:15" },
    { timeStamp: "07:30" },
    { timeStamp: "07:45" },
    { timeStamp: "08:00" },
    { timeStamp: "08:15" },
    { timeStamp: "08:30" },
    { timeStamp: "08:45" },
    { timeStamp: "09:00" },
    { timeStamp: "09:15" },
    { timeStamp: "09:30" },
    { timeStamp: "09:45" },
    { timeStamp: "10:00" },
    { timeStamp: "10:15" },
    { timeStamp: "10:30" },
    { timeStamp: "10:45" },
    { timeStamp: "11:00" },
    { timeStamp: "11:15" },
    { timeStamp: "11:30" },
    { timeStamp: "11:45" },
    { timeStamp: "12:00" },
    { timeStamp: "12:15" },
    { timeStamp: "12:30" },
    { timeStamp: "12:45" },
    { timeStamp: "13:00" },
    { timeStamp: "13:15" },
    { timeStamp: "13:30" },
    { timeStamp: "13:45" },
    { timeStamp: "14:00" },
    { timeStamp: "14:15" },
    { timeStamp: "14:30" },
    { timeStamp: "14:45" },
    { timeStamp: "15:00" },
    { timeStamp: "15:15" },
    { timeStamp: "15:30" },
    { timeStamp: "15:45" },
    { timeStamp: "16:00" },
    { timeStamp: "16:15" },
    { timeStamp: "16:30" },
    { timeStamp: "16:45" },
    { timeStamp: "17:00" },
    { timeStamp: "17:15" },
    { timeStamp: "17:30" },
    { timeStamp: "17:45" },
    { timeStamp: "18:00" },
    { timeStamp: "18:15" },
    { timeStamp: "18:30" },
    { timeStamp: "18:45" },
    { timeStamp: "19:00" },
    { timeStamp: "19:15" },
    { timeStamp: "19:30" },
    { timeStamp: "19:45" },
    { timeStamp: "20:00" },
    { timeStamp: "20:15" },
    { timeStamp: "20:30" },
    { timeStamp: "20:45" },
    { timeStamp: "21:00" },
    { timeStamp: "21:15" },
    { timeStamp: "21:30" },
    { timeStamp: "21:45" },
    { timeStamp: "22:00" },
    { timeStamp: "22:15" },
    { timeStamp: "22:30" },
    { timeStamp: "22:45" },
    { timeStamp: "23:00" },
    { timeStamp: "23:15" },
    { timeStamp: "23:30" },
    { timeStamp: "23:45" },
];