// import React, { useState, useEffect } from 'react';
// import {  LogLevel  } from '@azure/msal-browser';
// import AuthContext from './AuthContext';
// import { useNavigate } from 'react-router-dom';


// export const msalConfig = {
//   auth : {
//     // clientId: '3e1abe11-28f1-42b1-acf8-83f73baa2ce6',
//   clientId: '3e1abe11-28f1-42b1-acf8-83f73baa2ce6',
//   redirectUrl: 'http://localhost:3000/dash2/',

//   authority: 'https://login.microsoftonline.com/common/',


//     },
//     cache: {
//       cacheLocation: "sessionStorage",
//       storeAuthStateInCookie: false,
//     },
//     system: {
//       loggerOptions: {
//           loggerCallback: (level, message, containsPii) => {
//               if (containsPii) {
//                   return;
//               }
//               switch (level) {
//                   case LogLevel.Error:
//                       console.error(message);
//                       return;
//                   case LogLevel.Info:
//                       console.info(message);
//                       return;
//                   case LogLevel.Verbose:
//                       console.debug(message);
//                       return;
//                   case LogLevel.Warning:
//                       console.warn(message);
//                       return;
//                   default:
//                       return;
//               }
//           },
//       },
//       allowNativeBroker: false,
//   },
// };




// // export const msalInstance = new PublicClientApplication(msalConfig);

// export const loginRequest = {
//     scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
//   };
//   // const AuthProvider = ({ children }) => {
//   //   const navigate = useNavigate();
//   //   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   //   const [user, setUser] = useState(null);
//   //   const [error, setError] = useState(null);
//   //   const [publicClientApplication, setPublicClientApplication] = useState(null);
  
//   //   useEffect(() => {
//   //     const pca = new PublicClientApplication(msalConfig);
//   //     setPublicClientApplication(pca);
  
//   //     // Check if a user is already signed in
//   //     const account = pca.getAllAccounts()[0];
//   //     console.log(pca);
//   //     if (account) {
//   //       setUser(account);
//   //       setIsAuthenticated(true);
//   //     }
//   //     else {
//   //       setIsAuthenticated(false); // Explicitly set isAuthenticated to false when no accounts are found
//   //     }
//   //   }, []);



//   //   // useEffect(() => {
//   //   //   const handleRedirect = async () => {
//   //   //     // Check if the user is authenticated
//   //   //     if (isAuthenticated) {
//   //   //       // Redirect to /dash2 if the user is authenticated
//   //   //       console.log("hello")
//   //   //       navigate('/dash2');
//   //   //     } else {
//   //   //       // Redirect to /signin if the user is not authenticated
//   //   //       navigate('/signin');
//   //   //     }
//   //   //   };
    
//   //   //   handleRedirect();
//   //   // }, [isAuthenticated, navigate]);
     
  
//   //   const login = async () => {
//   //     try {
//   //       await publicClientApplication.loginPopup();
//   //       const account = publicClientApplication.getAllAccounts()[0];
//   //       setUser(account);
//   //       setIsAuthenticated(true);
//   //     } catch (error) {
//   //       setError(error.message);
//   //     }
//   //   };
  
//   //   const logout = async () => {
//   //     await publicClientApplication.logout();
//   //     setIsAuthenticated(false);
//   //     setUser(null);
//   //   };
  
//   //   const authContext = {
//   //     isAuthenticated,
//   //     user,
//   //     error,
//   //     login,
//   //     logout,
//   //     msalInstance: publicClientApplication, // Pass msalInstance to the context
//   //   };

//   //   console.log(authContext);
  
//   //   return (
//   //     <AuthContext.Provider value={authContext}>
//   //       {children}
//   //     </AuthContext.Provider>
//   //   );
//   // };
  
//   // export default AuthProvider;





// authConfig.js

import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 */
export const msalConfig = {
    auth: {
        clientId: "3e1abe11-28f1-42b1-acf8-83f73baa2ce6",
        authority: "https://login.microsoftonline.com/common/",
        redirectUri: 'https://tatapower.somaiyatech.in/dash2',
        // redirectUri: 'http://localhost:3000/dash2',
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        // refreshTokenMaxAge: 2,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        allowNativeBroker: false,
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */

export const loginRequest = {
    scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
    
};
// var silentRequest = {
//     scopes: ["api://3e1abe11-28f1-42b1-acf8-83f73baa2ce6/user_impersonation"],
//     account: currentAccount,
//     forceRefresh: false,
//     cacheLookupPolicy: cacheLookupPolicy.Default // will default to CacheLookupPolicy.Default if omitted
// };

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
// export const silentRequest = {
//     scopes: ["openid", "profile"],
//     loginHint: "example@domain.net",
// };








// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
