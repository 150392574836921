import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import { Audio } from 'react-loader-spinner';
import { ErrorOutline } from '@mui/icons-material';  // Import the icon you want to display

const Spinner = ({ marginLeft, marginTop }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: marginTop,
        marginLeft: marginLeft,
      }}
    >
      {loading ? (
        <Audio
          height="45"
          width="45"
          color={colors.primary.dark}
          animationDuration="0.3"
          ariaLabel="audio-loading"
          wrapperStyle={{}}
          wrapperClass="wrapper-class"
          visible={true}
        />
      ) : (
        <Box sx={{ textAlign: 'center', color: colors.primary.dark }}>
          <ErrorOutline sx={{ fontSize: 45, marginBottom: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            No Data Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Spinner;
