import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  CircularProgress,
  Backdrop,
  Icon,
  Switch,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DropzoneArea } from "react-mui-dropzone";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import dayjs from "dayjs";
import UploadCard from "./Components/UploadCard";
import WeatherCalendar from "../../Components/weatherCalendar";
import Warning from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExcelJS from "exceljs";
import axios from "axios";
import TempRHCh from "./Components/TempRHCh";
import instance from "../../middleware";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => "." + x)
  .join(",");

function UploadData() {
  const theme = useTheme();
  const [dayType, setDayType] = useState("2018");
  const [files, setFiles] = useState({});
  const [file1, setFile1] = useState({});
  const [file, selectedFile] = useState({});
  const colors = tokens(theme.palette.mode);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedData, setSelectedData] = useState("Data 1");
  const [selectedDate, setSelectedDate] = useState(dayjs().subtract(2, "day"));
  const [newdate, setNewDate] = useState();
  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [open, setOpen] = useState(false);
  const maxDate = dayjs().subtract(1, "day");
  const minDate = dayjs("2020-07-01");
  const [countData, setCountData] = useState([]);
  const [filterActive, setFilterActive] = useState(true);
  const [datee, setDatee] = useState(null);
  const [date, setDate] = useState(new Date());
  const [icon, setIcon] = useState(
    <Warning color="error" style={{ verticalAlign: "middle" }} />
  );
  const [clearFiles, setClearFiles] = useState(false);

  const [modhead, setModHead] = useState("File Not Supported");
  const [modbody, setModBody] = useState(
    "File Type not supported! Please refer to the sample sheet provided in instructions."
  );
  const [loader, setLoader] = useState(false);
  const [selectdate, setSelectDate] = useState(null);
  const [selectdatecheck, setSelectDateCheck] = useState(true);
  const [but, setBut] = useState(true);
  const [filePreview, setFilePreview] = useState(false);

  useEffect(() => {
    setNewDate(datee);
  }, [datee]);

  const handleLoaderClose = () => {
    setLoader(false);
  };

  const handleLoaderOpen = () => {
    setLoader(true);
  };

  const handleDateChange = (date) => {
    setDate(date);
    setSelectDate(datee);
    setSelectDateCheck(false);
  };

  const handleDelete = () => {
    setFiles({});
    setFilePreview(false);
    localStorage.removeItem("selectedFile1");
  };

  const handlePreview = () => {
    if (!selectdate) {
      setIcon(
        <ErrorIcon
          color="error"
          size="large"
          style={{ verticalAlign: "middle" }}
        />
      );
      setModHead("Error");
      setModBody("Please select a date before previewing the file.");
      setOpen(true);
      return;
    }

    setFilePreview(true);
  };

  const handleDataChange1 = (event) => {
    setSelectedData(event.target.value);
    const filteredData = countData.filter((item) => item.value === 0);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      filteredData.map((item) => `${item.day},${item.value}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${selectedData}_count_data.csv`);
    document.body.appendChild(link);

    link.click();
  };

  const handleDayTypeChange = (event) => {
    setDayType(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleChange = (files) => {
    setFiles(files, () => {
      if (files.length === 0 && selectdate === null) {
        setBut(true);
      } else if (files.length > 0) {
        setBut(false);
      }
    });
    setClearFiles(false);
  };

  const handleCombineChange = (files) => {
    handleChange(files);
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    const selectedFile1 = files[0];
    setFile1(selectedFile1);

    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
      const sheet = workbook.getWorksheet("Sheet1");
      const firstRow = sheet.getRow(1).values;

      // Check if both 'temp' and 'rh' are present in the first row
      if (!firstRow.includes("temp") || !firstRow.includes("rh")) {
        setFiles({}); // Clear the files in dropzone
        setIcon(
          <ErrorIcon
            color="error"
            size="large"
            style={{ verticalAlign: "middle" }}
          />
        );
        setModHead("File Not Supported");
        setModBody("The file does not contain the required columns 'temp' and 'rh'.");
        setOpen(true);
        setFiles({});
        localStorage.removeItem("selectedFile1"); // Remove the stored file
        return;
      }

      const jsonData1 = [];
      sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        jsonData1.push(row.values.slice(1));
      });

      localStorage.setItem("selectedFile1", JSON.stringify(jsonData1));
      // console.log("jsonData1", jsonData1);
    };

    if (selectedFile1 instanceof Blob) {
      reader.readAsArrayBuffer(selectedFile1);
    } else {
      // console.error("Selected file is not a valid Blob object");
    }
  };

  const handleFile = () => {
    if (!selectdate) {
      setIcon(
        <ErrorIcon
          color="error"
          size="large"
          style={{ verticalAlign: "middle" }}
        />
      );
      setModHead("Error");
      setModBody("Please select a date before uploading the file.");
      setOpen(true);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
      const sheet = workbook.getWorksheet("Sheet1");

      const newData = [];
      sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        newData.push(row.values.slice(1));
      });

      setData(newData);

      handleLoaderOpen();
      const update = dayjs(date).format("YYYY-MM-DD");
      const formData = new FormData();
      formData.append("DATE", update);
      formData.append("FILE", files[0]);

      instance
        .post("/uploadWeatherData", formData)
        .then((response) => {
          handleLoaderClose();
          if (response.data.success) {
            setIcon(
              <CheckCircleIcon
                size="large"
                style={{
                  verticalAlign: "middle",
                  color: "green",
                }}
              />
            );
            setModHead("Upload Successfull");
            const successMessage = "Data uploaded successfully";
            setModBody(successMessage);

            // Store the success message in localStorage
            localStorage.setItem("uploadStatus", successMessage);
            window.dispatchEvent(new Event("successMessage"));

            setOpen(true);

            // setTimeout(() => {
            //   window.location.reload();
            // }, 3000);
          } else {
            setIcon(
              <ErrorIcon
                color="error"
                size="large"
                style={{ verticalAlign: "middle" }}
              />
            );
            setModHead("Error Uploading");
            setModBody("Internal Server Error!");
            setOpen(true);
          }
        })
        .catch((error) => {
          handleLoaderClose();
          setIcon(
            <ErrorIcon
              color="error"
              size="large"
              style={{ verticalAlign: "middle" }}
            />
          );
          setModHead("Error Uploading");
          setModBody("Server error | Contact");
          setOpen(true);
        });
    };

    if (files[0] instanceof Blob) {
      reader.readAsArrayBuffer(files[0]);
    } else {
      // console.error("Selected file is not a valid Blob object");
    }
  };

  const handleClose = () => {
    setOpen(false);

    if (modhead === "File Not Supported" && modbody.includes("The file does not contain the required columns 'temp' and 'rh'.")) {
      setClearFiles(true);
    }
  };

  const getYearMenuItems = () => {
    const currentYear = dayjs().year();
    const startYear = 2018;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  // const handlefixDate = () => {
  //   setNewDate(datee);
  // };
  return (
//     <div
//       style={{
//         // backgroundColor: "#ff0000",
//         width: "100%",
//       }}
//     >
//       <Grid
//         container
//         sx={{
//           marginTop: "5.25rem",
//           width: "98%",
//           // backgroundColor: "#00ff00"
//         }}
//       >
//         <Grid
//           item
//           sx={{
//             mt: "1.875rem",
//             // backgroundColor: "#0000ff",
//             width: "48.4%",
//           }}
//         >
//           <Box
//             raised="true"
//             sx={{
//               alignContent: "center",
//               height: "97.5%",
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               alignSelf: "stretch",
//               borderRadius: "24px",
//               background: colors.primary["main"],
//               boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
//               marginBottom: "1.25rem",
//             }}
//           >
//             <Typography
//               variant="h4"
//               sx={{
//                 // fontWeight: 'bold',
//                 // fontSize: 24,
//                 // ml: 5,
//                 // mt: 2
//                 alignSelf: "stretch",
//                 color: colors.primary.dark,
//                 fontSize: "1.7rem",
//                 fontWeight: "600",
//                 lineHeight: "2.166rem",
//                 mt: "1.25rem",
//                 ml: "1.25rem",
//                 fontFamily: "Poppins,sans-serif",
//               }}
//             >
//               Upload Weather Data
//             </Typography>
//             <Box
//               sx={{
//                 height: "7.313rem",
//                 width: "93.6%",
//                 // backgroundColor: "#fff000"
//               }}
//             >
//               <Grid
//                 container
//                 sx={{
//                   ml: "0rem",
//                   justifyContent: "space-between",
//                   // backgroundColor: "#f000ff",
//                   width: "100%",
//                 }}
//               >
//                 <Grid
//                   item
//                   sx={{
//                     marginLeft: "0rem",
//                   }}
//                 >
//                   {filterActive ? (
//                     <Typography
//                       sx={{
//                         // marginLeft: "40px",
//                         // marginTop: "20px",
//                         // fontWeight: 500,
//                         color: colors.grey[850],
//                         leadingTrim: "both",
//                         textEdge: "cap",
//                         fontFamily: "Poppins,sans-serif",
//                         fontSize: "1rem",
//                         fontStyle: "normal",
//                         fontWeight: "400",
//                         lineHeight: "normal",
//                         marginTop: "1.25rem",
//                         marginLeft: "0.938rem",
//                       }}
//                     >
//                       Available Weather Data
//                     </Typography>
//                   ) : (
//                     <Typography
//                       sx={{
//                         // marginLeft: "40px",
//                         // marginTop: "20px",
//                         // fontWeight: 500,
//                         color: colors.grey[850],
//                         leadingTrim: "both",
//                         textEdge: "cap",
//                         fontFamily: "Poppins,sans-serif",
//                         fontSize: "1rem",
//                         fontStyle: "normal",
//                         fontWeight: "400",
//                         lineHeight: "normal",
//                         marginTop: "1.25rem",
//                         marginLeft: "0.938rem",
//                       }}
//                     >
//                       UnAvailable Weather Data
//                     </Typography>
//                   )}
//                 </Grid>
//                 <Grid item>
//                   <Box
//                     sx={{
//                       borderRadius: "0.625rem",
//                       marginLeft: "0.5rem",
//                       marginTop: "1.25rem",
//                       display: "flex",
//                       height: "57%",
//                       width: "94%",
//                       backgroundColor: colors.grey[300],
//                       // display: "flex",
//                       // display: "flex",
//                       // padding: "8px 8px 8px 16px",
//                       // alignItems: "center",
//                       // gap: "10px",
//                       // borderRadius: "8px",
//                       // background: "#F4F4F4",
//                     }}
//                   >
//                     <InputLabel
//                       sx={{
//                         color: "black",
//                         marginTop: "0.2rem",
//                         paddingRight: "0rem",
//                         marginLeft: "0.313rem",
//                         // color: "#444",
//                         // leadingTrim: "both",
//                         // textEdge: "cap",
//                         // fontFamily: "Gilroy-SemiBold",
//                         fontSize: "0.75rem",
//                         fontStyle: "normal",
//                         fontWeight: "400",
//                         lineHeight: "normal",
//                         color: colors.primary["dark"],
//                       }}
//                     >
//                       Year:
//                     </InputLabel>
//                     <FormControl
//                       sx={{
//                         justifySelf: "end",
//                         "& .MuiOutlinedInput-root": {
//                           "& fieldset": {
//                             borderColor: "transparent",
//                           },
//                           "&:hover fieldset": {
//                             borderColor: "transparent",
//                           },
//                           "&.Mui-focused fieldset": {
//                             borderColor: "transparent",
//                           },
//                         },
//                       }}
//                     >
//                       <Select
//                         value={selectedYear}
//                         onChange={handleYearChange}
//                         sx={{
//                           marginTop: "0.125rem",
//                           marginBottom: "0.313rem",
//                           height: "1.25rem",
//                           width: "4.688rem",
//                           fontSize: "0.625rem",
//                           color: colors.primary["dark"],
//                           lineHeight: "none",
//                           // borderColor: '#d1d1d1',
//                           justifySelf: "end",
//                           marginLeft: "0.313rem",
//                           "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
//                             {
//                               textOverflow: "inherit",
//                             },
//                         }}
//                       >
//                         {getYearMenuItems().map((year) => (
//                           <MenuItem key={year} value={year}>
//                             {year}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Box>
//                 </Grid>
//               </Grid>
//               <WeatherCalendar
//                 selectedYear={selectedYear}
//                 filterActive={filterActive}
//                 setCountData={setCountData}
//                 setDatee={setDatee}
//               />
//             </Box>
//             <Grid
//               container
//               sx={{
//                 // backgroundColor: "#051364",
//                 width: "100%",
//               }}
//             >
//               <Grid
//                 item
//                 sx={{
//                   // backgroundColor: "#fbef",
//                   width: "55.8%",
//                 }}
//               >
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <Box
//                     sx={{
//                       width: "88.8%",
//                       backgroundColor: colors.grey[200],
//                       borderRadius: 2,
//                       marginX: 1,
//                       marginLeft: "1.8rem",
//                       marginTop: "3.75rem",
//                       height: "3rem",
//                     }}
//                   >
//                     <FormControl
//                       fullWidth
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         border: "none",
//                       }}
//                     >
//                       <InputLabel
//                         sx={{
//                           // mb: "150px",
//                           marginTop: "0rem",
//                           paddingTop: "0rem",
//                           marginBottom: "1.25rem",
//                           paddingBottom: "1.25rem",
//                           color: colors.grey[850],
//                           fontSize: "1.2rem",
//                           lineHeight: ".9rem",
//                         }}
//                       >
//                         Date:
//                       </InputLabel>
//                       <DatePicker
//                         format="YYYY/MM/DD"
//                         onChange={handleDateChange}
//                         autoOk={true}
//                         value={selectdate ? date : null}
//                         minDate={dayjs("2007-03-10")}
//                         maxDate={datee ? dayjs(datee) : null}
//                         slotProps={{
//                           popper: {
//                             sx: {
//                               ".MuiPaper-root": {
//                                 //  border: "1px solid blue",
//                                 borderRadius: "4px !important",
//                               },
//                               " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper":
//                                 {
//                                   boxShadow: "none !important",
//                                 },
//                               "button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected":
//                                 {
//                                   borderRadius: "12px !important",
//                                   backgroundColor: colors.primary.black,
//                                   color: colors.primary.main,
//                                 },
//                               // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
//                               //   borderRadius: '12px !important',
//                               //   backgroundColor: colors.primary.black,
//                               //   color: colors.primary.main

//                               // },
//                               " .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
//                                 {
//                                   border: "none !important",
//                                 },
//                               " .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
//                                 {
//                                   borderRadius: "12px !important",
//                                   // background: colors.primary.light
//                                 },
//                               ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
//                                 {
//                                   backgroundColor: colors.primary.black,
//                                   color: colors.primary.main,
//                                   borderRadius: "10px !important",
//                                 },
//                               ".css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected":
//                                 {
//                                   borderRadius: "12px",
//                                   backgroundColor: colors.primary.black,
//                                   color: colors.primary.main,
//                                 },
//                               ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
//                                 {
//                                   borderRadius: "12px !important",
//                                   backgroundColor: `${colors.grey[200]} !important`,
//                                 },
//                               ".css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
//                                 {
//                                   borderRadius: "12px !important",
//                                   backgroundColor: colors.grey[200],
//                                 },
//                               ".css-13urpcw-MuiButtonBase-root-MuiPickersDay-root":
//                                 {
//                                   borderRadius: "12px !important",
//                                 },
//                               ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
//                                 {
//                                   backgroundColor: colors.primary.black,
//                                   color: colors.primary.main,
//                                   borderRadius: "10px !important",
//                                 },
//                             },
//                           },
//                         }}
//                         sx={{
//                           borderRadius: "25px",
//                           width: "11.25rem", // Adjust as needed
//                           border: "none",
//                           fontSize: "0.625rem",
//                           lineHeight: "0.9rem !important",

//                           marginLeft: "auto", // Push to the right
//                           "& .MuiInputLabel-root": {
//                             display: "none", // Hide the default label
//                           },
//                           "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-putrnr,.css-1nnh0tb .css-1uvydh2,":
//                             {
//                               height: "1.5rem !important",
//                             },
//                           "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root, .css-1n6yb8w-MuiInputBase-root-MuiOutlinedInput-root,.css-5cw3jg,.css-1r7a53k":
//                             {
//                               height: "3rem",
//                             },
//                           "& .MuiOutlinedInput-root": {
//                             "& fieldset": {
//                               borderColor: "transparent",
//                             },
//                             "&:hover fieldset": {
//                               borderColor: "transparent",
//                             },
//                             "&.Mui-focused fieldset": {
//                               borderColor: "transparent",
//                             },
//                           },
//                         }}
//                         renderInput={(params) => (
//                           <input
//                             {...params.inputProps}
//                             style={{
//                               height: ".1em",
//                               border: "none",
//                               width: "10%",
//                             }}
//                           />
//                         )}
//                       />
//                     </FormControl>
//                   </Box>
//                 </LocalizationProvider>
//               </Grid>
//               <Grid
//                 item
//                 sx={{
//                   // backgroundColor: "#ab5de4",
//                   width: "28.5%",
//                 }}
//               >
//                 <Button
//                   onClick={handleDataChange1}
//                   sx={{
//                     marginLeft: "0.625rem",
//                     marginTop: "3.75rem",
//                     textTransform: "none",
//                     height: "3rem",
//                     width: "94%",
//                     borderRadius: "0.438rem",
//                     backgroundColor: "#f4f4f4",
//                     color: "black",
//                     paddingLeft: "0.313rem",
//                     paddingRight: "0.625rem",
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "transparent",
//                       },
//                       "&:hover fieldset": {
//                         borderColor: "transparent",
//                       },
//                       "&.Mui-focused fieldset": {
//                         borderColor: "transparent",
//                       },
//                     },
//                     "&:hover": {
//                       color: "black", // Set the text color for the hover state
//                       backgroundColor: "#f4f4f4", // You can change the background color on hover if needed
//                     },
//                   }}
//                 >
//                   <DownloadIcon
//                     sx={{
//                       width: "1.875rem",
//                       marginRight: "0.313rem",
//                     }}
//                   />
//                   <Typography
//                     sx={{
//                       fontSize: "0.938rem",
//                       fontFamily: "Poppins , sans-serif",
//                       fontWeight: "500",
//                       letterSpacing: "0rem",
//                     }}
//                   >
//                     Missing Dates
//                   </Typography>
//                 </Button>

//                 {/* <Button
//   onClick={handleDownloadCSV}
//   sx={{
//     ml: "10px",
//     mt: "60px",
//     textTransform: "none",
//     width: "4rem",
//     height: "2.6rem",
//     padding: "8px 16px",
//     borderRadius: "6px",
//     background: "#f4f4f4",
//     fontFamily: "Poppins, sans-serif",
//     fontSize: "1rem",
//     fontWeight: "400",
//     lineHeight: "1.125rem",
//     textAlign: "center",
//     color: "#444444",
//     "&:hover": {
//       color: "white",
//       background: "black",
//     },
//     "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
//       webkitTapHighlightColor: "black",
//     },
//   }}
// >
//   Download
// </Button>;
//    */}
//               </Grid>
//               <Grid
//                 item
//                 sx={{
//                   // backgroundColor: "#aabcde",
//                   marginTop: "4.375rem",
//                   marginLeft: "0.625rem",
//                   width: "9%",
//                 }}
//               >
//                 <Switch
//                   sx={{
//                     width: "100%",
//                     height: "1.688rem",
//                     padding: "0rem",
//                     "& .MuiSwitch-switchBase": {
//                       padding: "0rem",
//                       marginTop: "0.188rem",
//                       transitionDuration: "300ms",
//                       "&.Mui-checked": {
//                         transform: "translateX(1.875rem)",
//                         color: "#fff",
//                         "& + .MuiSwitch-track": {
//                           backgroundColor: colors.grey[650],
//                           opacity: 1,
//                           border: 0,
//                         },
//                         "&.Mui-disabled + .MuiSwitch-track": {
//                           opacity: 0.5,
//                         },
//                       },
//                       "&.Mui-focusVisible .MuiSwitch-thumb": {
//                         color: "#33cf4d",
//                         border: "6px solid #fff",
//                       },
//                       "&.Mui-disabled .MuiSwitch-thumb": {
//                         color:
//                           theme.palette.mode === "light"
//                             ? theme.palette.grey[100]
//                             : theme.palette.grey[600],
//                       },
//                       "&.Mui-disabled + .MuiSwitch-track": {
//                         opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//                       },
//                     },
//                     "& .MuiSwitch-thumb": {
//                       boxSizing: "border-box",
//                       width: "1.25rem",
//                       height: "1.25rem",
//                     },
//                     "& .MuiSwitch-track": {
//                       borderRadius: 26 / 2,
//                       backgroundColor:
//                         theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//                       opacity: 1,
//                       transition: theme.transitions.create(
//                         ["background-color"],
//                         {
//                           duration: 500,
//                         }
//                       ),
//                     },
//                   }}
//                   focusVisibleClassName=".Mui-focusVisible"
//                   disableRipple
//                   onChange={() => setFilterActive(!filterActive)}
//                 />
//               </Grid>
//             </Grid>

//             <Box
//               sx={{
//                 // backgroundColor: "#fff00f",
//                 width: "100%",
//                 height: "56.5%",
//               }}
//               // sx={{
//               //   display: "flex",
//               //   marginX: "20px",
//               //   mt: "32px",
//               //   width: "535px",
//               //   mx: "auto",
//               //   flexDirection: "column",
//               //   justifyContent: "center",
//               //   alignItems: "center",
//               //   alignSelf: "stretch",
//               //   borderRadius: "16px",
//               //   border: `1px dashed ${colors.grey[500]}`,
//               //   background: "#F4F4F4",
//               //   "& .MuiDropzoneArea-textContainer": {
//               //     textAlign: "center",
//               //     marginTop: "100px",
//               //   },
//               //   "& .MuiDropzoneArea-root": {
//               //     background: colors.grey[200],
//               //     borderColor: "transparent",
//               //     borderRadius: "16px",
//               //   },
//               // }}
//             >
//               {/* Dropzone Area */}
//               {!filePreview && (
//                 <Box
//                   sx={{
//                     height: "79%",
//                     // backgroundColor: "#ff0000",
//                     width: "100%",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       marginX: "1.25rem",
//                       marginTop: "2rem",
//                       width: "92%",
//                       height: "100%",
//                       marginX: "auto",
//                       flexDirection: "column",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       alignSelf: "stretch",
//                       borderRadius: "16px",
//                       border: `1px dashed ${colors.grey[500]}`,
//                       background: colors.grey[200],
//                       "& .MuiDropzoneArea-textContainer": {
//                         textAlign: "center",
//                         marginTop: "2.5rem",
//                       },
//                       "& .MuiDropzoneArea-root": {
//                         background: colors.grey[200],
//                         borderColor: "transparent",
//                         borderRadius: "16px",
//                         minHeight: "100px",
//                       },
//                     }}
//                   >
//                     <DropzoneArea
//                       disabled={files.length > 0}
//                       onChange={handleCombineChange}
//                       key={clearFiles}
//                       className="dropzone"
//                       showFileNames
//                       dropzoneText="Drag and drop a file here or click"
//                       fontSize="0.875rem"
//                       color="#444444"
//                       fontFamily="Poppins,sans-serif"
//                       lineHeight="1.25rem"
//                       showAlerts={true}
//                       filesLimit={1}
//                       acceptedFiles={[
//                         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.xlsx, .xlsb, .xlsm, .xls,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
//                       ]}
//                     />
//                   </Box>
//                   <Button
//                     variant="contained"
//                     sx={{
//                       display: "flex",
//                       height: "10%",
//                       width: "25%",
//                       marginX: "auto",
//                       marginTop: "1.25rem",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       alignSelf: "stretch",
//                       borderRadius: "10px",
//                       backgroundColor: colors.primary["dark"],
//                       color: colors.primary["main"],
//                     }}
//                     onClick={handlePreview}
//                   >
//                     Preview
//                   </Button>
//                 </Box>
//               )}

//               {/* Show File Preview */}
//               {filePreview && (
//                 <Box
//                   sx={{
//                     height: "84%",
//                     width: "90%",
//                     // backgroundColor: "#aff5ff",
//                     mx: "auto",
//                   }}
//                 >
//                   <TempRHCh />
//                   <Grid
//                     container
//                     spacing={2}
//                     sx={{ display: "flex", justifyContent: "space-evenly" }}
//                   >
//                     <Grid
//                       item
//                       sx={{
//                         display: "flex",
//                         // backgroundColor: "#a1b1c1",
//                         width: "30%",
//                       }}
//                     >
//                       <Button
//                         onClick={handleDelete}
//                         variant="contained"
//                         sx={{
//                           display: "flex",
//                           height: "1.875rem",
//                           width: "100%",
//                           marginX: "auto",
//                           marginTop: "1.25rem",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           alignSelf: "stretch",
//                           borderRadius: "10px",
//                           backgroundColor: colors.primary["dark"],
//                           color: colors.primary["main"],
//                         }}
//                       >
//                         Delete
//                       </Button>
//                     </Grid>
//                     <Grid
//                       item
//                       sx={{
//                         display: "flex",
//                         // backgroundColor: "#a1b1c1",
//                         width: "30%",
//                       }}
//                     >
//                       <Button
//                         onClick={handleFile}
//                         variant="contained"
//                         sx={{
//                           display: "flex",
//                           height: "1.875rem",
//                           width: "100%",
//                           marginX: "auto",
//                           marginTop: "1.25rem",
//                           justifyContent: "center",
//                           alignItems: "center",
//                           alignSelf: "stretch",
//                           borderRadius: "10px",
//                           backgroundColor: colors.primary["dark"],
//                           color: colors.primary["main"],
//                         }}
//                       >
//                         Upload
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Box>
//               )}
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           sx={{
//             marginLeft: "2.25rem",
//             // backgroundColor: "#a1b1c1",
//             width: "48.3%",
//           }}
//         >
//           <UploadCard title={"Upload Load Data"} />
//         </Grid>
//       </Grid>

//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         style={{
//           fontFamily: "Poppins,sans-serif",
//           fontSize: "0.875rem"
//         }}
//       >
//         <DialogTitle id="alert-dialog-title">
//           <div style={{ textAlign: "center" }}>
//             <span >{modhead}</span>
//           </div>
//         </DialogTitle>
//         <hr
//           style={{
//             width: "100%",
//             textAlign: "left",
//             marginLeft: "10",
//             fontFamily: "Poppins,sans-serif",
//             fontSize: "0.875rem"
//           }}
//         />

//         <DialogContent>
//           <DialogContentText id="alert-dialog-description"
//           style={{
//             fontFamily: "Poppins,sans-serif",
//             fontSize: "0.875rem"
//           }}
//           >
//             {icon}
//             {modbody}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} sx={{
//             backgroundColor: colors.primary.black,
//             color: colors.primary.main,
//             "&:hover": {
//               backgroundColor: colors.primary.black,
//               color: colors.primary.main,
//             },
//           }} autoFocus>
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Backdrop open={loader}>
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     </div>
<div
style={{
  width: "100%"
}}>
<Grid container columnSpacing={3} xs={12} sm={12} md={12} lg={12}
  sx={{
    // marginTop: "1.875rem",
    width: '100%',
  }}>
  <Grid item xs={12} sm={12} md={6} lg={6}
    sx={{
      mt: "1.875rem",
    }}>
    <Grid item xs={12} sm={12} md={12} lg={12}
      raised="true"
      sx={{
        alignContent: "center",
        height: "97.5%",
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "24px",
        background: colors.primary['main'],
        boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        marginBottom: "1.25rem",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          alignSelf: "stretch",
          color: colors.primary.dark,
          fontSize: "1.7rem",
          fontWeight: "600",
          lineHeight: "2.166rem",
          mt: "1.25rem",
          ml: "1.25rem",
          fontFamily: "Poppins,sans-serif",
        }}
      >
        Upload Weather Data
      </Typography>
      <Grid container
        sx={{
          justifyContent: "space-between",
          paddingRight: "20px",
          marginBottom: "10px"
          // height: '100px'
        }}
      >
        <Grid item
          sx={{
            marginLeft: "0rem",
          }}
        >
          {filterActive ? (
            <Typography
              sx={{
                // marginLeft: "40px",
                // marginTop: "20px",
                // fontWeight: 500,
                color: colors.grey[850],
                // leadingTrim: "both",
                // textEdge: "cap",
                fontFamily: "Poppins,sans-serif",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                // lineHeight: "normal",
                marginTop: "1.25rem",
                marginLeft: "0.938rem",

              }}
            >
              Available Weather Data
            </Typography>
          ) : (
            <Typography
              sx={{
                // marginLeft: "40px",
                // marginTop: "20px",
                // fontWeight: 500,
                color: colors.grey[850],
                leadingTrim: "both",
                textEdge: "cap",
                fontFamily: "Poppins,sans-serif",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                marginTop: "1.25rem",
                marginLeft: "0.938rem",

              }}
            >
              UnAvailable Weather Data
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Box
            sx={{
              borderRadius: "0.625rem",
              marginLeft: "0.5rem",
              marginTop: "1.25rem",
              display: "flex",
              width: "94%",
              backgroundColor: colors.grey[300],
            }}
          >
            <InputLabel
              sx={{
                color: "black",
                marginTop: "0.2rem",
                paddingRight: "0rem",
                marginLeft: "0.313rem",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                color: colors.primary["dark"]
              }}
            >
              Year:
            </InputLabel>
            <FormControl
              sx={{
                justifySelf: "end",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            >
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                sx={{
                  marginTop: "0.125rem",
                  marginBottom: "0.313rem",
                  height: "1.25rem",
                  width: "4.688rem",
                  fontSize: "0.625rem",
                  color: colors.primary["dark"],
                  lineHeight: "none",
                  // borderColor: '#d1d1d1',
                  justifySelf: "end",
                  marginLeft: "0.313rem",
                  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
                    textOverflow: 'inherit'
                  }
                }}
              >
                {getYearMenuItems().map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} sx={{ maxHeight: "7.313rem"}}>
        <WeatherCalendar
          selectedYear={selectedYear}
          filterActive={filterActive}
          setCountData={setCountData}
          setDatee={setDatee}
        />
      </Grid>
      {/* </Box> */}
      <Grid container
        sx={{
          // backgroundColor: "#051364",
          width: "100%",
          marginTop: "10px"
        }}>
        <Grid item
          sx={{
            // backgroundColor: "#fbef",
            width: "55.8%"
          }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                width: "88.8%",
                backgroundColor: colors.grey[200],
                borderRadius: 2,
                marginX: 1,
                marginLeft: "1.8rem",
                // marginTop: "3.75rem",
                height: "3rem"
              }}
            >
              <FormControl
                fullWidth
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <InputLabel
                  sx={{
                    // mb: "150px",
                    marginTop: "0rem",
                    paddingTop: "0rem",
                    marginBottom: "1.25rem",
                    paddingBottom: "1.25rem",
                    color: colors.grey[850],
                    fontSize: "1.2rem",
                    lineHeight: '.9rem'
                  }}
                >
                  Date:
                </InputLabel>
                <DatePicker
                  format="YYYY/MM/DD"
                  onChange={handleDateChange}
                  autoOk={true}
                  value={selectdate ? date : null}
                  minDate={dayjs("2007-03-10")}
                  maxDate={datee ? dayjs(datee) : null}

                  slotProps={{
                    popper: {
                      sx: {
                        ".MuiPaper-root": {
                          //  border: "1px solid blue",
                          borderRadius: "4px !important",

                        },
                        " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                          boxShadow: "none !important",

                        },
                        'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected': {
                          borderRadius: '12px !important',
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main
                        },
                        // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                        //   borderRadius: '12px !important',
                        //   backgroundColor: colors.primary.black,
                        //   color: colors.primary.main

                        // },
                        ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                          border: 'none !important'
                        },
                        ' .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                          borderRadius: '12px !important',
                          // background: colors.primary.light
                        },
                        ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                          borderRadius: "10px !important",
                        },
                        '.css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                          borderRadius: '12px',
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                        },
                        '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                          borderRadius: '12px !important',
                          backgroundColor: `${colors.grey[200]} !important`
                        },
                        '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover': {
                          borderRadius: '12px !important',
                          backgroundColor: colors.grey[200]
                        },
                        '.css-13urpcw-MuiButtonBase-root-MuiPickersDay-root': {
                          borderRadius: '12px !important'
                        },
                        ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                        {
                          backgroundColor: colors.primary.black,
                          color: colors.primary.main,
                          borderRadius: "10px !important",
                        },

                      },
                    },
                  }}
                  sx={{
                    borderRadius: "25px",
                    width: "11.25rem", // Adjust as needed
                    border: "none",
                    fontSize: "0.625rem",
                    lineHeight: '0.9rem !important',

                    marginLeft: "auto", // Push to the right
                    "& .MuiInputLabel-root": {
                      display: "none", // Hide the default label
                    },
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-putrnr,.css-1nnh0tb .css-1uvydh2,":
                    {
                      height: "1.5rem !important",
                    },
                    "& .css-1h14m6r-MuiInputBase-root-MuiOutlinedInput-root, .css-1n6yb8w-MuiInputBase-root-MuiOutlinedInput-root,.css-5cw3jg,.css-1r7a53k": {
                      height: "3rem"
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <input
                      {...params.inputProps}
                      style={{
                        height: ".1em",
                        border: "none",
                        width: "10%",
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </LocalizationProvider>
        </Grid>
        <Grid item
          sx={{
            // backgroundColor: "#ab5de4", 
            width: "28.5%"
          }}>
          <Button
            onClick={handleDataChange1}
            sx={{
              marginLeft: "0.625rem",
              // marginTop: "3.75rem",
              textTransform: 'none',
              height: "3rem",
              width: "94%",
              borderRadius: "0.438rem",
              backgroundColor: "#f4f4f4",
              color: "black",
              paddingLeft: "0.313rem",
              paddingRight: "0.625rem",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
              "&:hover": {
                color: "black", // Set the text color for the hover state
                backgroundColor: "#f4f4f4", // You can change the background color on hover if needed
              },
            }}
          >
            <DownloadIcon
              sx={{
                width: "1.875rem",
                marginRight: "0.313rem",
              }}
            />
            <Typography
              sx={{
                fontSize: "0.938rem",
                fontFamily: "Poppins , sans-serif",
                fontWeight: "500",
                letterSpacing: "0rem",
              }}
            >
              Missing Dates
            </Typography>
          </Button>

          {/* <Button
onClick={handleDownloadCSV}
sx={{
ml: "10px",
mt: "60px",
textTransform: "none",
width: "4rem",
height: "2.6rem",
padding: "8px 16px",
borderRadius: "6px",
background: "#f4f4f4",
fontFamily: "Poppins, sans-serif",
fontSize: "1rem",
fontWeight: "400",
lineHeight: "1.125rem",
textAlign: "center",
color: "#444444",
"&:hover": {
color: "white",
background: "black",
},
"& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
webkitTapHighlightColor: "black",
},
}}
>
Download
</Button>;
*/}
        </Grid>
        <Grid item
          sx={{
            // backgroundColor: "#aabcde", 
            marginTop: "0.625rem",
            marginLeft: "0.625rem",
            width: "9%"
          }}>
          <Switch
            sx={{
              width: "100%",
              height: "1.688rem",
              padding: "0rem",
              "& .MuiSwitch-switchBase": {
                padding: "0rem",
                marginTop: "0.188rem",
                transitionDuration: "300ms",
                "&.Mui-checked": {
                  transform: "translateX(1.875rem)",
                  color: "#fff",
                  "& + .MuiSwitch-track": {
                    backgroundColor: colors.grey[650],
                    opacity: 1,
                    border: 0,
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#33cf4d",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled .MuiSwitch-thumb": {
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[600],
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
                },
              },
              "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                width: "1.25rem",
                height: "1.25rem",
              },
              "& .MuiSwitch-track": {
                borderRadius: 26 / 2,
                backgroundColor:
                  theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
                opacity: 1,
                transition: theme.transitions.create(
                  ["background-color"],
                  {
                    duration: 500,
                  }
                ),
              },
            }}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            onChange={() => setFilterActive(!filterActive)}
          />
        </Grid>
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12}
        sx={{
          height: "56.5%",
        }}
      // sx={{
      //   display: "flex",
      //   marginX: "20px",
      //   mt: "32px",
      //   width: "535px",
      //   mx: "auto",
      //   flexDirection: "column",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   alignSelf: "stretch",
      //   borderRadius: "16px",
      //   border: `1px dashed ${colors.grey[500]}`,
      //   background: "#F4F4F4",
      //   "& .MuiDropzoneArea-textContainer": {
      //     textAlign: "center",
      //     marginTop: "100px",
      //   },
      //   "& .MuiDropzoneArea-root": {
      //     background: colors.grey[200],
      //     borderColor: "transparent",
      //     borderRadius: "16px",
      //   },
      // }}
      >
        {/* Dropzone Area */}
        {!filePreview && (

          <Grid item xs={12} sm={12} md={12} lg={12}
            sx={{
              height: "75%",
              // backgroundColor: "#ff0000",
              // width: "100%",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}
            sx={{
              display: "flex",
              marginX: "1.25rem",
              marginTop: "2rem",
              // width: "92%",
              height: '100%',
              // marginX: "auto",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              borderRadius: "16px",
              border: `1px dashed ${colors.grey[500]}`,
              background: colors.grey[200],
              "& .MuiDropzoneArea-textContainer": {
                textAlign: "center",
                marginTop: "2.5rem",
              },
              "& .MuiDropzoneArea-root": {
                background: colors.grey[200],
                borderColor: "transparent",
                borderRadius: "16px",
                minHeight: "100px"
              },
            }}>
              <DropzoneArea
                disabled={files.length > 0}
                onChange={handleCombineChange}
                className="dropzone"
                showFileNames
                dropzoneText="Drag and drop a file here or click"
                fontSize="0.875rem"
                color="#444444"
                fontFamily="Poppins,sans-serif"
                lineHeight="1.25rem"
                showAlerts={true}
                filesLimit={1}
                acceptedFiles={[
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.xlsx, .xlsb, .xlsm, .xls,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                ]}
              />
            </Grid>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                height: "10%",
                width: "25%",
                marginX: "auto",
                marginTop: "1.25rem",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "stretch",
                borderRadius: "10px",
                backgroundColor: colors.primary["dark"],
                color: colors.primary["main"],
                marginBottom: "20px"
              }}
              onClick={handlePreview}
            >
              Preview
            </Button>
          </Grid>
        )}

        {/* Show File Preview */}
        {filePreview && (
          <Box
            sx={{
              height: "84%",
              width: '90%',
              // backgroundColor: "#aff5ff",
              mx: 'auto'
            }}>
            <TempRHCh />
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Grid item
                sx={{
                  display: 'flex',
                  // backgroundColor: "#a1b1c1",
                  width: "30%"
                }}>
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  sx={{
                    display: "flex",
                    height: "1.875rem",
                    width: "100%",
                    marginX: "auto",
                    marginTop: "1.25rem",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderRadius: "10px",
                    backgroundColor: colors.primary["dark"],
                    color: colors.primary["main"],
                  }}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item
                sx={{
                  display: 'flex',
                  // backgroundColor: "#a1b1c1",
                  width: "30%"
                }}>
                <Button
                  onClick={handleFile}
                  variant="contained"
                  sx={{
                    display: "flex",
                    height: "1.875rem",
                    width: "100%",
                    marginX: "auto",
                    marginTop: "1.25rem",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderRadius: "10px",
                    backgroundColor: colors.primary["dark"],
                    color: colors.primary["main"],
                  }}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}
    sx={{
      // marginLeft: '2.25rem',
      // backgroundColor: "#a1b1c1", 
      // width: "48.3%"
    }}>
    <UploadCard title={"Upload Load Data"} />
  </Grid>
</Grid>
<Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    <div style={{ textAlign: "center" }}>
      <span>{modhead}</span>
    </div>
  </DialogTitle>
  <hr
    style={{
      width: "80%",
      textAlign: "left",
      marginLeft: "10",
    }}
  />

  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {icon}
      {modbody}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}
      sx={{
        backgroundColor: colors.primary.black,
        color: colors.primary.main,
        "&:hover": {
          backgroundColor: colors.primary.black,
          color: colors.primary.main,
        },
      }}
      autoFocus>
      Close
    </Button>
  </DialogActions>
</Dialog>

<Backdrop open={loader}>
  <CircularProgress color="inherit" />
</Backdrop>
</div>
  );
}
export default UploadData;
