import React, { forwardRef, useRef } from "react";
import { useTheme } from "@mui/material";
import { Grid, Button } from "@mui/material";
import Download from "@mui/icons-material/Download";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import domtoimage from "dom-to-image-more";

const BarChart = forwardRef(({ isDashboard = false, maeData, margins, keys,customColors }, ref) => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!Array.isArray(maeData)) {
    
    return null;
  }
  const customTickFormat = value => {
    return `${value}%`; 
  };

  const exportChartToPng = (chartName = "chart") => {
    if (chartRef.current) {
      domtoimage
        .toPng(chartRef.current, {
          width: chartRef.current.offsetWidth * 2,
          height: chartRef.current.offsetHeight * 2,
          style: {
            transform: "scale(" + 2 + ")",
            transformOrigin: "top ",
          },
        })
        .then(function (dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${chartName}-Chart.png`;
          link.click();
        })
        .catch(function (error) {
          
        });
    }
  };
  
  const getTickValues = (data, numLegends) => {
    const MAX_TICKS = 20; // Maximum number of ticks to display
  
    // Calculate the interval between ticks
    let tickInterval = Math.ceil(data.length / MAX_TICKS);
  
    // Ensure at least one tick
    if (tickInterval === 0) {
      tickInterval = 1;
    }
  
    // Create an array of tick values
    const tickValues = data
      .map((item, index) => {
        if (index % tickInterval === 0) {
          return item.date;
        }
        return null;
      })
      .filter((value) => value !== null);
  
    // If the number of legends is greater than 20, ensure we display ticks
    // for every 5th item
    if (numLegends > MAX_TICKS) {
      return tickValues.filter((_, index) => index % 5 === 0);
    }
  
    return tickValues;
  };
  
  const customColors1 = ["red", "blue", "green"];
  return (
    <div style={{ height: "80%", width: "100%" }}>
      <Grid container sx={{ display: "flex", justifyContent: "right" }}>
        <Button
          sx={{
            color: colors.primary.dark,
          fontFamily: "Poppins,sans-serif",
          textTransform: "none",
          width: "2.5rem",
          height: "2.5rem",
          minWidth: "10px ",
          // padding: "1rem 2rem",
          borderRadius: "12px",
          background: colors.grey[200],
          mr:"10px",
          fontSize: "0.875rem",
          fontWeight: "400",
          lineHeight: "1.125rem",
          textAlign: "center",
          "&:hover": {
            color: colors.primary.dark,
            background: colors.grey[200],
          },
          "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
            webkitTapHighlightColor: colors.primary["black"],
          },
          }}
          onClick={() => {
            const chartName = keys.map((id) => {
              if (id === "mae_wdLoad") return "MAPE-Load";
              if (id === "mae_temp") return "MAPE-Temperature";
              if (id === "mae_rh") return "MAPE-Humidity";
            }).join("-");
            exportChartToPng(chartName);
          }}
        >
          <Download />
        </Button>
      </Grid>
      <div
        ref={chartRef}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          cursor:"pointer",
          backgroundColor:colors.primary.main,
          borderRadius:'0.625rem'
        }}
      >
        <ResponsiveBar
          data={maeData}
          ref={ref}
          theme={{
            axis: {
              legend: {
                text: {
                  fill: colors.grey[900],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[900],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[900],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[900],
              },
            },
          }}
          indexBy="date"
          keys={keys}
          borderRadius={4}
          margin={margins}
          padding={0.25}
          groupMode="grouped"
          
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={customColors}
          borderColor={{
            from: "color",
            modifiers: [["darker", "1.6"]],
          }}
          axisTop={null}
      
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: isDashboard ? undefined : "Load",
            legendPosition: "middle",
            legendOffset: 42,
            format: (date) => {
              const formattedDate = new Date(date).toLocaleDateString("en-US", {
                day: "2-digit",
              });
              return formattedDate;
            },
            tickValues: getTickValues(maeData, keys.length),
          }}
          
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            format: customTickFormat,
            tickRotation: 0,
            legend: isDashboard ? undefined : "value",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              data: keys.map((id,index) => ({
                id,
                label:
                  id === "mae_wdLoad"
                    ? "Load - MAPE"
                    : id === "mae_temp"
                    ? "Temperature - MAPE"
                    : "Humidity - MAPE",
                    color:
                    id === "mae_wdLoad"
                      ? colors.linechart[980]
                      : id === "mae_temp"
                      ? colors.orange[100]
                      : colors.purple[200]
                
              })),
              anchor: "bottom",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 60,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          barAriaLabel={function (e) {
            return (
              e.id + ": " + e.formattedValue + " in category: " + e.indexValue
            );
          }}
          tooltip={({ id, value, indexValue }) => (
            <div
              style={{
                background: colors.primary.main,
                 border: "0.2px solid #ccc",
                 borderRadius:'16px',
                padding: "10px",
              }}
            >
              <div  style={{
          fontSize: '16px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: "600",
          color:colors.primary["dark"]
        }}>Date: {indexValue}</div>
              
              {id === "mae_wdLoad" ? "Load" : id === "mae_temp" ? "Temp" : "Humid"}:{" "}
              {Number(value).toFixed(2) }%
            </div>
          )}
        />
      </div>
    </div>
  );
});

export default BarChart;