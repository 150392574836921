import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  useTheme,
  Button,
  InputLabel,
  FormControl,
 
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { tokens } from "../../theme";
import LineChart2 from "../../Components/LineChart2";
import instance from "../../middleware";
import dayjs from "dayjs";
import NextTable from "../../Components/NextTable";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";

const Ndayforecast = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1300,
    height: 700,
    // bgcolor: "background.paper",
    backgroundColor: colors.primary.main,
    boxShadow: 24,
    p: 4,
  };
  const [data, setData] = useState({ predictLoad: null });

  const [selectedDate, setSelectedDate] = useState(dayjs().add(1, "day"));
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);

  const fetchChartData = async (date) => {
    if (date) {
      try {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        const predictLoadResponse = await instance.post(
          "/predictLoad14",
          `DATE=${formattedDate}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const responseData = predictLoadResponse.data.response;
        if (responseData && responseData.length > 0) {
         
          const jsonData = responseData;
          setData({ predictLoad: jsonData });
        } else {
          toast.error("Invalid Data Structure in Response");
        }
      } catch (error) {
        toast.error("No Data Available");
      }
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchChartData(selectedDate);
      localStorage.setItem(selectedDate,"ndate")
    }
  }, [selectedDate]);
  const toggleLoadModal = () => {
    setIsLoadModalOpen(!isLoadModalOpen);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <>
    <Box 
    // sx={{ marginLeft: "7px" }}
    >
      <Grid xs={12} sm={12} md={12} lg={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "20px",
          // width: "75rem",
          width: "98%",
          alignItems: "center",
          marginTop: "1.875rem",
          position: "relative",
          top: 0,
          zIndex: 0,
          height: "5rem",
          backgroundColor: colors.primary.main,
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Typography
              sx={{
                marginTop: "0.313rem",
                fontSize: "1.5rem",
                fontWeight: "600",
                marginLeft: "1.3rem",
                fontFamily: "Poppins,sans-serif",
                color: colors.primary.dark,
              }}
            >
              Forecast for Next 14 Days
            </Typography>
          </Grid>
          {/* <Grid item sx={{ display: "flex", marginRight: "1.25rem" }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[200],
                    borderRadius: "0.625rem",
                  }}
                >
                  <Typography sx={{ padding: "0.625rem" }}>
                    Start Date :
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[200],
                    borderRadius: "0.625rem",
                  }}
                >
                  <Typography sx={{ padding: "0.625rem" }}>
                    End Date :
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item sx={{ marginTop: "0rem" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  backgroundColor: colors.grey[200],
                  borderRadius: "12px",
                  height: "3.125rem",
                  width: "15.375rem",
                  ml: '1.25rem',
                  mr: '1.25rem'

                }}
              >
                <FormControl fullWidth id="fullest">
                  <InputLabel
                    id="endate"
                    sx={{
                      color: colors.grey[800],
                    }}
                  >
                    Start Date:
                  </InputLabel>

                  <DatePicker
                    // value={selectedEndDate}
                    value={selectedDate}
                    onChange={handleDateChange}
                    // onChange={handleEndDateChange}
                    format="YYYY/MM/DD"
                    sx={{
                      borderRadius: "1.563rem",
                      width: "10.625rem", // Adjust as needed
                      border: "none",

                      marginLeft: "auto", // Push to the right
                      "& .MuiInputLabel-root": {
                        display: "none", // Hide the default label
                      },
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                      {
                        height: "0.938rem !important",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                      "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        fontSize: "0.875rem !important",
                      },
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1uvydh2,.css-putrnr":
                      {
                        fontSize: "0.875rem !important",
                        padding: "0rem !important",
                        marginLeft: "2.375rem !important",
                        paddingRight: "0rem ! important",
                        width: "60% !important",
                        marginTop: "1rem",
                      },
                      "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        marginLeft: "0.7rem !important",
                        fontSize: "0.875rem",
                      },
                      "& .css-hli2kd-MuiButtonBase-root-MuiIconButton-root":
                      {
                        padding: "0rem !important",
                      },
                      // "& .css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root":
                      // {
                      //   // marginRight:'0rem !important',
                      // },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root,.css-slyssw,.css-19f3993":
                      {
                        padding: "0rem !important",
                        marginTop: "0.875rem",
                      },
                    }}
                    slotProps={{
                      popper: {
                        sx: {
                          ".MuiPaper-root": {
                            //  border: "1px solid blue",
                            borderRadius: "4px !important",

                          },
                          " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                            boxShadow: "none !important",

                          },
                          'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected': {
                            borderRadius: '12px !important',
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main
                          },
                          // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                          //   borderRadius: '12px !important',
                          //   backgroundColor: colors.primary.black,
                          //   color: colors.primary.main

                          // },
                          ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                            border: 'none !important'
                          },
                          ' .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                            borderRadius: '12px !important',
                            // background: colors.primary.light
                          },
                          ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                            borderRadius: "10px !important",
                          },
                          '.css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                            borderRadius: '12px',
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                          },
                          '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                            borderRadius: '12px !important',
                            backgroundColor: `${colors.grey[200]} !important`
                          },
                          '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover': {
                            borderRadius: '12px !important',
                            backgroundColor: colors.grey[200]
                          },
                          '.css-13urpcw-MuiButtonBase-root-MuiPickersDay-root': {
                            borderRadius: '12px !important'
                          },
                          ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                          {
                            backgroundColor: colors.primary.black,
                            color: colors.primary.main,
                            borderRadius: "10px !important",
                          },

                        },
                      },
                    }}
                    renderInput={(params) => (
                      <input
                        {...params.inputProps}
                        style={{
                          height: ".1em",
                          border: "none",
                          width: "10%",
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} sx={{width: "98%"}}>
        <Grid xs={12} sm={12} md={12} lg={12}
          sx={{
            backgroundColor: colors.primary.main,
            marginTop: "1.875rem",
            display: "flex",
            borderRadius: "24px",
            height: "25.25rem",
            boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid item sx={{ display: 'flex' }}>
            <Typography
              sx={{
                fontSize: "1.563rem",
                fontWeight: "600",
                marginTop: "1rem",
                fontFamily: "Poppins,sans-serif",
                color: colors.grey[900],
                display: 'flex',
                marginRight: '66rem',
              }}
            >
              Load
            </Typography>
          </Grid>
          <Box sx={{ position: "relative", width: "100%", height: '27.25rem' }}>
            <IconButton
              onClick={toggleLoadModal}
              sx={{
                position: "absolute",
                right: "0.6rem",
                bottom: '21.9rem'
              }}
            >
              <FullscreenIcon />
            </IconButton>
            <LineChart2
              predictedData={data.predictLoad}
              pointFaceColor="rgba(255,255,255)"
              pointSize={2}
              expandedView
              lineWidth={1}
              color1={[
                "#F3C699", "#919191", "#E07001", "#008080", "#FF00FF", "#FFD700", "#6A5ACD", "#DC143C", "#00ff00", "#0000ff", "#FF6347", "#7FFFD4", "#800080", "#00CED1",
              ]}
              pointWidth={1}
              legend="Load"
              margins={{ top: 10, right: 30, bottom: 65, left: 70 }}
              pointBorderColor={{ from: "serieColor" }}
              containercolor={colors.linechart[400]}
            />
          </Box>
          <Modal
            open={isLoadModalOpen}
            onClose={toggleLoadModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                backdropFilter: "blur(2px)",
              },
            }}
          >
            <Box sx={modalStyle}>
              <LineChart2
                predictedData={data.predictLoad}
                pointFaceColor="rgba(255,255,255)"
                pointSize={2}
                lineWidth={1}
                pointWidth={1}
                legend="Load"
                margins={{ top: 10, right: 30, bottom: 70, left: 70 }}
                pointBorderColor={{ from: "serieColor" }}
                color1={[
                  "#F3C699", "#919191", "#E07001", "#008080", "#FF00FF", "#FFD700", "#6A5ACD", "#DC143C", "#00ff00", "#0000ff", "#FF6347", "#7FFFD4", "#800080", "#00CED1",
                ]}
                containercolor={colors.linechart[400]}
                expandedView
              />
              <IconButton
                onClick={toggleLoadModal}
                sx={{
                  position: "absolute",
                  top: "0.625rem",
                  right: "0.625rem",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Modal>
        </Grid>
      </Grid>

      <Box>
        <NextTable predictedData={data.predictLoad}
        />
      </Box>
    </Box>
  </>
  );
};

export default Ndayforecast;