import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import "../Pages/Forecast/Forecast.css";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonCellRenderer from "./TableButton.jsx";
import ExcelJS from "exceljs"; // Import exceljs
import { saveAs } from "file-saver";
import {
  Card,
  Grid,
  Box,
  InputLabel,
  Button,
  useTheme,
  Typography,
} from "@mui/material";
import { tokens } from "../theme.js";
import dayjs from "dayjs";
import instance from "../middleware.js";
import { toast } from "react-toastify";

const StatisticalTable = ({ predictDate, dayType, newDates }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const gridTheme = isDarkMode ? "ag-theme-dark" : "ag-theme-alpine";
  const colors = tokens(theme.palette.mode);
  const suppressRowHoverHighlight = true;
  const [rowData, setRowData] = useState([]);
  const [addPredictDates, setPredictDates] = useState(null);
  const [addedDates, setAddedDates] = useState([]);
  const [processedDates, setProcessedDates] = useState([]);
  const [datesarray, setDatesArray] = useState({});
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Time Stamp",
      headerClass: "customHeader",
      field: "timeStamp",
      cellClass: "timestampCol",
      width: 130,
      lockPosition: "left",
      pinned: "left",
    },
    {
      headerName: "Predictions for",
      headerClass: "predForHeader",
      lockPosition: "left",
      children: [
        {
          headerName: "Temp",
          headerClass: "predForTempCol",
          field: "predictedTemp",
          cellClass: "tempClass",
          width: 130,
          lockPosition: "left",
        },
        {
          headerName: "Humidity",
          headerClass: "predForHumCol",
          field: "predictedHum",
          cellClass: "humidityClass",
          width: 130,
          lockPosition: "left",
        },
        {
          headerName: "Load",
          headerClass: "predForLoadCol",
          field: "predictedLoad",
          cellClass: "loadClass",
          width: 200,
          lockPosition: "left",
        },
      ],
    },
    {
      headerName: "CF",
      headerClass: "updatedLoadHeader",
      field: "difference",
      cellClass: "uploadCol",
      width: 130,
    },
    {
      headerName: "Updated Load",
      headerClass: "updatedLoadHeader",
      field: "updatePredictedLoad",
      cellClass: "uploadCol",
      width: 150,
    },
  ]);

  const downloadExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sheet1");
    const headers = [
      "Time Stamp",
      "Temperature",
      "Humidity",
      "Load",
      "CF",
      "Updated Load",
    ];
    if (addedDates.length !== 0) {
      addedDates.forEach((date) =>
        headers.push(
          `Actual[${dayjs(date).format("YYYY/MM/DD")}]-Predictions Temperature`,
          `Actual[${dayjs(date).format("YYYY/MM/DD")}]-Predictions Humidity`,
          `Actual[${dayjs(date).format("YYYY/MM/DD")}]-Predictions Load`
        )
      );
    }
    if (processedDates.lemgth !== 0) {
      processedDates.forEach((dates) => {
        const parts = dates.split(",");
        const firstDate = dayjs(parts[0]).format("YYYY/MM/DD");
        const secondDate = dayjs(parts[1]).format("YYYY/MM/DD");
        headers.push(
          `${firstDate} Temperature`,
          `${secondDate} Temperature`,
          "Difference",
          `${firstDate} Humidity`,
          `${secondDate} Humidity`,
          "Difference",
          `${firstDate} Load`,
          `${secondDate} Load`,
          "Difference"
        );
      });
    }
    sheet.addRow(headers);
    rowData.forEach((row) => {
      const defaultRow = [
        row.timeStamp,
        parseFloat(row.predictedTemp),
        parseFloat(row.predictedHum),
        parseFloat(row.predictedLoad),
        parseFloat(row.difference),
        parseFloat(row.updatePredictedLoad),
      ];
      if (addedDates.length !== 0) {
        addedDates.forEach((date) => {
          const temp = `${date} diffTemp`;
          const hum = `${date} diffHum`;
          const load = `${date} diffLoad`;
          defaultRow.push(
            parseFloat(row[temp]),
            parseFloat(row[hum]),
            parseFloat(row[load])
          );
        });
      }
      if (processedDates.lemgth !== 0) {
        processedDates.forEach((dates) => {
          const parts = dates.split(",");
          const firstDate = parts[0];
          const secondDate = parts[1];
          const load1 = `${firstDate}Load`;
          const load2 = `${secondDate}Load`;
          const temp1 = `${firstDate}Tem`;
          const temp2 = `${secondDate}Tem`;
          const hum1 = `${firstDate}Hum`;
          const hum2 = `${secondDate}Hum`;
          const diff1 = `${parts}diffTemp`;
          const diff2 = `${parts}diffHum`;
          const diff3 = `${parts}diffLoad`;
          defaultRow.push(
            parseFloat(row[temp1]),
            parseFloat(row[temp2]),
            parseFloat(row[diff1]),
            parseFloat(row[hum1]),
            parseFloat(row[hum2]),
            parseFloat(row[diff2]),
            parseFloat(row[load1]),
            parseFloat(row[load2]),
            parseFloat(row[diff3])
          );
        });
      }
      sheet.addRow(defaultRow);
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, `Statistical Predictions ${predictDate}.xlsx`);
    });
  };

  useEffect(() => {
    if (newDates) {
      setDatesArray({
        dates: newDates[0],
        difference: newDates[1],
        refDate: newDates[2],
      });
    }
    if (addPredictDates && addPredictDates !== newDates[2]) {
      setAddedDates([]);
      setProcessedDates([]);
    }
  }, [newDates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (datesarray.refDate && addPredictDates !== datesarray.refDate) {
          setPredictDates(datesarray.refDate);
          let response;
          if (predictDate === datesarray.refDate) {
            setColumnDefs([
              {
                headerName: "Time Stamp",
                headerClass: "customHeader",
                field: "timeStamp",
                cellClass: "timestampCol",
                width: 130,
                lockPosition: "left",
                pinned: "left",
              },
              {
                headerName: `Predictions for ${predictDate} [AI - Generated]`,
                headerClass: "predForHeader",
                lockPosition: "left",
                children: [
                  {
                    headerName: "Temp",
                    headerClass: "predForTempCol",
                    field: "predictedTemp",
                    cellClass: "tempClass",
                    width: 130,
                    lockPosition: "left",
                  },
                  {
                    headerName: "Humidity",
                    headerClass: "predForHumCol",
                    field: "predictedHum",
                    cellClass: "humidityClass",
                    width: 130,
                    lockPosition: "left",
                  },
                  {
                    headerName: "Load",
                    headerClass: "predForLoadCol",
                    field: "predictedLoad",
                    cellClass: "loadClass",
                    lockPosition: "left",
                    cellRenderer: (params) => {
                      return (
                        <ButtonCellRenderer
                          value={params.value}
                          onButtonClick={(inputValue) => {
                            let foundMatchingRow = false;
                            if (inputValue) {
                              let Value = parseFloat(
                                parseFloat(inputValue).toFixed(2)
                              );
                              setRowData((prevRows) => {
                                return prevRows.map((row) => {
                                  if (foundMatchingRow) {
                                    return {
                                      ...row,
                                      updatePredictedLoad:
                                        row.updatePredictedLoad + Value,
                                      difference: row.difference + Value,
                                    };
                                  } else if (
                                    params.value === row.predictedLoad
                                  ) {
                                    foundMatchingRow = true;
                                    return {
                                      ...row,
                                      updatePredictedLoad:
                                        row.updatePredictedLoad + Value,
                                      difference: row.difference + Value,
                                    };
                                  } else {
                                    return row;
                                  }
                                });
                              });
                            }
                          }}
                        />
                      );
                    },
                    width: 200,
                  },
                ],
              },
              {
                headerName: "CF",
                headerClass: "updatedLoadHeader",
                field: "difference",
                cellClass: "uploadCol",
                width: 130,
              },
              {
                headerName: "Updated Load",
                headerClass: "updatedLoadHeader",
                field: "updatePredictedLoad",
                cellClass: "uploadCol",
                width: 150,
              },
            ]);
            const params = new URLSearchParams();
            params.append("DATE", predictDate);
            params.append("SPECIALDAY", dayType);
            const startAnnDate = '2024-08-08';
            const apiEndpoint = dayjs(predictDate).isAfter(startAnnDate)
              ? "/predictLoad_ANN"
              : "/predictLoad";
            response = await instance.post(apiEndpoint, params, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            });
          } else {
            setColumnDefs([
              {
                headerName: "Time Stamp",
                headerClass: "customHeader",
                field: "timeStamp",
                cellClass: "timestampCol",
                width: 130,
                lockPosition: "left",
                pinned: "left",
              },
              {
                headerName: `Predictions for ${predictDate} based on ${datesarray.refDate}`,
                headerClass: "predForHeader",
                lockPosition: "left",
                children: [
                  {
                    headerName: "Temp",
                    headerClass: "predForTempCol",
                    field: "predictedTemp",
                    cellClass: "tempClass",
                    width: 130,
                    lockPosition: "left",
                  },
                  {
                    headerName: "Humidity",
                    headerClass: "predForHumCol",
                    field: "predictedHum",
                    cellClass: "humidityClass",
                    width: 130,
                    lockPosition: "left",
                  },
                  {
                    headerName: "Load",
                    headerClass: "predForLoadCol",
                    field: "predictedLoad",
                    cellClass: "loadClass",
                    lockPosition: "left",
                    cellRenderer: (params) => {
                      return (
                        <ButtonCellRenderer
                          value={params.value}
                          onButtonClick={(inputValue) => {
                            let foundMatchingRow = false;
                            if (inputValue) {
                              let Value = parseFloat(
                                parseFloat(inputValue).toFixed(2)
                              );
                              setRowData((prevRows) => {
                                return prevRows.map((row) => {
                                  if (foundMatchingRow) {
                                    return {
                                      ...row,
                                      updatePredictedLoad:
                                        row.updatePredictedLoad + Value,
                                      difference: row.difference + Value,
                                    };
                                  } else if (
                                    params.value === row.predictedLoad
                                  ) {
                                    foundMatchingRow = true;
                                    return {
                                      ...row,
                                      updatePredictedLoad:
                                        row.updatePredictedLoad + Value,
                                      difference: row.difference + Value,
                                    };
                                  } else {
                                    return row;
                                  }
                                });
                              });
                            }
                          }}
                        />
                      );
                    },
                    width: 200,
                  },
                ],
              },
              {
                headerName: "CF",
                headerClass: "updatedLoadHeader",
                field: "difference",
                cellClass: "uploadCol",
                width: 130,
              },
              {
                headerName: "Updated Load",
                headerClass: "updatedLoadHeader",
                field: "updatePredictedLoad",
                cellClass: "uploadCol",
                width: 150,
              },
            ]);
            response = await instance.post(
              "/getData",
              `DATE=${datesarray.refDate}`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          }
          if (response.data && response.data.blocks) {
            const tableData = response.data.blocks.map((block, index) => ({
              timeStamp: timestamps[index]?.label, // Replace with actual timestamp field name
              predictedLoad: parseFloat(parseFloat(block.wdLoad).toFixed(2)), // Replace with actual predicted load field name
              predictedTemp: block.temp, // Replace with actual predicted temperature field name
              predictedHum: block.rh, // Replace with actual predicted humidity field name
              updatePredictedLoad: parseFloat(parseFloat(block.wdLoad).toFixed(2))  ,
              difference: 0,
            }));
            setRowData(tableData);
          }
        }
        if (datesarray.dates) {
          datesarray.dates.map(async (newDate) => {
            if (!addedDates.includes(newDate)) {
              setAddedDates((prevDates) => [...prevDates, newDate]);
              setColumnDefs((prevColumnDefs) => [
                ...prevColumnDefs,
                {
                  headerName: `Actual[${dayjs(newDate).format(
                    "YYYY/MM/DD"
                  )}]- Predictions `,
                  headerClass: "pADiffHeader",
                  children: [
                    {
                      headerName: "Temp",
                      headerClass: "pADiffTempCol",
                      field: `${newDate} diffTemp`,
                      cellClass: "pADiff",
                      width: 100,
                    },
                    {
                      headerName: "Humidity",
                      headerClass: "pADiffHumCol",
                      field: `${newDate} diffHum`,
                      cellClass: "pADiff",
                      width: 100,
                    },
                    {
                      headerName: "Load",
                      headerClass: "pADiffLoadCol",
                      field: `${newDate} diffLoad`,
                      cellClass: "pADiff",
                      width: 100,
                    },
                  ],
                },
              ]);
              const params = new URLSearchParams();
              params.append("DATE", newDate);
              const response = await instance.post("/getData", params, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              });

              if (response.data && response.data.blocks) {
                const newtableData = response.data.blocks.map(
                  (block, index) => ({
                    timeStamp: timestamps[index]?.label, // Replace with actual timestamp field name
                    [`${newDate} Load`]: block.wdLoad, // Replace with actual predicted load field name
                    [`${newDate} Temp`]: block.temp, // Replace with actual predicted temperature field name
                    [`${newDate} Hum`]: block.rh, // Replace with actual predicted humidity field name
                  })
                );
                setRowData((prevData) => {
                  return prevData.map((existingRow) => {
                    const newRow = newtableData.find(
                      (newRow) => newRow.timeStamp === existingRow.timeStamp
                    );
                    if (newRow) {
                      return {
                        ...existingRow,
                        [`${newDate} diffLoad`]: parseFloat(
                          (
                            newRow[`${newDate} Load`] -
                            existingRow.predictedLoad
                          ).toFixed(2)
                        ),
                        [`${newDate} diffHum`]: parseFloat(
                          (
                            newRow[`${newDate} Hum`] - existingRow.predictedHum
                          ).toFixed(2)
                        ),
                        [`${newDate} diffTemp`]: parseFloat(
                          (
                            newRow[`${newDate} Temp`] -
                            existingRow.predictedTemp
                          ).toFixed(2)
                        ),
                      };
                    } else {
                      return existingRow;
                    }
                  });
                });
              }
            }
          });
          const datesMissing = addedDates.filter((date) => {
            const parsedDate = dayjs(date, "YYYY/MM/DD");
            return !datesarray.dates.some((convertedDate) =>
              parsedDate.isSame(convertedDate, "day")
            );
          });
          if (datesMissing.length > 0) {
            setAddedDates((prevAddedDates) =>
              prevAddedDates.filter((date) => !datesMissing.includes(date))
            );
            setColumnDefs((prevColumnDefs) => {
              const columnsToRemove = [];
              const updatedColumnDefs = prevColumnDefs.filter((columnDef) => {
                if (
                  datesMissing.some((date) =>
                    columnDef.headerName.includes(
                      `Actual[${dayjs(date).format("YYYY/MM/DD")}]`
                    )
                  )
                ) {
                  columnsToRemove.push(columnDef);
                  return false;
                }
                return true;
              });
              return updatedColumnDefs;
            });
          }
        }
        if (datesarray.difference.length > 0) {
          datesarray.difference.map(async (actDate) => {
            if (!processedDates.includes(actDate.join(","))) {
              setProcessedDates((prevData) => [...prevData, actDate.join(",")]);
              let Date1 = actDate[0];
              let Date2 = actDate[1];
              setColumnDefs((prevColumnDefs) => [
                ...prevColumnDefs,
                {
                  headerName: `${dayjs(Date1).format(
                    "YYYY/MM/DD"
                  )} and  ${dayjs(Date2).format("YYYY/MM/DD")}`,
                  headerClass: "d-dMainHeader",
                  children: [
                    {
                      headerName: "Temperature",
                      headerClass: "diffTempHeader",
                      cellClass: "hello",
                      children: [
                        {
                          headerName: dayjs(Date1).format("MM/DD"),
                          headerClass: "tempD1Col",
                          field: `${Date1}Tem`,
                          cellClass: "diffTemp",
                          width: 100,
                        },
                        {
                          headerName: dayjs(Date2).format("MM/DD"),
                          headerClass: "tempD2Col",
                          field: `${Date2}Tem`,
                          cellClass: "diffTemp",
                          width: 100,
                        },
                        {
                          headerName: "Diff",
                          headerClass: "tempD1D2Col",
                          field: `${actDate}diffTemp`,
                          cellClass: "diffTemp",
                          width: 100,
                        },
                      ],
                    },
                    {
                      headerName: "Humidity",
                      headerClass: "diffHumHeader",
                      children: [
                        {
                          headerName: dayjs(Date1).format("MM/DD"),
                          headerClass: "humD1Col",
                          field: `${Date1}Hum`,
                          cellClass: "diffHum",
                          width: 100,
                        },
                        {
                          headerName: dayjs(Date2).format("MM/DD"),
                          headerClass: "humD2Col",
                          field: `${Date2}Hum`,
                          cellClass: "diffHum",
                          width: 100,
                        },
                        {
                          headerName: "Diff",
                          headerClass: "humD1D2Col",
                          field: `${actDate}diffHum`,
                          cellClass: "diffHum",
                          width: 100,
                        },
                      ],
                    },
                    {
                      headerName: "Load",
                      headerClass: "diffLoadHeader",
                      children: [
                        {
                          headerName: dayjs(Date1).format("YYYY/MM/DD"),
                          headerClass: "loadD1Col",
                          field: `${Date1}Load`,
                          cellClass: "diffLoad",
                          width: 120,
                        },
                        {
                          headerName: dayjs(Date2).format("YYYY/MM/DD"),
                          headerClass: "loadD2Col",
                          field: `${Date2}Load`,
                          cellClass: "diffLoad",
                          width: 120,
                        },
                        {
                          headerName: "Difference",
                          headerClass: "loadD1D2Col",
                          field: `${actDate}diffLoad`,
                          cellClass: "diffLoad",
                          width: 120,
                        },
                      ],
                    },
                  ],
                },
              ]);

              const params = new URLSearchParams();
              params.append("DATE", dayjs(Date1).format("YYYY-MM-DD"));

              const response = await instance.post("/getData", params, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              });

              const params1 = new URLSearchParams();
              params1.append("DATE", dayjs(Date2).format("YYYY-MM-DD"));
              params1.append("SPECIALDAY", dayType);

              const response1 = await instance.post("/getData", params1, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              });

              if (
                response.data &&
                response.data.blocks &&
                response1.data &&
                response1.data.blocks
              ) {
                const table1Data = response.data.blocks.map((block, index) => ({
                  timeStamp: timestamps[index]?.label,
                  [`${Date1}Load`]: block.wdLoad,
                  [`${Date1}Tem`]: block.temp,
                  [`${Date1}Hum`]: block.rh,
                }));
                const table2Data = response1.data.blocks.map(
                  (block, index) => ({
                    timeStamp: timestamps[index]?.label,
                    [`${Date2}Load`]: block.wdLoad,
                    [`${Date2}Tem`]: block.temp,
                    [`${Date2}Hum`]: block.rh,
                  })
                );
                setRowData((prevData) => {
                  return prevData.map((existingRow) => {
                    const newRow1 = table1Data.find(
                      (newRow) => newRow.timeStamp === existingRow.timeStamp
                    );
                    const newRow2 = table2Data.find(
                      (newRow) => newRow.timeStamp === existingRow.timeStamp
                    );
                    if (newRow1 && newRow2) {
                      return {
                        ...existingRow,
                        ...newRow1,
                        ...newRow2,
                        [`${actDate}diffLoad`]: parseFloat(
                          (
                            newRow1[`${Date1}Load`] - newRow2[`${Date2}Load`]
                          ).toFixed(2)
                        ),
                        [`${actDate}diffTemp`]: parseFloat(
                          (
                            newRow1[`${Date1}Tem`] - newRow2[`${Date2}Tem`]
                          ).toFixed(2)
                        ),
                        [`${actDate}diffHum`]: parseFloat(
                          (
                            newRow1[`${Date1}Hum`] - newRow2[`${Date2}Hum`]
                          ).toFixed(2)
                        ),
                      };
                    } else {
                      return existingRow;
                    }
                  });
                });
              }
            }
          });
        }
      } catch (error) {
        toast.error();
      }
    };
    fetchData();
  }, [datesarray]);

  return (
    <div className={gridTheme} style={{ width: "100%" }}>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={isDarkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"}
        style={{
          height: "47.188rem",
          width: "98%",
          marginTop: "1.875rem",
          backgroundColor: colors.primary.main,
          padding: "1.25rem",
          borderRadius: "24px",
          marginBottom: "1.25rem",
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontFamily: "Poppins,sans-serif",
                fontSize: "1.5rem",
                fontWeight: "400",
                display: "flex",
              }}
            >
              Statistical Table
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Grid container>
              <Grid item>
                <Button
                  sx={{
                    color: colors.primary.dark,
                    fontFamily: "Poppins,sans-serif",
                    textTransform: "none",
                    width: "2.5rem",
                    height: "2.5rem",
                    minWidth: "0.625rem",
                    // padding: "1rem 2rem",
                    borderRadius: "12px",
                    background: colors.grey[200],
                    // mr: "10px",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.125rem",
                    textAlign: "center",
                    "&:hover": {
                      color: colors.primary.dark,
                      background: colors.grey[200],
                    },
                    "& .css-d635f9-MuiButtonBase-root-MuiButton-root": {
                      webkitTapHighlightColor: colors.primary["black"],
                    },
                  }}
                  onClick={downloadExcelFile}
                >
                  <DownloadIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} sx={{ height: "41.25rem" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={{ sortable: true, resizable: true }}
            pagination={true}
            paginationPageSize={12}
            suppressRowHoverHighlight={suppressRowHoverHighlight}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default StatisticalTable;

const timestamps = [
  { label: "00:00", value: 0 },
  { label: "00:15", value: 1 },
  { label: "00:30", value: 2 },
  { label: "00:45", value: 3 },
  { label: "01:00", value: 4 },
  { label: "01:15", value: 5 },
  { label: "01:30", value: 6 },
  { label: "01:45", value: 7 },
  { label: "02:00", value: 8 },
  { label: "02:15", value: 9 },
  { label: "02:30", value: 10 },
  { label: "02:45", value: 11 },
  { label: "03:00", value: 12 },
  { label: "03:15", value: 13 },
  { label: "03:30", value: 14 },
  { label: "03:45", value: 15 },
  { label: "04:00", value: 16 },
  { label: "04:15", value: 17 },
  { label: "04:30", value: 18 },
  { label: "04:45", value: 19 },
  { label: "05:00", value: 20 },
  { label: "05:15", value: 21 },
  { label: "05:30", value: 22 },
  { label: "05:45", value: 23 },
  { label: "06:00", value: 24 },
  { label: "06:15", value: 25 },
  { label: "06:30", value: 26 },
  { label: "06:45", value: 27 },
  { label: "07:00", value: 28 },
  { label: "07:15", value: 29 },
  { label: "07:30", value: 30 },
  { label: "07:45", value: 31 },
  { label: "08:00", value: 32 },
  { label: "08:15", value: 33 },
  { label: "08:30", value: 34 },
  { label: "08:45", value: 35 },
  { label: "09:00", value: 36 },
  { label: "09:15", value: 37 },
  { label: "09:30", value: 38 },
  { label: "09:45", value: 39 },
  { label: "10:00", value: 40 },
  { label: "10:15", value: 41 },
  { label: "10:30", value: 42 },
  { label: "10:45", value: 43 },
  { label: "11:00", value: 44 },
  { label: "11:15", value: 45 },
  { label: "11:30", value: 46 },
  { label: "11:45", value: 47 },
  { label: "12:00", value: 48 },
  { label: "12:15", value: 49 },
  { label: "12:30", value: 50 },
  { label: "12:45", value: 51 },
  { label: "13:00", value: 52 },
  { label: "13:15", value: 53 },
  { label: "13:30", value: 54 },
  { label: "13:45", value: 55 },
  { label: "14:00", value: 56 },
  { label: "14:15", value: 57 },
  { label: "14:30", value: 58 },
  { label: "14:45", value: 59 },
  { label: "15:00", value: 60 },
  { label: "15:15", value: 61 },
  { label: "15:30", value: 62 },
  { label: "15:45", value: 63 },
  { label: "16:00", value: 64 },
  { label: "16:15", value: 65 },
  { label: "16:30", value: 66 },
  { label: "16:45", value: 67 },
  { label: "17:00", value: 68 },
  { label: "17:15", value: 69 },
  { label: "17:30", value: 70 },
  { label: "17:45", value: 71 },
  { label: "18:00", value: 72 },
  { label: "18:15", value: 73 },
  { label: "18:30", value: 74 },
  { label: "18:45", value: 75 },
  { label: "19:00", value: 76 },
  { label: "19:15", value: 77 },
  { label: "19:30", value: 78 },
  { label: "19:45", value: 79 },
  { label: "20:00", value: 80 },
  { label: "20:15", value: 81 },
  { label: "20:30", value: 82 },
  { label: "20:45", value: 83 },
  { label: "21:00", value: 84 },
  { label: "21:15", value: 85 },
  { label: "21:30", value: 86 },
  { label: "21:45", value: 87 },
  { label: "22:00", value: 88 },
  { label: "22:15", value: 89 },
  { label: "22:30", value: 90 },
  { label: "22:45", value: 91 },
  { label: "23:00", value: 92 },
  { label: "23:15", value: 93 },
  { label: "23:30", value: 94 },
  { label: "23:45", value: 95 },
];
