import React, { useState, useEffect } from 'react';
import { ResponsiveCalendar } from '@nivo/calendar';
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import instance from '../middleware';
import { toast } from 'react-toastify';
import Spinner from './Spinner';

const WeatherCalendar = ({ selectedYear, setCountData, filterActive, setDatee }) => {
    const [calendarData, setCalendarData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [colorScale, setColorScale] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colortheme = theme.palette.mode;
    const togglecolour = ['red', colors.primary.ecolor];
    const [firstZeroDate, setFirstZeroDate] = useState(null);

    const fetchData = async () => {
        try {
            const response = await instance.post(
                "/getLoadHeatMap",
                `YEAR=${selectedYear}`,
            );
            const filteredData = response.data.data.filter(item => item.date.startsWith(selectedYear));
            const newFilterData = filteredData.map((item) => {
                return {
                    "value": parseInt(item.count),
                    "day": item.date,
                    "name": item.name,
                    "email": item.email
                }
            });

            function isLeapYear(year) {
                return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
            }

            const daysInYear = isLeapYear(selectedYear) ? 366 : 365;

            const initializedData = Array.from({ length: daysInYear }, (_, index) => {
                const dayOfYear = new Date(selectedYear, 0, index + 2);
                const dayString = dayOfYear.toISOString().split('T')[0]; // Format: YYYY-MM-DD
                return {
                    value: 2,
                    day: dayString,
                    name: "",
                    email: "",
                    colorscale: colors.primary.ecolor
                };
            });

            newFilterData.forEach(item => {
                const dayIndex = Math.floor((new Date(item.day) - new Date(selectedYear, 0, 1)) / (1000 * 60 * 60 * 24)); // Calculate index of day in the year
                initializedData[dayIndex] = {
                    value: item.value,
                    day: item.day,
                    name: item.name,
                    email: item.email,
                    colorscale: item.value === 1 ? colors.primary.fillcolor : "#ff0000"
                };
            });

            setCountData(initializedData);
            setCalendarData(initializedData);
            if (firstZeroDate === null) {
                setFirstZeroDate(initializedData.find(item => item.value === 0)?.day);
                setDatee(initializedData.find(item => item.value === 0)?.day);
            }
            setLoading(false);
        } catch (error) {
            toast.error("Internal Server Error");
            setCalendarData([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedYear]);

    useEffect(() => {
        const colorValuePairs = calendarData.map(item => ({ color: item.colorscale, value: item.value }));
        const color = [...new Set(colorValuePairs.map(pair => pair.color))];
        color.sort((color1, color2) => {
            const value1 = colorValuePairs.find(pair => pair.color === color1).value;
            const value2 = colorValuePairs.find(pair => pair.color === color2).value;
            return value1 - value2;
        });
        if (color.length === 1 && JSON.stringify(color) === JSON.stringify(['#ABFFBE']) )  
        {
            setColorScale([colors.primary.fillcolor]);
        }
        if (color.length === 1 && JSON.stringify(color) === JSON.stringify(['#65ff89']) )   
        {
            setColorScale([colors.primary.fillcolor]);
        }
        if (color.length === 1 && JSON.stringify(color) === JSON.stringify(['#FF0000']) )   
        {
            setColorScale(["#FF0000"]);
        }
        if (color.length===3 || color.length===2)
        {
            const defaultcolor=["#ff0000",colors.primary.fillcolor,colors.primary.ecolor]
            setColorScale(defaultcolor);
        }
    }, [calendarData, colortheme]);
    useEffect(() => {
        const handleStorageChange = () => {
            const message= localStorage.getItem("uploadStatus1");
            
            if (message == "Data uploaded successfully") {
                // console.log("uploadStatus changed:", message);
                fetchData(); 
            }
        };

        window.addEventListener("successMessage1", handleStorageChange);

        return () => {
            window.removeEventListener("successMessage1", handleStorageChange);
        };
    }, []);

    
    if (loading) {
        return <div style={{ marginTop: '2rem',marginLeft: "45%" }}><Spinner /></div>;
    }

    if (calendarData.length === 0) {
        return <div style={{ marginLeft: "0.9rem" }}>No data available for selected year</div>;
    }

    const year = parseInt(selectedYear);
    const daysInYear = ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) ? 366 : 365;

    const allDates = Array.from({ length: daysInYear }, (_, i) =>
        new Date(year, 0, i + 2).toISOString().slice(0, 10)
    );

    return (
        <ResponsiveCalendar
        data={calendarData}
        from={`${selectedYear}-01-01`}
        to={`${selectedYear}-12-31`}
        emptyColor={colors.primary.ecolor}
        colors={filterActive ? colorScale : togglecolour}
        margin={{ right: 20, bottom: 0, left: 35, top: 30 }}
        yearSpacing={40}
        monthBorderColor={colors.primary.main}
        dayBorderWidth={2}
        dayBorderColor={colors.primary.main}
        theme={{
            labels: {
                text: {
                    fill: colors.primary.dark,
                },
            },
        }}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 36,
                itemCount: 4,
                itemWidth: 42,
                itemHeight: 36,
                itemsSpacing: 14,
                itemDirection: 'right-to-left',
            }
        ]}
        tooltip={(tooltip) => {
            return (
                <div
                    style={{
                        background: colors.primary.main,
                        border: '1px solid #ccc',
                        padding: '0.625rem',
                        borderRadius: '0.313rem',
                        fontSize: '0.875rem',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    <Typography sx={{ fontFamily: 'Poppins, sans-serif' }}>
                        Date: {tooltip.day} <br />
                        {tooltip.value === '1' ? 'Data is Available' : 'Data is Unavailable'} <br />
                        Name: {tooltip.data.name} <br />
                        Email: {tooltip.data.email}
                    </Typography>
                </div>
            );
        }}
    />
    );
};

export default WeatherCalendar;
