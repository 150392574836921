import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";

import BarChart from "../../Components/Bar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Thermostat from "@mui/icons-material/Thermostat";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import "./dashboard.css";
import dayjs from "dayjs";
import instance from "../../middleware.js";

const Dashboard2 = ({ }) => {
  dayjs.locale("en");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colors1 = [colors.linechart[980]];
  const colors2 = [colors.orange[100]];
  const colors3 = [colors.purple[200]];
  const [data, setData] = useState({ getData: null, predictLoad: null });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(10, "day"));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());
  const [selectedAvgStartDate1, setselectedAvgStartDate1] = useState(dayjs("2019-01-01"));
  const [selectedAvgEndDate1, setSelectedAvgEndDate1] = useState(dayjs().subtract(1, "day"));
  const [maeData, setMaeData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().add(1, "day"));
  const [maerange, setMaerange] = useState();
  const [maerange1, setMaerange1] = useState();
  const [ymape, setYmape] = useState({
    yesterdmape: null,
    monthmape: null,
    quarterlymape: null,
    yearmape: null
  });
  const [averageData1, setAverageData1] = useState({
    averageLoad1: null,
    averageTemperature1: null,
    averageHumidity1: null,
  });
  const [selectedprevEndDate, setSelectedPrevEndDate] = useState(dayjs());
  const [selectedprevStartDate, setPrevSelectedStartDate] = useState(dayjs().subtract(10, "day"));
  const chartRef = useRef(null);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    const differenceInDays = selectedEndDate.diff(date, "day");
    if (differenceInDays > 95) {
      toast.error("Maximum difference allowed is 95 days");
    } else if (differenceInDays < 5) {
      toast.error("Minimum difference allowed is 5 days");
    }
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    const differenceInDays = date.diff(selectedStartDate, "day");
    if (differenceInDays > 95) {
      toast.error("Maximum difference allowed is 95 days");
    } else if (differenceInDays < 5) {
      toast.error("Minimum difference allowed is 5 days");
    }
  };
  useEffect(() => {
    handleFetchAverage();
    // toast.success("Averages loaded successfully!");
  }
    , [])

  const handleStartDateChange1 = (date) => {
    setselectedAvgStartDate1(date);
  };
  const handleEndDateChange1 = (date) => {
    setSelectedAvgEndDate1(date);
  };

  //   setIsLoading(true);

  //   try {
  //     if (selectedYear) {
  //       var n = 1; // Define the value of 'n' here

  //       const response = await instance.get(
  //         `/getMAE/${n}`, // Include 'n' in the URL
  //         {
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/x-www-form-urlencoded",
  //           },
  //         }
  //       );
  //       console.log(response.data, "response2");
  //       const mae2 = response.data.maes;
  //       console.log("mae2", mae2);
  //       const mae3 = mae2.mae_wdLoad;
  //       setMae((prevState) => ({
  //         ...prevState,
  //         mae3: mae, // Load ka data
  //       }));


  //       const maesForYear = response.data.maes.filter((mae) => {
  //         return dayjs(mae.date).year() === selectedYear;
  //       });

  //       const wdLoads = maesForYear.map((mae) => parseFloat(mae.mae_wdLoad));

  //       const maxWdLoad = Math.max(...wdLoads);
  //       const minWdLoad = Math.min(...wdLoads);

  //       // Update maxMinMAE state with max and min wdLoad values for the selected year
  //       setMaxMinMAE({ max: maxWdLoad, min: minWdLoad });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching MAE:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleFetchAverage = async () => {
    const daysInput = document.getElementById("daysInput").value;
    if (daysInput >= 0) {
      try {
        const response1 = await instance.post(
          "/getAvgData",
          {
            n: parseInt(daysInput),
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );



        const averages = response1.data.average_stats;

        const averageLoad1 = averages.wdLoad;
        const averageTemperature1 = averages.temp;
        const averageHumidity1 = averages.rh;


        setAverageData1((prevState) => ({
          ...prevState,
          averageLoad1: averageLoad1,
          averageHumidity1: averageHumidity1,
          averageTemperature1: averageTemperature1,
        }));



        // Close the error message after 30 milliseconds
        toast.success(`Averages for ${daysInput} days`)
      } catch (error) {
        toast.error("Error fetching averages:", error.message);
        // toast.error("Failed To load Response")
        setTimeout(() => {
          toast.dismiss();
        }, 1500);
        // Handle error state if needed
      }
    }
    else {
      toast.error("Input must be positive");

      // Close the error message after 30 milliseconds
      setTimeout(() => {
        toast.dismiss();
      }, 1500);

    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedStartDate || !selectedEndDate || selectedEndDate.diff(selectedStartDate, 'day') < 5 || selectedEndDate.diff(selectedStartDate, 'day') > 95) {
          setSelectedStartDate(selectedprevStartDate);
          setSelectedEndDate(selectedprevEndDate);
          
          return;
        }
        setPrevSelectedStartDate(selectedStartDate);
        setSelectedPrevEndDate(selectedEndDate);
        const from = selectedStartDate.format("YYYY-MM-DD");
        const to = selectedEndDate.format("YYYY-MM-DD");

        const response = await instance.post(
          "/getMAERange",
          `FROM=${from}&TO=${to}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const avg123 = response.data.average_maes.mae_wdLoad;
        setMaerange(avg123);
        const roundedMaeData = response.data.maes.map((item) => ({
          ...item,
          mae_wdLoad: Number(item.mae_wdLoad).toFixed(3),
        }));
        setMaeData(roundedMaeData);
      } catch (error) {

        toast.error("Internal Server Error");


        setTimeout(() => {
          toast.dismiss();
        }, 1500);
      }
    };
    fetchData();
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const from = selectedAvgStartDate1.format("2024-04-08");
        const to = dayjs().subtract(1, 'day').format("YYYY-MM-DD");

        const response = await instance.post(
          "/getMAERange",
          `FROM=${from}&TO=${to}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",

            },
          }
        );

        const avg1234 = response.data.average_maes.mae_wdLoad

        setMaerange1(avg1234);
      } catch (error) {
        toast.error(
          "No Data available",

        );
      }
    };

    fetchData1();
  }, []);

  const handleDateChange = (dayjsDate) => {
    setSelectedDate(dayjsDate);
  };

  // For Future Us
  // const styles = {
  //   slide: {
  //     padding: 15,
  //     minHeight: 282,
  //     color: "black",
  //   },
  //   slide1: {
  //     background: "",
  //   },
  //   slide2: {
  //     background: "#B3DC4A",
  //   },
  //   slide3: {
  //     background: "#6AC0FF",
  //   },
  // };
  useEffect(() => {
    const fetchdata = async () => {
      const response = await instance.get('/mape_data');

      if (response.data) {
        try {
          const apnadata = response.data;


          const yearmape = apnadata.yearly_mean;
          const monthmape = apnadata.monthly_mean;
          const quarterlymape = apnadata.quarterly_mean;
          let yesterdmape = null;
          if (apnadata.yesterday_mean !== null) {
            if (apnadata.yesterday_mean[0]) {

              yesterdmape = (parseFloat(apnadata.yesterday_mean[0].mae_wdLoad)).toFixed(2);
            }
          }
          setYmape({
            yesterdmape: yesterdmape,
            yearmape: yearmape,
            quarterlymape: quarterlymape,
            monthmape: monthmape,
          });
        } catch (error) {
          toast.error("Internal Server Error");
        }
      }
    }
    fetchdata();
  }, []);

  return (
    <div style={{ 
      // marginLeft: "7px" 
      }}>
      <Grid xs={12} sm={12} md={12} lg={12}
        sx={{
          // display: "flex",
          // flexDirection: "row",
          borderRadius: "20px",
          // width: "75rem",
          width: "98%",
          // alignItems: "center",
          marginTop: "1.875rem",
          position: "relative",
          // top: 0,
          zIndex: 0,
          // height: "13rem",
          backgroundColor: colors.primary.main,
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
          padding: "30px",
          paddingTop: "20px"
        }}
      >
        <Grid container>
          <Grid item
          // sx={{ marginTop: '1.3rem' }}
          >
            <Typography sx={{
              fontFamily: 'Poppins,sans-serif',
              fontSize: '1.6rem',
              fontWeight: 600,
              // lineHeight: "34.66px",
              // textAlign: 'left',
              color: colors.primary.dark,
              // marginLeft: '1.25rem',
              // ml:'2.1rem'

            }}
            >
              Load - MAPE Stats
            </Typography>
          </Grid>

          <Grid container columnSpacing={3.5} sx={{ marginTop: '1.2rem' }}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box
                sx={{
                  backgroundColor: colors.grey[480],
                  paddingX: "1rem",
                  paddingY: "1.3rem",
                  borderRadius: "20px",
                  height: '6rem',
                  // width: '16.5rem',
                  // ml: '1.8rem'
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2} sm={2} md={2} lg={2}
                    sx={{
                      marginRight: "0.625rem",
                      marginTop: "0.438rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "2.2rem",
                        height: "2.2rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        backgroundColor: colors.grey[800],
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          marginLeft: "0.4rem",
                          height: "2rem",
                          marginTop: "0.125rem",
                          marginBottom: "0.125rem",
                          color: colors.primary.main,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item 
                  xs={9} sm={9} md={9} lg={9}
                  sx={{ marginTop: '0.5rem' }}>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "1.063rem",
                      fontWeight: 500,
                      lineHeight: "1.225rem",
                      color: colors.grey[900]
                    }}>
                      {ymape.yesterdmape !== null ? `${ymape.yesterdmape}` : 'N/A'} %

                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "0.938rem",
                      fontWeight: 400,
                      lineHeight: "1.225rem",
                      color: colors.grey[680]
                    }}>Yesterday's Load - MAPE</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box
                sx={{
                  backgroundColor: colors.grey[480],
                  paddingX: "1rem",
                  paddingY: "1.3rem",
                  borderRadius: "20px",
                  height: '6rem',
                  // width: '16.5rem',
                  // ml: '1.8rem'


                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2} sm={2} md={2} lg={2}
                    sx={{
                      marginRight: "0.625rem",
                      marginTop: "0.438rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "2.2rem",
                        height: "2.2rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        backgroundColor: colors.grey[800],
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          marginLeft: "0.4rem",
                          height: "2rem",
                          marginTop: "0.125rem",
                          marginBottom: "0.125rem",
                          color: colors.primary.main,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} sx={{ marginTop: '0.5rem' }}>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "1.063rem",
                      fontWeight: 500,
                      lineHeight: "1.225rem",
                      color: colors.grey[900]
                    }}>
                      {ymape.monthmape ? ymape.monthmape : 'N/A'} %
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "0.938rem",
                      fontWeight: 400,
                      lineHeight: "1.225rem",
                      color: colors.grey[680]
                    }}>Monthly Load - MAPE</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box
                sx={{
                  backgroundColor: colors.grey[480],
                  paddingX: "1rem",
                  paddingY: "1.3rem",
                  borderRadius: "20px",
                  height: '6rem',
                  // width: '16.5rem',
                  // mx: '1.8rem'


                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2} sm={2} md={2} lg={2}
                    sx={{
                      marginRight: "0.625rem",
                      marginTop: "0.438rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "2.2rem",
                        height: "2.2rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        backgroundColor: colors.grey[800],
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          marginLeft: "0.4rem",
                          height: "2rem",
                          marginTop: "0.125rem",
                          marginBottom: "0.125rem",
                          color: colors.primary.main,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} sx={{ marginTop: '0.5rem' }}>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "1.063rem",
                      fontWeight: 500,
                      lineHeight: "1.225rem",
                      color: colors.grey[900]
                    }}>
                      {ymape.quarterlymape ? ymape.quarterlymape : 'N/A'} %
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "0.938rem",
                      fontWeight: 400,
                      lineHeight: "1.225rem",
                      color: colors.grey[680]
                    }}>Quarterly Load - MAPE</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Box
                sx={{
                  backgroundColor: colors.grey[480],
                  paddingX: "1rem",
                  paddingY: "1.3rem",
                  borderRadius: "20px",
                  height: '6rem',
                  // width: '16.5rem',
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2} sm={2} md={2} lg={2}
                    sx={{
                      marginRight: "0.625rem",
                      marginTop: "0.438rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "2.2rem",
                        height: "2.2rem",
                        borderRadius: "8px",
                        marginBottom: "0.625rem",
                        backgroundColor: colors.grey[800],
                      }}
                    >
                      <FlashOnIcon
                        sx={{
                          marginLeft: "0.4rem",
                          height: "2rem",
                          marginTop: "0.125rem",
                          marginBottom: "0.125rem",
                          color: colors.primary.main,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} sx={{ marginTop: '0.5rem' }}>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "1.063rem",
                      fontWeight: 500,
                      lineHeight: "1.225rem",
                      color: colors.grey[900]
                    }}>
                      {ymape.yearmape ? ymape.yearmape : 'N/A'} %
                    </Typography>
                    <Typography sx={{
                      fontFamily: 'Poppins,sans-serif',
                      fontSize: "0.938rem",
                      fontWeight: 400,
                      lineHeight: "1.225rem",
                      color: colors.grey[680]
                    }}>Yearly Load - MAPE</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12}
        id="meanabs"
        sx={{
          background: colors.primary["main"],
          boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
        }}
      >
        <Grid
          container
          sx={{
            paddingTop: "1.25rem",
            marginBottom: "1.25rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Typography
              id="meanerror"
              variant="h3"
              fontWeight="bold"
              sx={{
                color: colors.grey[900],
              }}
            >
              Mean Absolute % Errors
            </Typography>
          </Grid>

          <Grid item sx={{ display: "flex", marginRight: "1.25rem" }}>
            <Grid container>
              <Grid item sx={{ marginTop: "0.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    id="localization"
                    sx={{
                      backgroundColor: colors.grey[200],
                    }}
                  >
                    <FormControl id="form1" fullWidth>
                      <InputLabel
                        id="startdate"
                        sx={{
                          color: colors.grey[800],
                        }}
                      >
                        Start Date:
                      </InputLabel>

                      <DatePicker
                        maxDate={selectedEndDate.subtract(5, "day")}
                        format="YYYY/MM/DD"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        id="input-container"
                        sx={{
                          fontSize: '0.875rem !important',
                          borderRadius: "1.563rem !important",
                          width: "10.625rem !important",
                          border: "none !important",
                          marginLeft: "auto !important",
                          "& .MuiInputLabel-root": {
                            display: "none !important",
                          },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-1uvydh2,.css-putrnr":
                          {
                            height: "0.938rem !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "transparent !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent !important",
                            },
                          },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            fontSize: "0.875rem !important",
                          },

                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-1uvydh2,.css-putrnr":
                          {
                            fontSize: "0.875rem !important",
                            padding: "0rem !important",
                            marginLeft: "2.375rem !important",
                            paddingRight: "0rem ! important",
                            width: "60% !important",
                            marginTop: "1rem !important",
                          },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            marginLeft: "1.875rem !important",
                            fontSize: "0.875rem !important",
                          },
                          "& .css-hli2kd-MuiButtonBase-root-MuiIconButton-root":
                          {
                            padding: "0rem !important",
                          },
                          // "& .css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root":
                          // {
                          //   // marginRight:'0rem !important',
                          // },
                          "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, .css-slyssw,.css-19f3993":
                          {
                            padding: "0rem !important",
                            marginTop: "0.875rem !important",
                          },
                        }}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            style={{
                              height: ".1em !important",
                              border: "none !important",
                              width: "10% !important",
                            }}
                          />
                        )}
                        slotProps={{
                          popper: {
                            sx: {
                              ".MuiPaper-root": {
                                //  border: "1px solid blue",
                                borderRadius: "4px !important",

                              },
                              " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                                boxShadow: "none !important",

                              },
                              'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected': {
                                borderRadius: '12px !important',
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main
                              },
                              // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                              //   borderRadius: '12px !important',
                              //   backgroundColor: colors.primary.black,
                              //   color: colors.primary.main

                              // },
                              ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                                border: 'none !important'
                              },
                              ' .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                borderRadius: '12px !important',
                                // background: colors.primary.light
                              },
                              ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                                borderRadius: "10px !important",
                              },
                              '.css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                                borderRadius: '12px',
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                              },
                              '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                borderRadius: '12px !important',
                                backgroundColor: `${colors.grey[200]} !important`
                              },
                              '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover': {
                                borderRadius: '12px !important',
                                backgroundColor: colors.grey[200]
                              },
                              '.css-13urpcw-MuiButtonBase-root-MuiPickersDay-root': {
                                borderRadius: '12px !important'
                              },
                              ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                                borderRadius: "10px !important",
                              },

                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item sx={{ marginTop: "0.5rem" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    id="adapter"
                    sx={{
                      backgroundColor: colors.grey[200],
                    }}
                  >
                    <FormControl fullWidth id="fullest">
                      <InputLabel
                        id="endate"
                        sx={{
                          color: colors.grey[800],
                        }}
                      >
                        End Date:
                      </InputLabel>

                      <DatePicker
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        maxDate={dayjs()}
                        format="YYYY/MM/DD"
                        sx={{
                          borderRadius: "1.563rem !important",
                          width: "10.625rem !important", // Adjust as needed
                          border: "none !important",

                          marginLeft: "auto !important", // Push to the right
                          "& .MuiInputLabel-root": {
                            display: "none !important", // Hide the default label
                          },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-1uvydh2,.css-putrnr":
                          {
                            height: "0.938rem !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "transparent !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent !important",
                            },
                          },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            fontSize: "0.875rem !important",
                          },
                          "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-1uvydh2,.css-putrnr":
                          {
                            fontSize: "0.875rem !important",
                            padding: "0rem !important",
                            marginLeft: "2.375rem !important",
                            paddingRight: "0rem ! important",
                            width: "60% !important",
                            marginTop: "1rem !important",
                          },
                          "& .css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            marginLeft: "1.875rem !important",
                            fontSize: "0.875rem !important",
                          },
                          "& .css-hli2kd-MuiButtonBase-root-MuiIconButton-root":
                          {
                            padding: "0rem !important",
                          },
                          // "& .css-1k3ibsw-MuiButtonBase-root-MuiIconButton-root":
                          // {
                          //   // marginRight:'0rem !important',
                          // },
                          "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root,.css-slyssw,.css-19f3993"://ye bhi lagega
                          {
                            padding: "0rem !important",
                            marginTop: "0.875rem !important",
                          },
                        }}
                        slotProps={{
                          popper: {
                            sx: {
                              ".MuiPaper-root": {
                                //  border: "1px solid blue",
                                borderRadius: "4px !important",

                              },
                              " .css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper": {
                                boxShadow: "none !important",

                              },
                              'button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-wwzkpf-MuiButtonBase-root-MuiPickersDay-root,.css-abufwq.Mui-selected,.css-z0jd0s.Mui-selected': {
                                borderRadius: '12px !important',
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main
                              },
                              // ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                              //   borderRadius: '12px !important',
                              //   backgroundColor: colors.primary.black,
                              //   color: colors.primary.main

                              // },
                              ' .css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                                border: 'none !important'
                              },
                              ' .css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                borderRadius: '12px !important',
                                // background: colors.primary.light
                              },
                              ".css-1vhkp4d-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                                borderRadius: "10px !important",
                              },
                              '.css-10h2jil-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected),.css-rnofp4.Mui-selected,.css-1h9azwu.Mui-selected': {
                                borderRadius: '12px',
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                              },
                              '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
                                borderRadius: '12px !important',
                                backgroundColor: `${colors.grey[200]} !important`
                              },
                              '.css-z34i7h-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover': {
                                borderRadius: '12px !important',
                                backgroundColor: colors.grey[200]
                              },
                              '.css-13urpcw-MuiButtonBase-root-MuiPickersDay-root': {
                                borderRadius: '12px !important'
                              },
                              ".css-tn4x24-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                backgroundColor: colors.primary.black,
                                color: colors.primary.main,
                                borderRadius: "10px !important",
                              },

                            },
                          },
                        }}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            style={{
                              height: ".1em !important",
                              border: "none !important",
                              width: "10% !important",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item >
                <Box
                  sx={{
                    backgroundColor: colors.red[100],
                    paddingX: "0.938rem !important",
                    paddingY: "0.625rem !important",
                    borderRadius: "16px !important",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        marginRight: "0.625rem",
                        marginTop: "0.438rem",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.875rem",
                          height: "1.875rem",
                          borderRadius: "8px",
                          marginBottom: "0.625rem",
                          backgroundColor: colors.grey[800],
                        }}
                      >
                        <FlashOnIcon
                          sx={{
                            marginLeft: "0.2rem",
                            height: "1.563rem",
                            marginTop: "0.125rem",
                            marginBottom: "0.125rem",
                            color: colors.primary.main,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {maerange} %
                      </Typography>
                      <Typography>Avg. Load MAPE</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{}}>
              <Box
                sx={{
                  height: "25rem",
                  // width: "24.375rem",
                  ml: "1rem"
                }}
              >
                <BarChart
                  maeData={maeData}
                  customColors={colors1}
                  keys={["mae_wdLoad"]}
                  isDashboard={true}
                  margins={{ top: 40, right: 30, bottom: 70, left: 40 }}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box
                sx={{
                  height: "25rem",
                  // width: "24.375rem",
                }}
              >
                <BarChart
                  maeData={maeData}
                  customColors={colors2}
                  keys={["mae_temp"]}
                  isDashboard={true}
                  margins={{ top: 40, right: 30, bottom: 70, left: 40 }}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box
                sx={{
                  height: "25rem",
                  marginBottom: "0px"
                  // width: "24.375rem",
                }}
              >
                <BarChart
                  maeData={maeData}
                  keys={["mae_rh"]}
                  customColors={colors3}
                  isDashboard={true}
                  margins={{ top: 40, right: 30, bottom: 70, left: 40 }}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box>
        <Grid container xs={12} sm={12} md={12} lg={12} sx={{ width: "98%"}}>
          <Grid item xs={12} sm={12} md={7} lg={7} >
            <Box
              sx={{
                // width: "43.75rem",
                // height: "12.125rem",
                padding: "1.5rem 0rem 1.5rem 0rem",
                borderRadius: "24px",
                boxShadow: "0rem 0.625rem 2.5rem 0rem #DEE6ED66",
                backgroundColor: colors.primary["main"],
                marginTop: "1.5rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
                marginBottom: "1.125rem",
                // marginRight: "1.5rem"
              }}
            >
              <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins,sans-serif",
                      fontWeight: 600,
                      // lineHeight:'34.66px',
                      color: colors.grey[900],
                      fontSize: "1.375rem",
                      marginLeft: "1.5rem",
                    }}
                  >
                    Avg. Stats for Past <input
                      type="number"
                      id="daysInput"
                      defaultValue={7}
                      style={{
                        width: "3.325rem",
                        height: "1.875rem",
                        backgroundColor: colors.grey[450],
                        borderRadius: "5px",
                        color: colors.primary.dark,
                        border: "0px solid grey",
                        textAlign: 'center'
                      }}
                    /> Days
                  </Typography>
                </Grid>
                <Grid item sx={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    onClick={handleFetchAverage}
                    sx={{
                      textTransform: 'none',
                      marginRight: "20px",
                      color: colors.primary.main,
                      backgroundColor: colors.primary.black,
                      borderRadius: "10px"
                      // width: "100px",
                      // height: "50px",
                      // fontSize: "5px"
                    }}
                  >
                    Get Average
                  </Button>
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}
                sx={{
                  marginTop: "1.5rem",
                  mathStyle: "1.5rem",
                  paddingLeft: "20px",
                  paddingRight: "20px"
                  // marginLeft: '1.563rem' 
                }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    sx={{
                      paddingLeft: "0.938rem",
                      paddingTop: "0.625rem",
                      borderRadius: "16px",
                      // width: "12.5rem",
                      height: "5rem",
                      background: colors.grey[480],
                      boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[300]}`,
                    }}
                  >
                    <Grid container sx={{}}>
                      <Grid
                        item
                        xs={3} sm={3} md={3} lg={3}
                        sx={{
                          marginRight: "0.625rem",
                          marginTop: "0.438rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2rem",
                            height: "2.1rem",
                            borderRadius: "8px",
                            marginBottom: "0.625rem",
                            backgroundColor: colors.grey[800],
                            marginTop: "0.375rem",
                            marginLeft: "0.375rem",
                            marginRight: "0.375rem",
                          }}
                        >
                          <FlashOnIcon
                            sx={{
                              marginLeft: "0.2rem",
                              height: "1.8rem",
                              marginTop: "0.125rem",
                              marginBottom: "0.125rem",
                              color: colors.primary.main,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography
                          sx={{
                            color: colors.primary["black"],
                            fontSize: "1.125rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {averageData1.averageLoad1 !== null &&
                            `${averageData1.averageLoad1.toFixed(2)}`} MW
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.938rem", color: colors.grey[680] }}
                        >
                          Avg. Load
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    sx={{
                      paddingLeft: "0.938rem",
                      paddingTop: "0.625rem",
                      // justifyContent: 'center',
                      borderRadius: "16px",
                      // width: "12.5rem",
                      // ml: "1.5rem",
                      height: "5rem",
                      background: colors.orange[100],
                      boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[400]}`,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={3} sm={3} md={3} lg={3}
                        sx={{
                          marginRight: "0.625rem",
                          marginTop: "0.438rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2rem",
                            height: "2.1rem",
                            borderRadius: "8px",
                            marginBottom: "0.625rem",
                            backgroundColor: colors.grey[800],
                            marginTop: "0.375rem",
                            marginLeft: "0.375rem",
                            marginRight: "0.375rem",
                          }}
                        >
                          <Thermostat
                            sx={{
                              marginLeft: "0.2rem",
                              height: "1.8rem",
                              marginTop: "0.125rem",
                              marginBottom: "0.125rem",
                              color: colors.primary.main,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography
                          sx={{
                            color: colors.primary["black"],
                            fontSize: "1.125rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {averageData1.averageTemperature1 !== null &&
                            `${averageData1.averageTemperature1.toFixed(2)}`} °C
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.9rem", color: colors.grey[680] }}
                        >
                          Avg. Temperature
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    sx={{
                      background: colors.purple[200],
                      boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[500]}`,
                      paddingLeft: "0.938rem",
                      paddingTop: "0.625rem",
                      borderRadius: "16px",
                      // width: "12.5rem",
                      // marginLeft: "1.5rem",
                      height: "5rem",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={3} sm={3} md={3} lg={3}
                        sx={{
                          marginRight: "0.625rem",
                          marginTop: "0.438rem",
                        }}
                      >
                        <Box
                          sx={{
                            width: "2rem",
                            height: "2.1rem",
                            borderRadius: "8px",
                            marginBottom: "0.625rem",
                            backgroundColor: colors.grey[800],
                            marginTop: "0.375rem",
                            marginLeft: "0.375rem",
                            marginRight: "0.375rem",
                          }}
                        >
                          <WaterDropIcon
                            sx={{
                              marginLeft: "0.2rem",
                              height: "1.8rem",
                              marginTop: "0.125rem",
                              marginBottom: "0.125rem",
                              color: colors.primary.main,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Typography
                          sx={{
                            color: colors.primary["black"],
                            fontSize: "1.125rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {averageData1.averageHumidity1 !== null &&
                            `${averageData1.averageHumidity1.toFixed(2)}`} %
                        </Typography>
                        <Typography
                          sx={{ fontSize: "0.938rem", color: colors.grey[680] }}
                        >
                          Avg. Humidity
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box
              sx={{
                // width: "29.375rem",
                // height: "12.125rem",
                padding: "1.5rem 0rem 1.5rem 0rem",
                borderRadius: "24px",
                boxShadow: "0rem 0.625rem 2.5rem 0rem #DEE6ED66",
                backgroundColor: colors.primary["main"],
                marginTop: "1.5rem",
                marginLeft: "1.5rem",
                boxShadow: `0rem 0.625rem 2.5rem 0rem ${colors.shadow[100]}`,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins,sans-serif",
                  fontWeight: 600,
                  // lineHeight:'34.66px',
                  color: colors.grey[900],
                  fontSize: "1.375rem",
                  marginLeft: "1.5rem",
                }}
              >
                Avg. Load MAPE Since Deployment
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12}
                sx={{ paddingX: "20px" }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.red[100],
                    paddingX: "0.938rem",
                    paddingY: "0.625rem",
                    borderRadius: "16px",
                    // marginLeft: "1.5rem",
                    height: "5rem",
                    marginTop: "1.5rem",
                    // width: "26.375rem",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        marginRight: "0.625rem",
                        marginTop: "0.438rem",
                      }}
                    >
                      <Box
                        sx={{
                          width: "2rem",
                          height: "2.1rem",
                          borderRadius: "8px",
                          marginBottom: "0.625rem",
                          backgroundColor: colors.grey[800],
                          marginTop: "0.375rem",
                          marginLeft: "0.375rem",
                          marginRight: "0.375rem",
                        }}
                      >
                        <FlashOnIcon
                          sx={{
                            marginLeft: "0.2rem",
                            height: "1.8rem",
                            marginTop: "0.125rem",
                            marginBottom: "0.125rem",
                            color: colors.primary.main,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: colors.primary["black"],
                          fontSize: "1.125rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        {maerange1 ? maerange1 : 'N/A'}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "0.938rem", color: colors.grey[680] }}
                      >
                        Avg. Load MAPE Since Deployment
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard2;
